import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import style from "./subscriptionModal.module.scss";
import { Button, Button1, FormInput, Input, ModalContent } from "../macros";
import { subscriptionSchema } from "../../Utils/Validations";
import styled from "styled-components";

const SubscriptionModal = ({ onSubmit }) => {
  const { user, loading } = useSelector((state) => state.user.userData);
  const [totalPrice, setTotalPrice] = useState(0);
  // const { isSubscribed } = useSelector((state) => state.user.userData.user);
  const { isSubscribed } = useSelector((state) => state.user);

  const { allowedUsers, allowedProjects } = useSelector(
    (state) => state.subscription
  );

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      projects: allowedProjects,
      users: allowedUsers,
    },
    resolver: yupResolver(subscriptionSchema),
  });

  const watchedValues = watch(["projects", "users"]);

  useEffect(() => {
    const charges = watchedValues[0] * 5 + watchedValues[1] * 1;
    setTotalPrice(charges);
  }, [watchedValues]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <div className="mb-6">
        <FormInput
          inputType="number"
          defaultValue={0}
          register={register("users")}
          placeholder="Enter Number of users"
          minValue={1}
          name="users"
          label="Select Users"
          maxValue={100}
        />

        <FormInput
          inputType="number"
          defaultValue={0}
          register={register("projects")}
          placeholder="Enter Number of projects"
          minValue={1}
          name="projects"
          label="Select Projects"
          maxValue={100}
        />

        <div className={style.membershipTotal}>
          <label className={style.membershipLabel}>Membership Amount</label>
          <div className={style.totalPrice}>${totalPrice}/month</div>
        </div>
      </div>

      <Button1
        title={`${isSubscribed ? "Update Membership" : "Add Membership"}`}
        btnType="submit"
        theme={{ btn: style.addUpdate }}
      />
    </form>
  );
};

export default SubscriptionModal;
