import styles from "./../../pages/Auth/authLayoutForms.module.scss";

const OtpInput = (props) => {
  const { otpError, setOtp, type, placeholder, value, minLength, maxLength } =
    props;
  return (
    <div className={styles.input}>
      <input
        type={type}
        placeholder={placeholder}
        onChange={(e) => setOtp(e.target.value)}
        value={value}
        maxLength={maxLength}
        minLength={minLength}
      />
      {otpError && (
        <div className={styles.invalid}>Invalid Otp! Please try again.</div>
      )}
    </div>
  );
};

export default OtpInput;
