import React from "react";

import { FormInput, Input } from "../macros";
import ColorInput from "../macros/ColorInput";
import DateInput from "../macros/DateInput";

const AssetDetails = ({ register, control, errors, canEdit }) => {
  console.log("Asset", canEdit);
  return (
    <div className="h-full overflow-y-auto mb-3">
      <div className="grid grid-cols-2 gap-y-3 gap-x-7 w-full">
        <FormInput
          inputType="text"
          disabled={!canEdit}
          register={register("title")}
          label="Title"
          name="title"
          placeholder="Enter Title..."
          errors={errors}
        />

        <FormInput
          inputType="text"
          disabled={!canEdit}
          register={register("assetName")}
          name="assetName"
          label="Asset Name"
          placeholder="Enter Name..."
          errors={errors}
        />

        <FormInput
          inputType="textArea"
          disabled={!canEdit}
          register={register("description")}
          name="description"
          label="Description"
          placeholder="Enter Text..."
          errors={errors}
        />

        <FormInput
          inputType="text"
          disabled={!canEdit}
          register={register("assetModel")}
          name="assetModel"
          label="Asset Model"
          placeholder="Enter Text..."
          errors={errors}
        />

        <div className="space-y-1">
          <ColorInput
            disabled={!canEdit}
            register={register("color")}
            control={control}
            name="color"
          />
        </div>
        <FormInput
          inputType="text"
          disabled={!canEdit}
          register={register("assetValue")}
          name="assetValue"
          label="Asset Value"
          placeholder="Enter Text..."
          errors={errors}
        />

        <FormInput
          label="Asset Maintenance Date"
          inputType="date"
          errors={errors}
          disabled={!canEdit}
          register={register("maintenanceDate")}
          name="maintenanceDate"
        />
        <FormInput
          inputType="text"
          disabled={!canEdit}
          register={register("maintenanceProvider")}
          name="maintenanceProvider"
          label="Asset Maintenance Provider"
          placeholder="Enter Text..."
          errors={errors}
        />
      </div>
    </div>
  );
};

export default AssetDetails;
