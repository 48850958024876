import { createSlice } from "@reduxjs/toolkit";
import { addUpdate, deleteUpdate, getUpdates } from "./updateActions";

const initialState = {
  updates: [],
  totalUpdates: 0,
  pageIndex: 1,
  numberOfRows: 5,
  loading: false,
  isOpenModel: false,
};
const updateSlice = createSlice({
  name: "update",
  initialState,
  reducers: {
    setUpdateModel: (state, action) => {
      state.isOpenModel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUpdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(addUpdate.fulfilled, (state, action) => {
        const updates = state.updates;
        state.updates = [action.payload.result, ...updates];
        state.loading = false;
      })
      .addCase(addUpdate.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getUpdates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUpdates.fulfilled, (state, action) => {
        state.updates = action.payload.result.docs;
        state.totalUpdates = action.payload.result.totalDocs;
        state.numberOfRows = action.payload.result.limit;
        state.pageIndex = action.payload.result.page;
        state.loading = false;
      })
      .addCase(getUpdates.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteUpdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUpdate.fulfilled, (state, action) => {
        const filteredUpdates = state.updates.filter(function (obj) {
          return obj._id !== action.payload.result;
        });
        state.updates = [...filteredUpdates];
        state.loading = false;
      })
      .addCase(deleteUpdate.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const updateReducer = updateSlice.reducer;
export const { setUpdateModel } = updateSlice.actions;
