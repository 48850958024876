import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../@api/axios";

export const createEnquiryRecord = createAsyncThunk(
  "contact/createEnquiryRecord",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/contact`, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
