import { message, Popover, Select, Spin, theme } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { AbilityContext, Can } from "../@permissions/Can";
import { Button, Button1, Input, TutorialCard } from "../component/macros";
import AddEditTutorialModal from "../component/modals/AddEditTutorialModal";
import VideoModal from "../component/modals/videoModal";
import styles from "./tutorial.module.scss";
import {
  deleteTutorials,
  getTutorials,
} from "../store/tutorials/tutorialActions";
import { useSearchParams } from "react-router-dom";
import AddUpdateTutorialModel from "../component/modals/AddUpdateTutorialModal";
import { setTutorialModel } from "../store/tutorials/tutorialSlice";
import { showMessage } from "../Utils/pushNotification";

const filterOptions = [{ label: "Title", value: "title" }];

const Tutorial = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [selectedTutorials, setSelectedTutorials] = useState([]);
  const [videoPreviewModal, setVideoPreviewModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [videoLink, setVideoLink] = useState(false);
  const [showCheck, setShowCheck] = useState(null);
  const [exportData, setExportData] = useState([]);
  const [del, setDel] = useState(null);
  const [singleTutorial, setSingleTutorial] = useState({});
  const { tutorials, loading } = useSelector((state) => state.tutorial);
  const { user } = useSelector((state) => state.user.userData);
  const ability = useContext(AbilityContext);
  const [modifiedDataSource, setModifiedDataSource] = useState(() => tutorials);
  const [sortType, setSortType] = useState("asc");
  const [filter, setFilter] = useState({
    name: filterOptions[0].value,
    value: searchParams.get("search") || "",
  });

  const { isOpenModel, selectedValue } = useSelector((state) => state.tutorial);

  const handleSelect = (tut) => {
    if (
      selectedTutorials.some((selectedTut) => selectedTut?.title === tut?.title)
    ) {
      setSelectedTutorials(
        selectedTutorials.filter(
          (selectedTut) => selectedTut?.title !== tut?.title
        )
      );
    } else {
      setSelectedTutorials([...selectedTutorials, tut]);
    }
  };
  useEffect(() => {
    if (!loading) {
      dispatch(getTutorials());
    }
  }, [del]);

  useEffect(() => {
    setExportData(
      tutorials.map((tutorial) => {
        const item = {
          title: tutorial?.title,
          videoLink: tutorial?.video,
        };
        return item;
      })
    );
  }, [tutorials]);
  const handleEditTutorial = () => {
    setSingleTutorial(selectedTutorials[0]);
    setEditModal(true);
    setSelectedTutorials([]);
  };
  const handleDeleteTutorials = () => {
    const ids = selectedTutorials.map((obj) => obj._id);
    dispatch(deleteTutorials(ids))
      .unwrap()
      .then((res) => (showMessage("success", res?.message), setDel(del + 1)))
      .catch((err) => showMessage("error", "Error in deleting tutorials"));
    setSelectedTutorials([]);
  };
  const handleVideo = (link) => {
    setVideoLink(link);
    setVideoPreviewModal(true);
  };

  useEffect(() => {
    if (filter?.value > "") {
      const path = filter.name;
      const data = tutorials?.filter((update) =>
        update[path]?.toLowerCase()?.includes(filter?.value?.toLowerCase())
      );
      setModifiedDataSource(handleSort(data));
    } else {
      setModifiedDataSource(handleSort(tutorials));
    }
  }, [filter?.name, filter?.value, sortType, tutorials]);

  const handleRadioChange = (e) => {
    setSortType(e.target.value);
  };

  const sortContent = () => {
    return (
      <div className="flex flex-col">
        <label className="flex items-center gap-2">
          <input
            type="radio"
            value="asc"
            checked={sortType === "asc"}
            onChange={handleRadioChange}
          />
          Ascending
        </label>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            value="desc"
            checked={sortType === "desc"}
            onChange={handleRadioChange}
          />
          Descending
        </label>
      </div>
    );
  };
  const handleFilterChange = (option) => {
    setFilter({ name: option, value: "" });
  };

  const handleSort = (data) => {
    return [...data].sort((a, b) => {
      if (sortType === "asc") {
        return a?.title?.localeCompare(b.title);
      } else if (sortType === "desc") {
        return b?.title?.localeCompare(a.title);
      }
      return 0;
    });
  };
  const filterContent = () => {
    return (
      <div className="flex gap-2">
        <Select
          size={"middle"}
          defaultValue={filterOptions[0]?.value}
          onChange={handleFilterChange}
          style={{ width: 200 }}
          options={filterOptions}
        />
        <input
          className="border border-solid border-[#d9d9d9] rounded-md px-2 py-1"
          placeholder="Search..."
          type="text"
          value={filter?.value}
          maxLength={60}
          onChange={(e) =>
            setFilter((prev) => ({ ...prev, value: e.target.value }))
          }
        />
      </div>
    );
  };

  return (
    <div className={`flex flex-col gap-5 ${styles.tutMain}`}>
      <div className="flex items-center justify-between gap-3 flex-wrap">
        <div className={styles.tutorialHeader}>
          <div className={styles.tutorialTitle}>
            <h1>Tutorials</h1>
            <p>Quick tutorial videos to get you started with GeoMaus</p>
          </div>
          <div className={styles.tutorialbtn}>
            <Can I="create" a="Tutorial">
              <Button1
                title="Add Tutorial"
                theme={{ btn: styles.addNewTut }}
                onClick={() => {
                  dispatch(setTutorialModel(true));
                }}
              />
            </Can>
            {/* <CSVLink data={exportData} filename={"Projects"}>
              <Button1 title="Export" />
            </CSVLink> */}
          </div>
        </div>
      </div>
      {/* <div className="md:max-w-[calc(100vw-153px)] lg:max-w-[calc(100vw-170px)] max-h-[calc(100vh-250px)] xl:max-h-[calc(100vh-250px)] bg-[#FCFCFC] pt-10 pb-10 px-10 flex flex-col items-center gap-3 rounded-2xl ">
        <div className="pl-[22px] flex items-center gap-7 self-start">
          <Popover content={sortContent} trigger="click" placement="bottom">
            <div className="flex items-center gap-3  cursor-pointer">
              <span className="heading4 text-[#545456]">Sort</span>
              <img src="/assets/images/IconSort.svg" alt="IconSearch" />
            </div>
          </Popover>
          <Popover content={filterContent} trigger="click" placement="bottom">
            <div className="flex items-center gap-3  cursor-pointer">
              <span className="heading4 text-[#545456]">Filter Results</span>
              <img src="/assets/images/IconFilter.svg" alt="IconSearch" />
            </div>
          </Popover>
          {filter?.value && (
            <>
              <p>Filter Applied:</p>
              <div
                className="border border-secondary rounded-xl px-4 py-1 text-xs text-secondary cursor-pointer"
                onClick={() => {
                  setFilter({ name: "title", value: "" });
                }}
              >
                <p>
                  {
                    filterOptions.filter((flt) => flt.value === filter?.name)[0]
                      ?.label
                  }
                  : {filter?.value}
                </p>
              </div>
            </>
          )}
        </div>
        {loading ? (
          <Spin />
        ) : (
          <div className="p-5 w-full flex flex-wrap gap-5 overflow-auto h-[90%]">
            {modifiedDataSource.length === 0 && (
              <span className="heading4 text-[#545456] !font-normal">
                No Tutorials
              </span>
            )}
            {modifiedDataSource?.map((tut, index) => (
              <div
                key={index}
                onClick={() => handleVideo(tut?.video)}
                className="w-[150px] flex flex-col items-center justify-start gap-2 cursor-pointer select-none "
                onMouseOver={() => {
                  if (ability.can("edit", "Tutorial")) {
                    setShowCheck(tut);
                  }
                }}
                onMouseOut={() => {
                  if (ability.can("edit", "Tutorial")) {
                    setShowCheck(null);
                  }
                }}
              >
                <div className="relative w-[150px] h-[150px]">
                  <img
                    src={
                      tut?.thumbnail
                        ? tut?.thumbnail
                        : "/assets/images/TutorialThumbnail1.svg"
                    }
                    alt="thumbnailTutorial"
                    className="object-cover w-[150px] h-[150px] rounded-xl hover:scale-[1.05] customTransition"
                  />
                  {(showCheck?._id === tut?._id ||
                    selectedTutorials?.length > 0) && (
                    <div
                      onClick={(e) => (handleSelect(tut), e.stopPropagation())}
                      className="absolute top-2 right-2"
                    >
                      <input
                        type="checkbox"
                        checked={selectedTutorials.some(
                          (selectedTut) => selectedTut?.title === tut?.title
                        )}
                        className="cursor-pointer"
                        readOnly
                      />
                    </div>
                  )}
                </div>
                <span className="truncate text-base w-full text-center font-semibold text-tertiory hover:text-primary">
                  {tut?.title}
                </span>
              </div>
            ))}
          </div>
        )}
      </div> */}

      {loading && <Spin />}

      <TutorialCard items={modifiedDataSource} handleVideo={handleVideo} />

      <AddUpdateTutorialModel
        isOpen={isOpenModel}
        {...(selectedValue ? { selectedData: selectedValue } : {})}
      />

      {videoPreviewModal && (
        <VideoModal
          open={videoPreviewModal}
          setOpen={setVideoPreviewModal}
          src={videoLink}
        />
      )}
    </div>
  );
};

export default Tutorial;
