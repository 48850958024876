import { message, Popover, Select, Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import {
  Button1,
  FormInput,
  Input2,
  TableComponent,
} from "../component/macros";
import DeleteModal from "../component/modals/DeleteModal";
import useDebounce from "../hooks/useDebounce";
import { deleteUpdate, getUpdates } from "../store/updates/updateActions";
import { timestamptoDate } from "../Utils/functions";
import { useSearchParams } from "react-router-dom";
import style from "./update.module.scss";
import SortBy from "../component/macros/SortBy/SortBy";
import AddUpdateFeatureModel from "../component/modals/InsertFeatureUpdateModal";
import { setUpdateModel } from "../store/updates/updateSlice";
import FilterBy from "../component/macros/filterBy/filterBy";
import ConfirmationModal from "../component/modals/ConfitmationModal";
import { showMessage } from "../Utils/pushNotification";

const filterOptions = [
  { label: "Title", value: "title" },
  { label: "Details", value: "details" },
];

const Updates = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [chosenUpdate, setChosenUpdate] = useState();
  const [value, setValue] = useState(() => searchParams.get("search") || "");
  const [searchValue, oldValue] = useDebounce(value, 500, true);
  const { updates, loading, isOpenModel } = useSelector(
    (state) => state.update
  );
  const [modifiedDataSource, setModifiedDataSource] = useState(() => updates);
  const [sortType, setSortType] = useState("asc");
  const [filter, setFilter] = useState({
    name: filterOptions[0].value,
    value: "",
  });

  const sortByOptions = useMemo(
    () => [
      {
        label: "Ascending",
        value: "asc",
        isSelected: sortType === "asc",
      },
      {
        label: "Descending",
        value: "desc",
        isSelected: sortType === "desc",
      },
    ],
    [sortType]
  );

  useEffect(() => {
    setExportData(
      updates.map((update) => {
        const item = {
          Title: update?.title,
          Details: update?.details,
          DateAdded: timestamptoDate(update?.createdAt),
        };
        return item;
      })
    );
  }, [updates]);

  useEffect(() => {
    if (searchValue !== "" || (searchValue === "" && oldValue !== "")) {
      dispatch(getUpdates({ query: searchValue }));
    } else {
      dispatch(getUpdates({ query: searchValue }));
    }
  }, [searchValue, oldValue]);

  const handleDelete = () => {
    dispatch(deleteUpdate(chosenUpdate?._id))
      .unwrap()
      .then(
        (res) => (showMessage("success", res?.message), setDeleteModal(false))
      )
      .catch(
        (err) => (
          showMessage("error", "Error Deleting Update"), setDeleteModal(false)
        )
      );
  };

  useEffect(() => {
    if (filter?.value > "") {
      const path = filter.name;
      const data = updates?.filter((update) =>
        update[path]?.toLowerCase()?.includes(filter?.value?.toLowerCase())
      );
      setModifiedDataSource(handleSort(data));
    } else {
      setModifiedDataSource(handleSort(updates));
    }
  }, [filter?.name, filter?.value, sortType, updates]);

  const handleRadioChange = (e) => {
    setSortType(e.target.value);
  };
  const sortContent = () => {
    return (
      <div className="flex flex-col">
        <label className="flex items-center gap-2">
          <input
            type="radio"
            value="asc"
            checked={sortType === "asc"}
            onChange={handleRadioChange}
          />
          Ascending
        </label>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            value="desc"
            checked={sortType === "desc"}
            onChange={handleRadioChange}
          />
          Descending
        </label>
      </div>
    );
  };
  const handleFilterChange = (option) => {
    setFilter({ name: option, value: "" });
  };

  const handleSort = (data) => {
    return [...data].sort((a, b) => {
      if (sortType === "asc") {
        return a?.title?.localeCompare(b.title);
      } else if (sortType === "desc") {
        return b?.title?.localeCompare(a.title);
      }
      return 0;
    });
  };

  const UPDATES_COLUMNS = [
    {
      title: <div>Title</div>,
      dataIndex: "title",
    },
    {
      title: <div>Details</div>,
      dataIndex: "details",
      render: (text) => {
        return <p className="text-center">{text}</p>;
      },
    },
    {
      title: <div>Date Added</div>,
      dataIndex: "createdAt",
      render: (text) => {
        return <p className="mx-auto text-center">{timestamptoDate(text)}</p>;
      },
    },
    {
      title: (
        <div className="flex items-center justify-center gap-3 ">Actions</div>
      ),

      dataIndex: "actions",
      render: (text, record) => {
        return (
          <Button1
            title="Delete"
            onClick={() => (setChosenUpdate(record), setDeleteModal(true))}
          ></Button1>
        );
      },
    },
  ];
  return (
    <div className={style.updateMain}>
      <div className={style.headerTop}>
        <div className={style.leftside}>
          <Button1
            title="Add Updates"
            theme={{ btn: style.addNew }}
            onClick={() => {
              dispatch(setUpdateModel(true));
            }}
          />

          <CSVLink data={exportData} filename={"Updates"}>
            <Button1 title="Export " theme={{ btn: style.exportBtn }} />
          </CSVLink>
        </div>

        <div className={style.rightside}>
          <div className="search">
            {/* <FormInput
              inputType="search"
              placeholder="Search"
              theme={{ labelText: style.labelInput }}
              onChange={(e) => setValue(e?.target?.value)}
            /> */}
            <Input2
              placeholder="Search"
              type="search"
              icon="/assets/images/searchIconBlack.svg"
              name="search"
              onChange={(e) => setValue(e?.target?.value)}
              theme={{ input: style.labelInput }}
            />
          </div>

          <FilterBy
            defaultValue={filterOptions[0]?.value}
            onChange={handleFilterChange}
            options={filterOptions}
            filter={filter}
            setFilter={setFilter}
          />

          <SortBy
            items={sortByOptions}
            onChange={(e) => setSortType(e.target.value)}
          />
        </div>
      </div>

      <div className="body content">
        {loading && !updates && <Spin />}

        <div className="table data" style={{ width: "100%" }}>
          <TableComponent columns={UPDATES_COLUMNS} data={modifiedDataSource} />
        </div>

        {deleteModal && (
          <ConfirmationModal
            isModal={deleteModal}
            setIsModal={setDeleteModal}
            text="Do you want to delete this update?"
            onClick={handleDelete}
          />
        )}
      </div>

      <AddUpdateFeatureModel isOpen={isOpenModel} type="Update" />
    </div>

    // <div className="flex flex-col gap-5">
    //   <h1 className="headingPage  xl:hidden">Updates</h1>
    //   <div className="flex items-center justify-between gap-3 flex-wrap">
    //     <div className="flex items-center gap-8">
    //       <h1 className="headingPage hidden xl:block">Updates</h1>

    //       <div className="flex items-center gap-5">
    //         <Button
    //           title="Add Update"
    //           onClick={() => {
    //             setAddModal(true);
    //           }}
    //         />
    //         <CSVLink data={exportData} filename={"Updates"}>
    //           <Button title="Export" />
    //         </CSVLink>
    //       </div>
    //     </div>
    //     <div>
    //       <Input
    //         type="search"
    //         placeholder="Search..."
    //         width="w-[360px]"
    //         value={value}
    //         onChange={(e) => setValue(e?.target?.value)}
    //       />
    //     </div>
    //   </div>
    //   <div className="md:max-w-[calc(100vw-153px)] lg:max-w-[calc(100vw-170px)] bg-[#FCFCFC] pt-10 pb-4 px-10 flex flex-col items-center gap-3 rounded-2xl max-h-[calc(100vh-368px)] xl:max-h-[calc(100vh-250px)]">
    //     <div className="pl-[22px] flex items-center gap-7 self-start">
    //       <Popover content={sortContent} trigger="click" placement="bottom">
    //         <div className="flex items-center gap-3  cursor-pointer">
    //           <span className="heading4 text-[#545456]">Sort</span>
    //           <img src="/assets/images/IconSort.svg" alt="IconSearch" />
    //         </div>
    //       </Popover>
    //       <Popover content={filterContent} trigger="click" placement="bottom">
    //         <div className="flex items-center gap-3  cursor-pointer">
    //           <span className="heading4 text-[#545456]">Filter Results</span>
    //           <img src="/assets/images/IconFilter.svg" alt="IconSearch" />
    //         </div>
    //       </Popover>
    //       {filter?.value && (
    //         <>
    //           <p>Filter Applied:</p>
    //           <div
    //             className="border border-secondary rounded-xl px-4 py-1 text-xs text-secondary cursor-pointer"
    //             onClick={() => {
    //               setFilter({ name: "title", value: "" });
    //             }}
    //           >
    //             <p>
    //               {
    //                 filterOptions.filter((flt) => flt.value === filter?.name)[0]
    //                   ?.label
    //               }
    //               : {filter?.value}
    //             </p>
    //           </div>
    //         </>
    //       )}
    //     </div>
    //     {loading ? (
    //       <Spin />
    //     ) : (
    //       <TableComponent
    //         columns={UPDATES_COLUMNS}
    //         data={modifiedDataSource}
    //         placeholder="No Updates..."
    //       />
    //     )}
    //     {addModal && (
    //       <AddFeatureUpdate
    //         loading={loading}
    //         open={addModal}
    //         setOpen={setAddModal}
    //         type="Update"
    //       />
    //     )}
    //     {deleteModal && (
    //       <DeleteModal
    //         loading={loading}
    //         open={deleteModal}
    //         setOpen={setDeleteModal}
    //         handleDelete={handleDelete}
    //       />
    //     )}
    //   </div>
    // </div>
  );
};

export default Updates;
