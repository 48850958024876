/**@deprecated */

import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Input,
  ModalContent,
  ReactDropzone,
  SingleSelect,
  SwitchToggle,
  TextArea,
} from "../macros";
import { message, Spin } from "antd";
import { addUserSchema } from "../../Utils/Validations";
import { addCustomer } from "../../store/customers/customerActions";
import { handleImageDrop } from "../../Utils/functions";
import { showMessage } from "../../Utils/pushNotification";

const AddCustomerModal = ({ open, setOpen }) => {
  const { loading } = useSelector((state) => state.customer);
  const { role: loggedInRole } = useSelector((state) => state.user);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { accessType: "View" },
    resolver: yupResolver(addUserSchema),
  });
  const [isEnabled, setisEnabled] = useState(false);
  const [file, setFile] = useState(false);
  const [imageBase64, setImageBase64] = useState(null);
  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    const addUserData = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      password: data.password,
      description: data.description,
      address: data.address,
      twoFactorAuthentication: isEnabled,
      photo: imageBase64,
    };
    if (loggedInRole === "ADMIN") {
      addUserData.role = ["CUSTOMER"];
    } else if (loggedInRole === "CUSTOMER") {
      addUserData.role = ["USER"];
      addUserData.accessType = data.accessType;
    }
    dispatch(addCustomer(addUserData))
      .unwrap()
      .then((res) => (showMessage("success", res?.message), setOpen(false)))
      .catch((err) => showMessage("error", err?.data?.message));
  };

  return (
    <ModalContent isOpen={open} setClose={() => setOpen(false)} width={631}>
      <div className="flex flex-col gap-2 w-full items-center px-5 ">
        <h3 className="heading3 text-primary">Add Customer</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-y-1 1320:gap-y-3 gap-x-7 w-full pb-2 overflow-y-auto max-h-[70vh]">
            <div className="space-y-1 relative">
              <span className="heading4 ml-3">Name*</span>
              <Input
                register={register("name")}
                Icon="/assets/images/IconText.svg"
                placeholder="Enter Name..."
              />
              <p className="errorStyle">{errors?.name?.message}</p>
            </div>
            <div className="mt-8 row-span-2 bg-[#B3B8BD]/[0.5] shadow-md rounded-2xl flex justify-center items-center">
              <ReactDropzone
                onDrop={(acceptedFile) => (
                  setFile(acceptedFile),
                  handleImageDrop(acceptedFile, setImageBase64)
                )}
              >
                <div
                  className={`py-4 flex flex-col items-center justify-center gap-3`}
                >
                  <img src="/assets/images/IconUpload.svg" alt="uploadImage" />
                  <p className="font-medium text-xs text-primary text-center underline">
                    Select or drag and drop image here to upload
                  </p>
                  <p className="text-[8px]">{file && file[0]?.name}</p>
                </div>
              </ReactDropzone>
            </div>
            <div className="space-y-1">
              <span className="heading4 ml-3">Email*</span>
              <Input
                register={register("email")}
                Icon="/assets/images/IconUser.svg"
                placeholder="Enter Email..."
              />
              <p className="errorStyle">{errors?.email?.message}</p>
            </div>
            <div className="space-y-1">
              <span className="heading4 ml-3">Phone*</span>
              <Input
                register={register("phone")}
                Icon="/assets/images/IconPhone.svg"
                placeholder="Phone"
              />
              <p className="errorStyle">{errors?.phone?.message}</p>
            </div>

            <div className="space-y-1">
              <span className="heading4 ml-3">Two Factor Authentication</span>
              <SwitchToggle
                isChecked={isEnabled}
                handleToggle={() => setisEnabled(!isEnabled)}
              />
            </div>
            <div className="space-y-1">
              <span className="heading4 ml-3">Password*</span>
              <Input
                register={register("password")}
                Icon="/assets/images/IconPhone.svg"
                placeholder="Password"
              />
              <p className="errorStyle">{errors?.password?.message}</p>
            </div>
            <div className="space-y-1">
              <span className="heading4 ml-3">Confirm Password*</span>
              <Input
                register={register("confirmPassword")}
                Icon="/assets/images/IconPhone.svg"
                placeholder="Confirm Password"
              />
              <p className="errorStyle">{errors?.confirmPassword?.message}</p>
            </div>

            <div className="space-y-1">
              <span className="heading4 ml-3">Description</span>
              <TextArea
                register={register("description")}
                height="h-[calc(100%-33px)]"
                Icon="/assets/images/IconDescription.svg"
                placeholder="Enter Description..."
              />
              <p className="errorStyle">{errors?.description?.message}</p>
            </div>
            <div className="space-y-1">
              <span className="heading4 ml-3">Address</span>
              <TextArea
                register={register("address")}
                height="h-[calc(100%-33px)]"
                Icon="/assets/images/IconDescription.svg"
                placeholder="Enter Address..."
              />
              <p className="errorStyle">{errors?.address?.message}</p>
            </div>
            {loggedInRole === "CUSTOMER" && (
              <div className="space-y-1">
                <span className="heading4 ml-3">Access Type*</span>
                <Controller
                  name="accessType"
                  control={control}
                  render={({ field }) => (
                    <SingleSelect
                      mode="single"
                      options={[
                        { value: "View", label: "View" },
                        { value: "Edit", label: "Edit" },
                      ]}
                      handleSelect={field.onChange}
                      value={field.value}
                      placeholder="Access Type"
                    />
                  )}
                />
                <p className="errorStyle">{errors?.accessType?.message}</p>
              </div>
            )}
          </div>

          <div className="flex items-center gap-6 whitespace-nowrap mt-7 w-[70%] mx-auto">
            <Button
              loading={loading}
              title={"Add Customer"}
              btnType="submit"
              className="!px-7"
              width={"w-full"}
              titleColor="text-primary"
              Icon="/assets/images/IconCheckBlue.svg"
            />
            <Button
              loading={loading}
              title={"Cancel"}
              width={"w-full"}
              className="!px-7"
              onClick={() => setOpen(false)}
              titleColor="text-primary"
              Icon="/assets/images/IconCrossBlue.svg"
            />
          </div>
        </form>
      </div>
    </ModalContent>
  );
};

export default AddCustomerModal;
