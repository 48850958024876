import { createSlice } from "@reduxjs/toolkit";
import {
  loginUser,
  updateUserProfile,
  getGoogleAuthData,
  switchRole,
  accessAccount,
  removeCustomerAccess,
  sendOtp,
} from "./userActions";
const customerTokenPresent = localStorage.getItem("customer_access_token")
  ? true
  : false;
const initialState = {
  userData: {},
  loading: false,
  error: null,
  role: "",
  accessType: "",
  isCustomer: customerTokenPresent,
  isSubscribed: false,
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsSubscribe: (state, action) => {
      state.isSubscribed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload.result;
        state.role = action.payload.result.user.role[0];
        state.accessType = action.payload.result.user?.accessType;
        state.isSubscribed = action.payload.result.user.isSubscribed;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data.message;
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.userData.user = action.payload.result.user;
        state.error = null;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data.message;
      })
      .addCase(getGoogleAuthData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGoogleAuthData.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload.result;
        state.role = action.payload.result.user.role[0];
        state.accessType = action.payload.result.user?.accessType;
        state.error = null;
      })
      .addCase(getGoogleAuthData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(switchRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(switchRole.fulfilled, (state, action) => {
        state.loading = false;
        state.userData.user = action.payload.result;
        state.role = action.payload.result.role[0];
        state.accessType = action.payload.result?.accessType;
        state.error = null;
      })
      .addCase(switchRole.rejected, (state) => {
        state.loading = false;
      })
      .addCase(accessAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(accessAccount.fulfilled, (state, action) => {
        console.log("action", action.payload);
        state.loading = false;
        state.isCustomer = true;
        if (action.payload.loggedInRole === "ADMIN") {
          state.role = "CUSTOMER";
        } else if (action.payload.loggedInRole === "CUSTOMER") {
          state.role = "USER";
        }
        state.accessType = action.payload.result.accessType;
      })
      .addCase(accessAccount.rejected, (state) => {
        state.loading = false;
      })
      .addCase(removeCustomerAccess.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeCustomerAccess.fulfilled, (state, action) => {
        state.isCustomer = false;
        state.role = action.payload;
        state.loading = false;
      })
      .addCase(removeCustomerAccess.rejected, (state) => {
        state.loading = false;
      })
      .addCase(sendOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(sendOtp.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const userReducer = userSlice.reducer;
export const { setIsSubscribe } = userSlice.actions;
