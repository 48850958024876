import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../@api/axios";

export const addUpdate = createAsyncThunk(
  "updates/addUpdate",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/update`, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUpdates = createAsyncThunk(
  "updates/getUpdates",
  async (data, { rejectWithValue }) => {
    try {
      const { rows = 100, page = 1, query } = data;
      const res = await axiosInstance.get(
        `/updates?page=` + page + `&limit=` + rows + `&query=` + query
      );
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteUpdate = createAsyncThunk(
  "updates/deleteUpdate",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.delete(`/update/` + id);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
