import React, { Children } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import Button from "./Button";
import "./../../Styles/antd.css";

const StyledModal = styled(Modal)`
  .ant-modal-content {
    background-color: white; /* Remove background color */
    border-radius: 0; /* Remove border radius */
    padding: 0 !important; /* Remove padding */
    margin: 0; /* Remove margin */
    
  }
}

.ant-modal-header,
.ant-modal-body,
.ant-modal-footer {
  padding: 0;
  }

  .ant-modal-close {
    display: none; /* Hide the default close button */
  }
`;

const ModalContent = ({
  loading,
  isOpen,
  setClose,
  closeButton,
  children,
  btnYes,
  handleYes,
  buttons,
  width,
}) => {
  return (
    <StyledModal
      footer={null}
      centered
      width={width}
      closable={false}
      onCancel={setClose}
      open={isOpen}
      maskStyle={{ opacity: 0.3 }}
    >
      <div className="relative flex flex-col justify-center items-center w-full">
        {closeButton && (
          <img
            src="/assets/images/IconCross.svg"
            alt="closeButton"
            className="absolute right-0 top-0 cursor-pointer"
            onClick={setClose}
          />
        )}
        {Children.map(children, (child) => (
          <>{child}</>
        ))}
        {buttons && (
          <div className="flex items-center gap-6 whitespace-nowrap">
            <Button
              loading={loading}
              title={btnYes}
              className="!px-7"
              width={"w-full"}
              onClick={handleYes}
              titleColor="text-primary"
              Icon="/assets/images/IconCheckBlue.svg"
            />
            <Button
              loading={loading}
              title={"Cancel"}
              width={"w-full"}
              className="!px-7"
              onClick={setClose}
              titleColor="text-primary"
              Icon="/assets/images/IconCrossBlue.svg"
            />
          </div>
        )}
      </div>
    </StyledModal>
  );
};

export default ModalContent;
