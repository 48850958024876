import {
  setEditCustomerModal,
  setCustomerDataForEdit,
} from "../../store/customers/customerSlice";
import { SideModel, UserForm } from "../macros";
import style from "./editCustomer.modal.scss";
import { useDispatch } from "react-redux";
const EditCustomerModel = ({ isOpen }) => {
  const dispatch = useDispatch();
  const handleReset = () => {
    dispatch(setEditCustomerModal(false));
    dispatch(setCustomerDataForEdit(undefined));
  };
  return (
    <SideModel isOpen={isOpen} handleReset={handleReset}>
      {/* <div className={style.editCoustomer}>
        <h2>Edit Customer</h2>
        <h5 className={style.editText}>
          Amazing! We have secured a new client add them here and lets see where
          it goes!
        </h5>
      </div> */}
      <UserForm type="Edit" />
    </SideModel>
  );
};
export default EditCustomerModel;
