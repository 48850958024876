import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosInstance from "../../@api/axios";
import { showMessage } from "../../Utils/pushNotification";

export const loginUser = createAsyncThunk(
  "user/login",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/users/login`, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const registerUser = createAsyncThunk(
  "user/register",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/users/register`, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const googleAuth = createAsyncThunk(
  "user/googleAuth",
  async (callbackUrl, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/user/auth/google`, {
        callbackUrl,
      });
      if (window) {
        window.open(res, "_self");
      }
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getGoogleAuthData = createAsyncThunk(
  "user/googleAuthVerify",
  async ({ code, callbackUrl, role, action }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get("/user/auth/google/verify", {
        params: { code, callbackUrl, role },
      });
      action && action(res);
      return res;
    } catch (err) {
      if (err?.data?.code === 404) {
        showMessage("error", err?.data?.message);
      }
      return rejectWithValue(err);
    }
  }
);

export const sendOtp = createAsyncThunk(
  "user/sendOtp",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/sendotp`, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const confirmOtp = createAsyncThunk(
  "user/confirmOtp",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/confirmOtp`, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updatePasword = createAsyncThunk(
  "user/updatePasword",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(
        `/user/password/changepassword`,
        data
      );
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const accessAccount = createAsyncThunk(
  "user/accessAccount",
  async ({ id, loggedInRole }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/customerAccessToken/` + id);
      localStorage.setItem("customer_access_token", res.result.token);
      res.loggedInRole = loggedInRole;
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const removeCustomerAccess = createAsyncThunk(
  "user/removeCustomerAccess",
  async (role, { rejectWithValue }) => {
    try {
      localStorage.removeItem("customer_access_token");
      return role;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateUserProfile = createAsyncThunk(
  "user/updateuserprofile",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(`/user/` + id, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const switchRole = createAsyncThunk(
  "user/switchRole",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/switchrole`, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const globalSearch = createAsyncThunk(
  "user/globalSearch",
  async ({ searchText }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/searchAll`, { searchText });
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const sendPlatformUpdatesToAll = createAsyncThunk(
  "user/sendPlatformUpdatesToAll",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/platform/update`);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
