import React from "react";
import { Select, Space } from "antd";
import "./../../Styles/antd.css";

const SingleSelect = ({
  icon,
  placeholder,
  options,
  handleSelect,
  value,
  showSearch = false,
  onSearch,
}) => (
  <Space
    style={{
      width: "100%",
      borderRadius: "20px",
    }}
    direction="vertical"
  >
    <div className="relative flex items-center">
      <Select
        showSearch={showSearch}
        mode="single"
        className="ANTDSINGLESELECT"
        allowClear={false}
        onSearch={onSearch}
        suffixIcon={<img src="/assets/images/IconArrow.svg" alt="icon" />}
        style={{
          width: "100%",
        }}
        placeholder={placeholder}
        onChange={handleSelect}
        value={value}
        options={options}
      />
      <img
        src={icon ? icon : "/assets/images/IconDescription.svg"}
        alt="prefix-icon"
        className="absolute left-3"
      />
    </div>
  </Space>
);

export default SingleSelect;
