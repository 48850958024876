import { useState } from "react";
import { FormInput, SideModel } from "..";
import Button1 from "../Button1";
import styles from "./contactSupport.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../Dropdown";
import { contactSchema } from "../../../Utils/Validations";
import { createEnquiryRecord } from "../../../store/contactUs/contactUsActions";
import { showMessage } from "../../../Utils/pushNotification";

const ContactSupport = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const options = [
    { value: "Scan to BIM", label: "Scan to BIM" },
    { value: "Scan to CAD", label: "Scan to CAD" },
    { value: "2D/3D Floor Plans", label: "2D/3D Floor Plans" },
    { value: "3D Laser  Scanning", label: "3D Laser  Scanning" },
    { value: "3D Virtual Tour", label: "3D Virtual Tour" },
    { value: "3D Design Renders", label: "3D Design Renders" },
    { value: "General Enquiry", label: "General Enquiry" },
    { value: "Other", label: "Other" },
  ];

  const { role: loggedInRole } = useSelector((state) => state.user);

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(contactSchema),
  });

  const onSubmit = async (data) => {
    dispatch(createEnquiryRecord(data))
      .unwrap()
      .then(
        (res) => (
          showMessage("success", "Your enquiry is successfully submited."),
          setIsModalOpen(false)
        )
      )
      .catch((err) => showMessage("error", err?.data?.message));
  };

  const handleReset = () => {
    setIsModalOpen(false);
    reset();
  };

  return (
    <div className={styles.contactInfo}>
      <div className={styles.contactText}>
        <h2>Contact Support</h2>
        <p>
          Having difficulties? <br /> Contact us and we will get back to you
          swiftly.
        </p>
      </div>
      <div className={styles.contactBtn}>
        <Button1
          title="Contact Support"
          theme={{ btn: styles.supportBtn }}
          onClick={() => setIsModalOpen(true)}
        />
        <div className={styles.footerLogo}>
          <p>Powered By:</p>
          <img src="/assets/images/Logo.png" alt="" />
        </div>
      </div>

      {isModalOpen && (
        <SideModel isOpen={isModalOpen} handleReset={handleReset}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.tutorial}>
              <div className={styles.Form}>
                <h2>Contact Us</h2>
                <p className={styles.uploadText}>
                  Need help or have a question? We're here for you. Fill out the
                  contact form with your details. Your satisfaction is our
                  priority.
                </p>

                <FormInput
                  inputType="text"
                  placeholder="Enter full name here..."
                  label="Full Name"
                  register={register("fullName")}
                  name="fullName"
                  errors={errors}
                />
                <FormInput
                  inputType="text"
                  placeholder="Enter email here..."
                  label="Email"
                  register={register("email")}
                  name="email"
                  errors={errors}
                />
                <FormInput
                  inputType="text"
                  placeholder="Enter phone here..."
                  label="Phone"
                  register={register("phone")}
                  name="phone"
                  errors={errors}
                />
                <Dropdown
                  control={control}
                  isMultiple={false}
                  label={"Enquiry Type"}
                  name={"enquiryType"}
                  options={options}
                  placeholder={"Select a Enquiry Type"}
                />
              </div>
              <div className={styles.Btn}>
                <Button1
                  theme={{ btn: styles.addUpload }}
                  title="Add"
                  type="submit"
                />

                <Button1
                  theme={{ btn: styles.cancleUpload }}
                  type="button"
                  title="Cancel"
                  onClick={handleReset}
                />
              </div>
            </div>
          </form>
        </SideModel>
      )}
    </div>
  );
};

export default ContactSupport;
