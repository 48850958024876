import { useEffect, useState } from "react";
import ModalContent from "../component/macros/Modal";
import React from "react";
import { Flex, Select } from "antd";
import {
  AddProjectButton,
  Button,
  Button1,
  Card,
  Card2,
  FeatureCard,
  Graph,
  HorizontalScrollbar,
  SelectCustom,
} from "../component/macros";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { timestamptoDate, timestamptoDate1 } from "../Utils/functions";
import moment from "moment";
import { getPeople } from "../store/customers/customerActions";
import { getFeatures } from "../store/feature/featureActions";
import { getUpdates } from "../store/updates/updateActions";
import {
  deleteProject,
  getProjects,
  getProjectWithAssets,
} from "../store/projects/projectActions";
import { getCustomerTags } from "../store/asset/assetActions";
import AddEditProjectModal from "../component/modals/AddEditProjectModal";
import AddCustomerModal from "../component/modals/AddCustomerModal";
import AddFeatureUpdate from "../component/modals/AddFeatureUpdate";
import { globalSearch } from "../store/users/userActions";
import useDebounce from "../hooks/useDebounce";
import { getTimeAgo } from "./../Utils/functions";
import DashboardCard from "../component/macros/DashboardCard";
import ProjectCard from "../component/macros/ProjectCard";
import style from "./dashboard.module.scss";
import { If, Else, Then } from "react-if";
import ConfirmationModal from "../component/modals/ConfitmationModal";
import { showMessage } from "../Utils/pushNotification";

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const Dashboard = () => {
  const [entryModal, setEntryModal] = useState(false);
  const [addProjectModal, setAddProjectModal] = useState(false);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [addFeatureModal, setAddFeatureModal] = useState(false);
  const [addUpdateModal, setAddUpdateModal] = useState(false);
  const [chosenProject, setChosenProject] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [overDueCount, setOverDueCount] = useState(0);
  const [options, setOptions] = useState([]);
  const { role } = useSelector((state) => state.user);
  const { totalCustomer, customers } = useSelector((state) => state.customer);
  const { projects } = useSelector((state) => state.project);
  const { features } = useSelector((state) => state.feature);
  const { updates } = useSelector((state) => state.update);
  const { customerTags, totalAssets } = useSelector((state) => state.asset);
  const [value, setValue] = useState("");
  const [searchValue, oldValue] = useDebounce(value, 500, true);
  const [sortType, setSortType] = useState("desc");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjects({ searchValue: "", sortType }));
    dispatch(getPeople({ searchValue: "", role }));
    dispatch(getFeatures({ query: "" }));
    dispatch(getUpdates({ query: "" }));
    dispatch(getCustomerTags());
    overDueTags();
  }, [role]);

  const updatedProject = projects
    ? [...projects].sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      )
    : [];

  const handleAddEntry = () => {
    setEntryModal(true);
  };

  function addLeadingZero(number) {
    return number < 10 ? "0" + number : number;
  }

  const overDueTags = () => {
    let count = 0;
    if (customerTags && Array.isArray(customerTags)) {
      customerTags.map((asset) => {
        if (moment(asset.maintenanceDate).isBefore(new Date())) count++;
      });
    }
    setOverDueCount(count);
  };
  const handleGlobalSearch = async (e) => {
    setValue(e);
  };
  const handleSelect = (value) => {
    const selected = options.find((option) => option._id === value);
    if (Object.entries(selected)?.length > 0) {
      navigate(
        `${role?.toLowerCase()}/${
          selected?.category === "user" ? "people" : selected?.category + "s"
        }?search=${selected?.title ?? selected?.name}`
      );
    }
  };

  const handleDeleteProject = () => {
    dispatch(deleteProject(chosenProject?._id))
      .unwrap()
      .then((res) => {
        dispatch(getProjectWithAssets());
        showMessage("success", res?.message);
      })
      .catch((err) => showMessage("error", "Error Deleting Project"))
      .finally(setDeleteModal(false));
  };

  const cardData = [
    {
      title: "Project",
      count: addLeadingZero(projects?.length),
      route: `/${role?.toLowerCase()}/projects`,
    },
    {
      title: "Feature Request </br> Reported Issues",
      count: addLeadingZero(features?.length),
      route: `/${role?.toLowerCase()}/features`,
    },
    {
      title: "Overdue Tags",
      count: addLeadingZero(overDueCount),
      route: `/${role?.toLowerCase()}/projects`,
    },
    {
      title: "Updates",
      count: addLeadingZero(updates?.length),
      route: `/${role?.toLowerCase()}/updates`,
    },
    {
      title: "Tags",
      count: addLeadingZero(customerTags?.length),
      route: `/${role?.toLowerCase()}/projects`,
    },
  ];

  useEffect(() => {
    dispatch(globalSearch({ searchText: searchValue }))
      .unwrap()
      .then((res) => setOptions(res?.result));
  }, [searchValue, oldValue]);

  const adminDash = () => (
    <>
      <DashboardCard cardData={cardData} />
      <div className={style.mainProject}>
        {updatedProject?.length > 0 && (
          <HorizontalScrollbar>
            <ProjectCard
              projects={updatedProject}
              setChosenProject={setChosenProject}
              setDeleteModal={setDeleteModal}
            />
          </HorizontalScrollbar>
        )}
        <div className={style.newWork}>
          <FeatureCard featureList={features} />

          <div className={style.recentCustomer}>
            <div className={style.customerInfo}>
              <div className={style.customerId}>
                <div className={style.customerHeading}>
                  <h3>Recent Customer</h3>
                  <NavLink
                    to={`/${role.toLowerCase()}/people`}
                    className={style.viewData}
                  >
                    View All
                  </NavLink>
                </div>

                <div className={style.details}>
                  <div className={style.imageWrapper}>
                    <img
                      src={
                        customers && customers[0]?.photo
                          ? customers[0]?.photo
                          : "/assets/images/default-avatar-icon-of-social-media-user-vector.jpg"
                      }
                      alt=""
                    />
                  </div>
                  <div className={style.emailClient}>
                    {customers ? customers[0]?.email : ""}
                  </div>

                  <div className={style.emailClient}>
                    {customers ? customers[0]?.phone : ""}
                  </div>
                  <span className={style.dateRecent}>
                    {timestamptoDate1(customers ? customers[0]?.createdAt : "")}
                  </span>
                </div>
                <button
                  className={style.btnAddClient}
                  onClick={() => navigate(`/${role.toLowerCase()}/people`)}
                >
                  Add New Customer
                  <img src="/assets/images/ArrowRightWhite.png" alt="" />
                </button>
              </div>
            </div>
            <div className={style.customerLIst}>
              <div className={style.updates}>
                <div className={style.bgliner}>
                  <span className={style.bglabel}></span>
                  <span className={style.bglabel}></span>
                  <span className={style.bglabel}></span>
                </div>

                <p>Update</p>
                <h1>{addLeadingZero(updates?.length)}</h1>
              </div>
              <div className={style.client}>
                <div className={style.bgliner}>
                  <span className={style.bglabel}></span>
                  <span className={style.bglabel}></span>
                  <span className={style.bglabel}></span>
                </div>

                <p>Customers</p>
                <h1>{addLeadingZero(totalCustomer)}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  const customerDash = () => (
    <div className={style.clientScreen}>
      <div className={style.projectHeader}>
        {/* <div className={style.buildingBtn}>
          <AddProjectButton
            label="Add new Building"
            icon="/assets/images/AddOne.svg"
            onClick={() => console.log("clicked")}
            theme={{
              addProject: style.addbuilding,
              headteg: style.headteg,
              addOne: style.addOne,
            }}
          />
        </div> */}

        {projects?.length > 0 && (
          <HorizontalScrollbar>
            <ProjectCard projects={projects} />
          </HorizontalScrollbar>
        )}
      </div>
      <div className={style.featureSection}>
        <div className={style.peoplelist}>
          <div className={style.datateg}>
            <h1>{addLeadingZero(totalCustomer)}</h1>
            <p>People</p>
          </div>
          <button
            className={style.btnAddNew}
            onClick={() => navigate(`/${role.toLowerCase()}/people`)}
          >
            Add New people
            <img src="/assets/images/ArrowRightWhite.png" alt="" />
          </button>
        </div>

        <FeatureCard featureList={features} />

        <div className={style.reportedlist}>
          <h1>{addLeadingZero(features?.length)}</h1>
          <p>- Feature Request</p>

          <p>- Reported Issues</p>
        </div>
      </div>
    </div>
  );

  const userDash = () => (
    <div className={style.userMain}>
      {projects?.length > 0 && (
        <HorizontalScrollbar>
          {" "}
          <ProjectCard projects={projects} />
        </HorizontalScrollbar>
      )}
      <div className={style.featureMain}>
        <div className={style.featuerRequest}>
          {features.length > 0 && <FeatureCard featureList={features} />}
        </div>
        <div className={style.overview}>
          <div className={style.overMineText}>
            <div className={style.overviewText}>Overview</div>

            <h1>{addLeadingZero(features.length)}</h1>
            <p>Feature Request - Reported Issues</p>
          </div>
          <div className={style.overviweBtn}>
            <Button1
              type="button"
              title=""
              icon="/assets/images/ArrowRightWhite.png"
              theme={{ btn: style.btnAddNew }}
              onClick={() => navigate(`/${role.toLowerCase()}/features`)}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={style.dashMain}>
      {/* ADMIN DASHBOARD CONTENT*/}
      {role == "ADMIN" && adminDash()}

      {/* CUSTOMER DASHBOARD CONTENT*/}
      {role == "CUSTOMER" && customerDash()}

      {/* USER DASHBOARD CONTENT*/}
      {role == "USER" && userDash()}

      {deleteModal && (
        <ConfirmationModal
          isModal={deleteModal}
          text="Do you want to delete this project?"
          setIsModal={setDeleteModal}
          onClick={handleDeleteProject}
        />
      )}
    </div>
  );
};

export default Dashboard;

// <>
//   <div className="max-h-[calc(100vh-120px)] flex gap-4 1320:gap-7">
//     {/* Dashboard Section */}
//     <div className="w-[78%] 1320:w-[83%] flex flex-col gap-10 overflow-y-auto pr-2 md:pr-12">
//       <div className="flex gap-5">
//         <h2 className="headingPage">Dashboard</h2>
//         <img src="/assets/images/IconFilter.svg" alt="filter" />
//       </div>
//       <div className="flex flex-col gap-5">
//         <h2 className="headingPage">Overview</h2>
//         <div className="grid xl:grid-cols-2 min-[1440px]:grid-cols-3  gap-5">
//           <Card
//             heading={role === "ADMIN" ? "Projects" : "Buildings"}
//             barColor="#031FDC"
//             icon="/assets/images/IconAddBlue.svg"
//             width="w-[250px]  md:w-full"
//             buttonTitle="Add a New Project"
//             onClick={() => navigate(`/${role.toLowerCase()}/projects`)}
//           >
//             {projects?.length > 0 ? (
//               <h2 className={` text-lightgrey text-[24px]`}>
//                 {projects?.length}
//               </h2>
//             ) : (
//               <h2 className={` text-lightgrey text-sm`}>
//                 Discover Projects
//               </h2>
//             )}
//           </Card>
//           <Card
//             heading={"Feature Request"}
//             barColor="#4AC380"
//             icon="/assets/images/IconAddGreen.svg"
//             width="w-[250px]  md:w-full"
//             buttonTitle="Add a New Feature"
//             onClick={() => navigate(`/${role.toLowerCase()}/features`)}
//           >
//             {features.length > 0 ? (
//               <h2 className={` text-lightgrey text-[24px]`}>
//                 {features.length}
//               </h2>
//             ) : (
//               <h2 className={` text-lightgrey text-sm`}>Report Issues</h2>
//             )}
//           </Card>
//           {role === "ADMIN" ? (
//             <Card
//               heading={"Tags"}
//               barColor="#FF652B"
//               icon="/assets/images/IconGlobal.svg"
//               width="w-[250px]  md:w-full"
//               buttonTitle="All Tags"
//               onClick={() => {}}
//             >
//               <h2 className={` text-lightgrey text-[24px]`}>
//                 {customerTags.length > 0 ? customerTags.length : 0}
//               </h2>
//             </Card>
//           ) : (
//             ""
//           )}
//           {role === "ADMIN" ? (
//             <Card
//               heading={"Updates"}
//               barColor="#FFCF24"
//               icon="/assets/images/IconAddYellow.svg"
//               width="w-[250px]  md:w-full"
//               buttonTitle="Add a New Update"
//               onClick={() => navigate(`/${role.toLowerCase()}/updates`)}
//             >
//               {updates.length > 0 ? (
//                 <h2 className={` text-lightgrey text-[24px]`}>
//                   {updates.length}
//                 </h2>
//               ) : (
//                 <h2 className={` text-lightgrey text-[24px]`}>0</h2>
//               )}
//             </Card>
//           ) : (
//             ""
//           )}
//           {role === "ADMIN" ? (
//             <Card
//               heading={"Overdue Tags"}
//               barColor="#CD3333"
//               icon="/assets/images/IconInfo.svg"
//               width="w-[250px]  md:w-full"
//               buttonTitle="All Overdue Project Tags"
//               onClick={() => {}}
//             >
//               <h2 className={` text-lightgrey text-[24px]`}>
//                 {overDueCount}
//               </h2>
//             </Card>
//           ) : (
//             ""
//           )}
//           {role !== "USER" && (
//             <Card
//               heading={role === "ADMIN" ? "Customer" : "People"}
//               barColor="#8C58FB"
//               icon="/assets/images/IconAddPurple.svg"
//               width="w-[250px]  md:w-full"
//               buttonTitle="Add a New Customer"
//               onClick={() => navigate(`/${role.toLowerCase()}/people`)}
//             >
//               {totalCustomer ? (
//                 <h2 className={` text-lightgrey text-[24px]`}>
//                   {totalCustomer}
//                 </h2>
//               ) : (
//                 <h2 className={` text-lightgrey text-sm`}>
//                   Discover Customers
//                 </h2>
//               )}
//             </Card>
//           )}
//         </div>
//       </div>
//       <div className="flex items-center flex-wrap gap-3 justify-between pb-2">
//         <h1 className="headingPage  xl:hidden">Quick Access</h1>
//         <div className="flex items-center gap-12">
//           <h2 className="headingPage hidden xl:block">Quick Access</h2>
//           <Button
//             title={"Add Entry"}
//             width={"w-[170px]"}
//             onClick={handleAddEntry}
//           />
//         </div>
//         <div className="w-full 1320:w-[360px]">
//           <SelectCustom
//             options={options}
//             showSearch={true}
//             handleSelect={(value) => handleSelect(value)}
//             onSearch={handleGlobalSearch}
//             placeholder="Search..."
//             icon="/assets/images/IconSearch.svg"
//           />
//         </div>
//       </div>

//       <div className="flex flex-col gap-5">
//         <h2 className="headingPage">Statistics</h2>

//         <div className="flex flex-wrap gap-5">
//           <div className="w-full min-[1320px]:w-[calc(100%-280px)] flex flex-col bg-white rounded-[20px] gap-5 px-[15px] py-[30px] ">
//             <div className="flex justify-between items-center px-4">
//               <h2 className="text-sm text-lightgrey font-bold relative ">
//                 Activites
//               </h2>
//               <div className="relative">
//                 <Select
//                   suffixIcon={
//                     <img
//                       src="/assets/images/IconCalender.svg"
//                       alt="filter"
//                     />
//                   }
//                   defaultValue="Daily"
//                   style={{ width: 120 }}
//                   onChange={handleChange}
//                   options={[
//                     { value: "Daily", label: "Daily" },
//                     { value: "Weekly", label: "Weekly" },
//                     { value: "Monthly", label: "Monthly" },
//                     { value: "Yearly", label: "Yearly" },
//                   ]}
//                 />
//               </div>
//             </div>
//             {<Graph />}
//           </div>

//           <Card
//             heading={"Widget"}
//             width="w-[260px]"
//             barColor={"#587CFB"}
//             icon={"/assets/images/IconAddBlue.svg"}
//             buttonTitle={"Add a New Information"}
//             onClick={() => {}}
//           >
//             <h2 className={` text-lightgrey text-sm`}>Description</h2>
//           </Card>
//         </div>
//       </div>
//     </div>

//     {/* Recent Section */}
//     <div className="w-auto max-h-[calc(100vh-120px)] overflow-y-auto ">
//       <h2 className="headingPage">Recents</h2>
//       <div className="mt-7 w-full xl:w-[92%] flex flex-col gap-4 ">
//         <Card
//           heading="Notifications"
//           barColor="#D59331"
//           icon="/assets/images/IconRecentNotifications.svg"
//           alt="alt"
//           buttonTitle="No Recent Notifications..."
//           onClick={() => {}}
//         />

//         {role === "ADMIN" && (
//           <Card2
//             heading="Recent Projects"
//             barColor="#2094FF"
//             icon="/assets/images/IconAddBlue.svg"
//             alt="alt"
//             buttonTitle="No Recent Notifications..."
//             onClick={() => {}}
//           >
//             <div className="flex flex-col gap-5 w-full">
//               <div className="flex items-center gap-[90px] w-[350px]">
//                 <h2 className="text-[16px] font-semibold text-lightgrey">
//                   Project Title
//                 </h2>
//                 <h2 className="text-[16px] font-medium text-lightgrey">
//                   Description
//                 </h2>
//               </div>
//               <div className="max-h-44">
//                 {projects?.slice(0, 5).map((item, key) => {
//                   return (
//                     <div
//                       key={key}
//                       className="flex items-center gap-5 border-b-[2px] border-[#ececec] mb-3 pb-2 w-[320px]"
//                     >
//                       <div className="flex items-center gap-3 min-w-[170px] max-w-[170px]">
//                         <img
//                           src={
//                             item?.image
//                               ? item?.coverphoto
//                               : "/assets/images/IconDots.svg"
//                           }
//                           alt="dots"
//                         />
//                         <h2 className="truncate text-[16px] font-semibold text-lightgrey">
//                           {item?.title}
//                         </h2>
//                       </div>
//                       <h2 className="truncate text-[16px] font-semibold text-lightgrey">
//                         {item?.description}
//                       </h2>
//                     </div>
//                   );
//                 })}
//               </div>
//             </div>
//           </Card2>
//         )}

//         <Card2
//           heading="Feature Request/ Reported Issues"
//           barColor="#4AC380"
//           icon="/assets/images/IconAddBlue.svg"
//           alt="alt"
//           buttonTitle="No Recent Notifications..."
//           onClick={() => {}}
//         >
//           <div className="flex flex-col gap-5 w-full">
//             {features.length !== 0 && (
//               <div className="flex items-center gap-[90px] w-[350px]">
//                 <h2 className="text-[16px] font-semibold text-lightgrey">
//                   Title
//                 </h2>
//                 <h2 className="text-[16px] font-semibold text-lightgrey">
//                   Date
//                 </h2>
//                 <h2 className="text-[16px] font-semibold text-lightgrey">
//                   Added
//                 </h2>
//               </div>
//             )}
//             <div className="max-h-44">
//               {features?.slice(0, 5).map((item, key) => {
//                 return (
//                   <div
//                     key={key}
//                     className="flex items-center gap-5 border-b-[2px] border-[#ececec] mb-3 pb-2 w-[320px]"
//                   >
//                     <h2 className="truncate text-[16px] font-semibold text-lightgrey">
//                       {item?.title}
//                     </h2>
//                     <h2 className="truncate text-[16px] font-semibold text-lightgrey">
//                       {timestamptoDate(item?.createdAt)}
//                     </h2>

//                     <h2 className="truncate text-[16px] font-semibold text-lightgrey">
//                       {item?.userId?.name}
//                     </h2>
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//           {features.length === 0 && (
//             <p className="font-[16px]  text-lightgrey">No Items found...</p>
//           )}
//         </Card2>
//       </div>
//     </div>
//   </div>
//   {entryModal && (
//     <ModalContent
//       isOpen={entryModal}
//       setClose={() => setEntryModal(false)}
//       closeButton={true}
//       width={520}
//       btnYes="Save"
//     >
//       <div className="flex flex-col gap-3 mt-[16px]">
//         <h2 className="headingPage">Quick Access</h2>
//         <div
//           className={`grid ${
//             role === "USER" ? "grid-cols-1" : "grid-cols-2"
//           } gap-3`}
//         >
//           {role === "ADMIN" && (
//             <Button
//               title={"Add Project"}
//               width={"w-full"}
//               onClick={() => (
//                 setAddProjectModal(true), setEntryModal(false)
//               )}
//             />
//           )}
//           {role !== "USER" && (
//             <Button
//               title={`Add ${role === "ADMIN" ? "Customers" : "People"}`}
//               width="w-full"
//               onClick={() => (
//                 setAddCustomerModal(true), setEntryModal(false)
//               )}
//             />
//           )}

//           <Button
//             title={"Add Request"}
//             width={"w-full"}
//             onClick={() => (setAddFeatureModal(true), setEntryModal(false))}
//           />

//           {role === "ADMIN" && (
//             <Button
//               title={"Add Update"}
//               width={"w-full"}
//               onClick={() => (
//                 setAddUpdateModal(true), setEntryModal(false)
//               )}
//             />
//           )}
//         </div>
//       </div>
//     </ModalContent>
//   )}
//   {addProjectModal && (
//     <AddEditProjectModal
//       open={addProjectModal}
//       setOpen={setAddProjectModal}
//       type="Add"
//     />
//   )}
//   {addCustomerModal && (
//     <AddCustomerModal
//       open={addCustomerModal}
//       setOpen={setAddCustomerModal}
//     />
//   )}
//   {addFeatureModal && (
//     <AddFeatureUpdate
//       open={addFeatureModal}
//       setOpen={setAddFeatureModal}
//       type="Feature"
//     />
//   )}
//   {addUpdateModal && (
//     <AddFeatureUpdate
//       open={addUpdateModal}
//       setOpen={setAddUpdateModal}
//       type="Update"
//     />
//   )}
// </>
