import React from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";

import starIcon from "./../../icons/svgIcon/IconStar.svg";
import homeIcon from "./../../icons/svgIcon/IconHome.svg";
import discoveryIcon from "./../../icons/svgIcon/IconDiscovery.svg";
import requestsIcon from "./../../icons/svgIcon/IconRequests.svg";
import folderIcon from "./../../icons/svgIcon/IconFolder.svg";
import memberIcon from "./../../icons/svgIcon/IconMember.svg";
import scanIcon from "./../../icons/svgIcon/IconScan.svg";
import dangerIcon from "./../../icons/svgIcon/IconDanger.svg";
import logIcon from "./../../icons/svgIcon/IconLog.svg";
import settingIcon from "./../../icons/svgIcon/IconSetting.svg";

const svgMap = {
  starIcon,
  homeIcon,
  discoveryIcon,
  requestsIcon,
  folderIcon,
  memberIcon,
  scanIcon,
  dangerIcon,
  logIcon,
  settingIcon,
};

const SvgIcon = ({ type, opacity, hoverOpacity, isHovered, color }) => {
  const svgPath = svgMap[type];
  if (!svgPath) {
    return null;
  }

  return (
    <div>
      <ReactSVG
        src={svgPath}
        beforeInjection={(svg) => {
          const currentOpacity = isHovered ? hoverOpacity : opacity;
          svg.setAttribute(
            "style",
            `fill-opacity: ${currentOpacity}; fill: ${color}`
          );
          svg.querySelectorAll("*").forEach((element) => {
            element.setAttribute("fill-opacity", currentOpacity);
          });
        }}
      />
    </div>
  );
};

SvgIcon.propTypes = {
  type: PropTypes.oneOf([
    "starIcon",
    "homeIcon",
    "discoveryIcon",
    "requestsIcon",
    "folderIcon",
    "memberIcon",
    "scanIcon",
    "dangerIcon",
    "logIcon",
    "settingIcon",
  ]).isRequired,
  opacity: PropTypes.string,
  hoverOpacity: PropTypes.string,
  isHovered: PropTypes.bool,
  color: PropTypes.string,
};

SvgIcon.defaultProps = {
  opacity: "0.3",
  color: "white",
  hoverOpacity: "1",
  isHovered: false,
};

export default SvgIcon;
