import moment from "moment";

export const timestamptoDate = (timestamp) => {
  const momentDate = moment(timestamp);
  return momentDate.format("DD MMM YYYY");
};
export const formatTime = (timestamp) => {
  return moment(timestamp).format("h:mm A");
};

export const formatDate = (timestamp) => {
  const now = moment();
  const date = moment(timestamp);

  const isToday = now.isSame(date, "day");
  const formattedDate = isToday
    ? `Today, ${date.format("MMM DD")}`
    : date.format("MMM DD");

  return formattedDate;
};

export const timestamptoDate1 = (timestamp) => {
  const momentDate = moment(timestamp);
  return momentDate.format("DD/MM/YYYY");
};
export const getTimeAgo = (createdAt) => {
  const currentTime = moment();
  const createdAtMoment = moment(createdAt);

  const duration = moment.duration(currentTime.diff(createdAtMoment));

  if (duration.asDays() < 1) {
    // Less than a day
    return duration.hours() > 0
      ? `${duration.hours()} ${duration.hours() > 1 ? "hours" : "hour"} ago`
      : `${duration.minutes()} ${
          duration.minutes() > 1 ? "minutes" : "minute"
        } ago`;
  } else if (duration.asWeeks() < 1) {
    // Less than a week
    return `${duration.days()} ${duration.days() > 1 ? "days" : "day"} ago`;
  } else if (duration.asMonths() < 1) {
    // Less than a month
    return `${Math.floor(duration.asWeeks())} ${
      Math.floor(duration.asWeeks()) > 1 ? "weeks" : "week"
    } ago`;
  } else {
    // More than a month
    return `${Math.floor(duration.asMonths())} ${
      Math.floor(duration.asMonths()) > 1 ? "months" : "month"
    } ago`;
  }
};

export const handleImageDrop = async (acceptedFiles, setImageBase64) => {
  const file = acceptedFiles[0];
  const reader = new FileReader();
  reader.onload = (event) => {
    setImageBase64(event.target.result);
  };
  reader.readAsDataURL(file);
};

export const hexToRgb = (hex) => {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16) / 255,
        g: parseInt(result[2], 16) / 255,
        b: parseInt(result[3], 16) / 255,
      }
    : null;
};

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export function rgbToHex({ r, g, b }) {
  return (
    "#" +
    componentToHex(r * 255) +
    componentToHex(g * 255) +
    componentToHex(b * 255)
  );
}

export function toTitleCase(str) {
  return str.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
}
