import React from "react";
import { message } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationOutlined,
  InfoOutlined,
} from "@ant-design/icons";
import styled, { createGlobalStyle } from "styled-components";
import style from "./pushNotification.module.scss";

const GlobalStyle = createGlobalStyle`

  .ant-message-notice-wrapper {
    text-align: right !important;
    padding: 7px 14px 0 0 !important;
  }

  .ant-message-notice-content {
    padding: 0 !important;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 30px;
  width: 538px;
  height: 101px;
  background-color: ${({ type }) =>
    type === "success"
      ? "#E6FAF5"
      : type === "error"
      ? "#FDEEEE"
      : type === "warning"
      ? "#FDF8E8"
      : "#EEEEFE"};
  border: 1px solid
    ${({ type }) =>
      type === "success"
        ? "#00CC99"
        : type === "error"
        ? "#EB5757"
        : type === "warning"
        ? "#F2C94C"
        : "#5458F7"};
  border-left: 5px solid
    ${({ type }) =>
      type === "success"
        ? "#00CC99"
        : type === "error"
        ? "#EB5757"
        : type === "warning"
        ? "#F2C94C"
        : "#5458F7"};
`;

const MessageIcon = styled.div`
  margin-right: 22px;
  font-size: 40px;
  color: ${({ type }) =>
    type === "success"
      ? "#00CC99"
      : type === "error"
      ? "#EB5757"
      : type == "warning"
      ? "#F2C94C"
      : "#5458F7"};
`;

export const showMessage = (type, content) => {
  message.open({
    content: (
      <>
        <GlobalStyle />
        <MessageContainer type={type}>
          <MessageIcon type={type}>
            {type === "success" && <CheckOutlined />}
            {type === "error" && <CloseOutlined />}
            {type === "warning" && <ExclamationOutlined />}
            {type === "info" && <InfoOutlined />}
          </MessageIcon>
          <div className={style.body}>
            <div className={style.title}>
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </div>
            <div className={style.message}>{content}</div>
          </div>
        </MessageContainer>
      </>
    ),
    duration: 3,
  });
};
