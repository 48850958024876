import React from "react";
import * as yup from "yup";
import styles from "./authLayoutForms.module.scss";
import AuthLayout from "./AuthLayout";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button1, Input2 } from "../../component/macros";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmOtp,
  sendOtp,
  updatePasword,
} from "../../store/users/userActions";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import OtpForm from "./OtpForm";
import { showMessage } from "../../Utils/pushNotification";

const schema = yup.object({
  email: yup
    .string()
    .required("Email is required")
    .email("Email must be valid"),
  password: yup.string().min(8, "Minimum 8 characters"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const [activeOtpForm, setOtpForm] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [otpConfirmed, setOtpConfirmed] = useState(false);
  const { loading } = useSelector((state) => state.user);
  const [data, setData] = useState({});
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const handleConfirm = (e) => {
    e.preventDefault();
    setOtpError(false);
    data.otp = otp;
    dispatch(confirmOtp(data))
      .unwrap()
      .then(
        (res) => (
          showMessage("success", res?.message),
          setOtpForm(false),
          setOtpConfirmed(true)
        )
      )
      .catch((err) => {
        setOtpError(true);
      });
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleResend = (formData) => {
    setOtpError(false);
    setOtp("");
    setData(formData);
    delete data?.otp;
    dispatch(sendOtp(data));
  };

  const handleSendOTP = (formData) => {
    setData(formData);
    setOtpError(false);
    setOtp("");

    dispatch(sendOtp(formData))
      .unwrap()
      .then((res) => setOtpForm(true))
      .catch((err) => showMessage("error", err.data.message));
  };

  const handleResetPassword = (formData) => {
    dispatch(
      updatePasword({ email: formData.email, password: formData.password })
    )
      .unwrap()
      .then((res) => showMessage("success", res?.message), navigate("/"));
  };

  return (
    <AuthLayout>
      <div className={styles.signinForm}>
        {!otpConfirmed && !activeOtpForm && (
          <>
            <h2>Forgotten your password?</h2>
            <p>
              There is nothing to worry about, we'll send you a message to help
              you reset your password.
            </p>
            <form onSubmit={handleSubmit(handleSendOTP)}>
              <div className={styles.inputgroup}>
                <Input2
                  label="Email"
                  type="email"
                  name="email"
                  placeholder="olivia@untitledui.com"
                  icon="assets/images/EmailInput.svg"
                  alt=""
                  register={register("email")}
                  errors={errors}
                />
              </div>

              <div className={styles.loginbtn}>
                <Button1 title="Sent Reset Link" type="submit" />
              </div>
            </form>
          </>
        )}

        {!activeOtpForm && otpConfirmed && (
          <form onSubmit={handleSubmit(handleResetPassword)}>
            <div className={styles.inputgroup}>
              <Input2
                label="Email"
                type="email"
                name="email"
                value={data?.email}
                disabled={otpConfirmed}
                icon="assets/images/EmailInput.svg"
                alt=""
                register={register("email")}
                errors={errors}
              />

              <Input2
                label="Password"
                type="password"
                placeholder="Password"
                register={register("password")}
                name="password"
                icon="assets/images/PasswordEye.svg"
                alt=""
                errors={errors}
              />

              <Input2
                label="Confirm Password"
                type="password"
                placeholder="Confirm Password"
                register={register("confirmPassword")}
                name="confirmPassword"
                icon="assets/images/PasswordEye.svg"
                alt=""
                errors={errors}
              />
            </div>

            <div className={styles.loginbtn}>
              <Button1 title="Reset" type="submit" />
            </div>
          </form>
        )}

        {activeOtpForm && !otpConfirmed && (
          <OtpForm
            otp={otp}
            handleConfirm={handleConfirm}
            setOtp={setOtp}
            otpError={otpError}
            setOtpError={setOtpError}
            data={data}
            handleResend={handleResend}
          />
        )}
      </div>
    </AuthLayout>
  );
};

export default ForgetPassword;
