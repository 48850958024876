import React, { useState } from "react";
import { Button1, DropBox, FormInput, SideModel } from "../macros";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { ReactDropzone } from "../macros";
import { message, Spin } from "antd";
import { addUserSchema } from "../../Utils/Validations";
import { addCustomer } from "../../store/customers/customerActions";
import { handleImageDrop } from "../../Utils/functions";
import style from "./addNewCustomerModal.module.scss";
import { setAddCustomerModal } from "../../store/customers/customerSlice";
import { showMessage } from "../../Utils/pushNotification";
import Dropdown from "../macros/Dropdown";

const generateStrongPassword = () => {
  const upperCaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowerCaseChars = "abcdefghijklmnopqrstuvwxyz";
  const numberChars = "0123456789";
  const specialChars = "!@#$%&*";
  const allChars = upperCaseChars + lowerCaseChars + numberChars + specialChars;

  let password = "";

  // Ensure the password contains at least one character from each character set
  password += upperCaseChars[Math.floor(Math.random() * upperCaseChars.length)];
  password += lowerCaseChars[Math.floor(Math.random() * lowerCaseChars.length)];
  password += numberChars[Math.floor(Math.random() * numberChars.length)];
  password += specialChars[Math.floor(Math.random() * specialChars.length)];

  // Fill the rest of the password length with random characters from all character sets
  for (let i = password.length; i < 8; i++) {
    password += allChars[Math.floor(Math.random() * allChars.length)];
  }

  // Shuffle the password to avoid predictable patterns
  password = password
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");

  return password;
};

const AddNewCustomerModal = ({ isOpen }) => {
  const { role: loggedInRole } = useSelector((state) => state.user);

  const accessTypesOptions = [
    { value: "View", label: "View" },
    { value: "Edit", label: "Edit" },
    { value: "Admin", label: "Admin" },
  ];

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { accessType: "View" },
    resolver: yupResolver(addUserSchema),
  });
  const [isEnabled, setisEnabled] = useState(false);
  const [file, setFile] = useState(false);
  const [imageBase64, setImageBase64] = useState(null);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const addUserData = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      password: generateStrongPassword(),
      description: data.description || "",
      address: data.address || "",
      twoFactorAuthentication: isEnabled || false,
      photo: imageBase64 || "",
    };

    if (loggedInRole === "ADMIN") {
      addUserData.role = ["CUSTOMER"];
    } else if (loggedInRole === "CUSTOMER") {
      addUserData.role = ["USER"];
      addUserData.accessType = data.accessType;
    }
    dispatch(addCustomer(addUserData))
      .unwrap()
      .then((res) => (showMessage("success", res?.message), handleReset()))
      .catch((err) => showMessage("error", err?.data?.message));
  };
  const handleReset = () => {
    dispatch(setAddCustomerModal(false));
    reset();
  };
  return (
    <SideModel isOpen={isOpen} handleReset={handleReset}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.tutorial}>
          <div className={style.Form}>
            <h2>Add New</h2>
            <p className={style.uploadText}>
              Amazing! We have secured a new client add them here and lets see{" "}
              <br />
              where it goes!
            </p>

            <ReactDropzone
              onDrop={(acceptedFile) => (
                setFile(acceptedFile),
                handleImageDrop(acceptedFile, setImageBase64)
              )}
            >
              <DropBox text="Upload" fileName={file && file[0]?.name} />
            </ReactDropzone>

            <FormInput
              inputType="text"
              placeholder="Add full name here..."
              label="Full Name"
              register={register("name")}
              name="name"
              errors={errors}
            />
            <FormInput
              inputType="text"
              placeholder="Add email here..."
              label="Email"
              register={register("email")}
              name="email"
              errors={errors}
            />
            <FormInput
              inputType="text"
              placeholder="Add phone here..."
              label="Phone"
              register={register("phone")}
              name="phone"
              errors={errors}
            />

            {loggedInRole === "CUSTOMER" && (
              <Dropdown
                options={accessTypesOptions}
                label="Access Type"
                control={control}
                name="accessType"
                placeholder="Select Access Type"
              />
            )}
          </div>
          <div className={style.Btn}>
            <Button1
              theme={{ btn: style.addUpload }}
              title="Add"
              type="submit"
            />

            <Button1
              theme={{ btn: style.cancleUpload }}
              type="button"
              title="Cancel"
              onClick={() => {
                handleReset();
              }}
            />
          </div>
        </div>
      </form>
    </SideModel>
  );
};

export default AddNewCustomerModal;
