import React from "react";
import { useDropzone } from "react-dropzone";

const ReactDropzone = ({ borderRadius, children, onDrop }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    onDrop,
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section>
      <div
        {...getRootProps({ className: "dropzone" })}
        className="cursor-pointer"
      >
        <input {...getInputProps()} />
        {children}
      </div>
      {/* <aside className="text-[10px] mt-3 text-center">
        <ul>{files}</ul>
      </aside> */}
    </section>
  );
};

export default ReactDropzone;
