import style from "./dropBox.module.scss";

const DropBox = ({ text, fileName, size, description }) => {
  return (
    <div className={style.uploadBtn}>
      <img src="/assets/images/Download.svg" alt="" />
      <label>
        {description ? description : "Upload Picture Less than"}
        <span className={style.lightFont}>{size ? size : "10MB"}</span>
      </label>
      <span className={style.uploadNow}>{text}</span>
      <p className="text-[8px]">{fileName}</p>
    </div>
  );
};

export default DropBox;
