import React from "react";
import Select from "react-select";
import style from "./dropDownWithoutForm.module.scss";

const CustomDropdownWithoutForm = ({
  options,
  label,
  onChange,
  value,
  placeholder,
  isMultiple,
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #bfbfbf",
      boxShadow: "none",
      padding: "12px",
      borderRadius: "0px",
      "&:hover": {
        borderColor: "#aaa",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#ffffff",
      backgroundColor: "black",
      padding: "8px",
      border: "none",
      "&:hover": {
        color: "#ffffff",
      },
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "5px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f0f0f0" : "#fff",
      color: "#333",
      padding: "10px",
      fontSize: "13px",
      cursor: "pointer",
      "&:active": {
        backgroundColor: "#e0e0e0",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#999",
      fontSize: "16px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#333",
      fontSize: "16px",
    }),
  };

  return (
    <>
      <p className={style.labelText}>{label}</p>
      <Select
        styles={customStyles}
        options={options}
        placeholder={placeholder}
        isMulti={isMultiple}
        value={
          isMultiple ? value : options.find((option) => option.value === value)
        }
        onChange={
          isMultiple
            ? onChange
            : (selectedOption) => onChange(selectedOption.value)
        }
      />
    </>
  );
};

export default CustomDropdownWithoutForm;
