import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../@api/axios";

export const addTutorial = createAsyncThunk(
  "tutorial/addTutorial",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/tutorial`, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getTutorials = createAsyncThunk(
  "tutorial/getTutorials",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/tutorial`);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateTutorial = createAsyncThunk(
  "tutorial/updateTutorial",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(`/tutorial/` + id, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteTutorials = createAsyncThunk(
  "tutorials/deleteTutorials",
  async (ids, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/deletemultiple`, ids);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
