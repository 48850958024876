import { Ability, AbilityBuilder } from "@casl/ability";

// Defines how to detect object's type
function subjectName(item) {
  if (!item || typeof item === "string") {
    return item;
  }
  return item.__type;
}

const ability = new Ability([], { subjectName });
const handleUserRole = (role, access) => {
  if (role) {
    ability.update(defineRulesFor(role, access));
  }
};

function defineRulesFor(role, access) {
  const { can, cannot, rules } = new AbilityBuilder();

  if (role === "ADMIN") {
    can("manage", "all");
  } else if (role === "CUSTOMER") {
    can("view", "Project");
    cannot("create", "Project");
    can("edit", "Project");
    can("delete", "Project");
    can("create", "Asset");
    can("edit", "Asset");
    can("delete", "Asset");
    can("delete", "AssetFile");
    can("edit", "AssetFile");
    can("view", "ProjectField");
    can("view", "Tutorial");
  } else if (role === "USER") {
    if (access === "Edit") {
      can("create", "Asset");
      can("edit", "Asset");
      can("delete", "Asset");
      can("delete", "AssetFile");
      can("edit", "AssetFile");
    } else {
      cannot("create", "Asset");
      cannot("edit", "Asset");
      cannot("delete", "Asset");
      cannot("delete", "AssetFile");
      cannot("edit", "AssetFile");
    }
    if (access === "Admin") {
      can("view", "Project");
      cannot("create", "Project");
      can("edit", "Project");
      can("delete", "Project");
      can("create", "Asset");
      can("edit", "Asset");
      can("delete", "Asset");
      can("delete", "AssetFile");
      can("edit", "AssetFile");
      can("view", "ProjectField");
      can("view", "Tutorial");
      can("create", "User");
    }
    can("view", "Project");
    cannot("create", "Project");
    cannot("edit", "Project");
    cannot("delete", "Project");
    cannot("view", "People");
    cannot("view", "Dashboard");
    cannot("view", "ProjectField");
    can("view", "Tutorial");
  }
  return rules;
}

export { ability, handleUserRole };
