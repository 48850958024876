import { message, Popover, Select, Spin } from "antd";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Button1,
  FormInput,
  Input,
  Input2,
  TableComponent,
} from "../component/macros";
import AddFeatureUpdate from "../component/modals/AddFeatureUpdate";
import DeleteModal from "../component/modals/DeleteModal";
import useDebounce from "../hooks/useDebounce";
import { deleteFeature, getFeatures } from "../store/feature/featureActions";
import { timestamptoDate } from "../Utils/functions";
import style from "./feature.module.scss";
import SortBy from "../component/macros/SortBy/SortBy";
import { setFeatureModel } from "../store/feature/featureSlice";
import AddUpdateFeatureModel from "../component/modals/InsertFeatureUpdateModal";
import FilterBy from "../component/macros/filterBy/filterBy";
import ConfirmationModal from "../component/modals/ConfitmationModal";
import { showMessage } from "../Utils/pushNotification";

const filterOptions = [
  { value: "Username", label: "Username" },
  { value: "Title", label: "Title" },
];
const Feature = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [searchValue, oldValue] = useDebounce(value, 500, true);
  const { features, loading, isOpenModel } = useSelector(
    (state) => state.feature
  );
  const [exportData, setExportData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [chosenFeature, setChosenFeature] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [modifiedDataSource, setModifiedDataSource] = useState(() => features);
  const [sortType, setSortType] = useState("asc");
  const [filter, setFilter] = useState({ name: "Username", value: "" });
  // const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setFilter({ name: "Username", value: "" });
    return () => {
      setFilter({ name: "Username", value: "" });
    };
  }, []);

  useEffect(() => {
    if (searchValue !== "" || (searchValue === "" && oldValue !== "")) {
      dispatch(getFeatures({ query: searchValue }));
    } else {
      dispatch(getFeatures({ query: searchValue }));
    }
  }, [searchValue, oldValue]);
  const handleDelete = () => {
    dispatch(deleteFeature(chosenFeature?._id))
      .unwrap()
      .then(
        (res) => (showMessage("success", res?.message), setDeleteModal(false))
      )
      .catch(
        (err) => (
          showMessage("error", "Error Deleting Feature"), setDeleteModal(false)
        )
      );
  };
  useEffect(() => {
    setExportData(
      features.map((feature) => {
        const item = {
          Username: feature?.userId?.name,
          Email: feature?.userId?.email,
          Title: feature?.title,
          Details: feature?.details,
          DateAdded: timestamptoDate(feature?.createdAt),
        };
        return item;
      })
    );
  }, [features]);

  const handleFilterChange = (option) => {
    setFilter({ name: option, value: "" });
  };
  const handleSort = (data) => {
    return [...data]?.sort((a, b) => {
      if (sortType === "asc") {
        return a?.title?.localeCompare(b?.title);
      } else if (sortType === "desc") {
        return b?.title?.localeCompare(a?.title);
      }
      return 0;
    });
  };

  useEffect(() => {
    if (filter?.value > "") {
      switch (filter?.name) {
        case "Username": {
          const data = features?.filter((feature) =>
            feature?.userId?.name
              ?.toLowerCase()
              ?.includes(filter?.value?.toLowerCase())
          );
          setModifiedDataSource(handleSort(data));
          break;
        }
        case "Title": {
          const data = features?.filter((feature) =>
            feature?.title
              ?.toLowerCase()
              ?.includes(filter?.value?.toLowerCase())
          );
          setModifiedDataSource(handleSort(data));
          break;
        }
        default:
          setModifiedDataSource(handleSort(features));
          break;
      }
    } else {
      setModifiedDataSource(handleSort(features));
    }
  }, [filter?.name, filter?.value, sortType, features]);

  const FEATURE_COLUMNS = [
    {
      title: (
        <div>
          Username
          {/* <img
            src="/assets/images/IconArrow.svg"
            alt="sorterarrow"
            className="w-[10px]"
          /> */}
        </div>
      ),
      dataIndex: ["userId", "name"],
    },
    {
      title: (
        <div>
          Email
          {/* <img
            src="/assets/images/IconArrow.svg"
            alt="sorterarrow"
            className="w-[10px]"
          /> */}
        </div>
      ),
      dataIndex: ["userId", "email"],
    },
    {
      title: (
        <div>
          Title
          {/* <img
            src="/assets/images/IconArrow.svg"
            alt="sorterarrow"
            className="w-[10px]"
          /> */}
        </div>
      ),
      sorter: (a, b) => a.title.localeCompare(b.title),
      dataIndex: "title",
    },
    {
      title: (
        <div>
          Details
          {/* <img
            src="/assets/images/IconArrow.svg"
            alt="sorterarrow"
            className="w-[10px]"
          /> */}
        </div>
      ),
      dataIndex: "details",
    },
    {
      title: <div>Date Added</div>,
      dataIndex: "createdAt",
      render: (text) => {
        return <p className="mx-auto text-center">{timestamptoDate(text)}</p>;
      },
    },
    {
      title: <div className="flex items-center gap-3">Actions</div>,
      dataIndex: "actions",
      render: (text, record) => {
        return (
          <Button1
            title="Delete"
            onClick={() => (setChosenFeature(record), setDeleteModal(true))}
          />
        );
      },
    },
  ];

  const sortByOptions = useMemo(
    () => [
      {
        label: "Ascending",
        value: "asc",
        isSelected: sortType === "asc",
      },
      {
        label: "Descending",
        value: "desc",
        isSelected: sortType === "desc",
      },
    ],
    [sortType]
  );

  return (
    <>
      <div className={style.featureMain}>
        <div className={style.headerTop}>
          <div className={style.leftside}>
            <Button1
              title="Add Feature Request"
              theme={{ btn: style.addNew }}
              onClick={() => {
                dispatch(setFeatureModel(true));
              }}
            />

            <CSVLink data={exportData} filename={"Features"}>
              <Button1 title="Export " theme={{ btn: style.exportBtn }} />
            </CSVLink>
          </div>

          <div className={style.rightside}>
            <div className="search">
              {/* <FormInput
                inputType="search"
                placeholder="Search"
                theme={{ labelText: style.labelInput }}
                onChange={(e) => setValue(e?.target?.value)}
              /> */}
              <Input2
                placeholder="Search"
                type="search"
                icon="/assets/images/searchIconBlack.svg"
                name="search"
                theme={{ input: style.labelInput }}
                onChange={(e) => setValue(e?.target?.value)}
              />
            </div>

            <FilterBy
              defaultValue={filterOptions[0]?.value}
              onChange={handleFilterChange}
              options={filterOptions}
              filter={filter}
              setFilter={setFilter}
            />

            <SortBy
              items={sortByOptions}
              onChange={(e) => setSortType(e.target.value)}
            />
          </div>
        </div>

        <div className="body content">
          {loading && !features && <Spin />}

          <div className="table data" style={{ width: "100%" }}>
            <TableComponent
              columns={FEATURE_COLUMNS}
              data={modifiedDataSource}
            />
          </div>

          {/* Delete Feature Model */}
          {deleteModal && (
            <ConfirmationModal
              isModal={deleteModal}
              setIsModal={setDeleteModal}
              text="Do you want to delete this feature?"
              onClick={handleDelete}
            />
          )}
        </div>
        <AddUpdateFeatureModel isOpen={isOpenModel} type="Feature" />
      </div>
    </>
  );
};

export default Feature;

{
  /* <div className="flex flex-col gap-5">
<h1 className="headingPage  xl:hidden">Feature Requests</h1>
<div className="flex items-center justify-between gap-3 flex-wrap">
  <div className="flex items-center gap-8">
    <div className="flex items-center gap-5">
      <Button1
        title="Add Feature Request"
        theme={{ btn: styles.addNew }}
        onClick={() => {
          setAddModal(true);
        }}
      />

      <CSVLink data={exportData} filename={"Features"}>
        <Button title="Export" />
      </CSVLink>
    </div>
  </div>

  <div>
    <Input
      type="search"
      placeholder="Search..."
      width="w-[360px]"
      onChange={(e) => setValue(e?.target?.value)}
    />
    <Popover content={sortContent} trigger="click" placement="bottom">
      <p1>SORT</p1>
    </Popover>
    <Popover content={filterContent} trigger="click" placement="bottom">
      <p1>FILTER</p1>
    </Popover>
  </div>
</div>

<div className="md:max-w-[calc(100vw-153px)] lg:max-w-[calc(100vw-170px)] bg-[#FCFCFC] pt-10 pb-4 px-10 flex flex-col items-center gap-3 rounded-2xl max-h-[calc(100vh-368px)] xl:max-h-[calc(100vh-250px)]">
  <div className="pl-[22px] flex items-center gap-7 self-start">
    <Popover content={sortContent} trigger="click" placement="bottom">
      <div className="flex items-center gap-3  cursor-pointer">
        <span className="heading4 text-[#545456]">Sort</span>
        <img src="/assets/images/IconSort.svg" alt="IconSearch" />
      </div>
    </Popover>
    <Popover content={filterContent} trigger="click" placement="bottom">
      <div className="flex items-center gap-3  cursor-pointer">
        <span className="heading4 text-[#545456]">Filter Results</span>
        <img src="/assets/images/IconFilter.svg" alt="IconSearch" />
      </div>
    </Popover>
    {filter?.value && (
      <>
        <p>Filter Applied:</p>
        <div
          className="border border-secondary rounded-xl px-4 py-1 text-xs text-secondary cursor-pointer"
          onClick={() => {
            setFilter({ name: "Username", value: "" });
          }}
        >
          <p>
            {filter?.name}: {filter?.value}
          </p>
        </div>
      </>
    )}
  </div>
  {loading && !features ? (
    <Spin />
  ) : (
    <TableComponent
      columns={FEATURE_COLUMNS}
      data={modifiedDataSource}
      placeholder="No Feature Requests..."
    />
  )}
</div>
{addModal && (
  <AddFeatureUpdate
    loading={loading}
    open={addModal}
    setOpen={setAddModal}
    type="Feature"
  />
)}
{deleteModal && (
  <DeleteModal
    loading={loading}
    open={deleteModal}
    setOpen={setDeleteModal}
    handleDelete={handleDelete}
  />
)}
</div> */
}
