import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { AbilityContext } from "../../@permissions/Can";
import { getAsset, getFiles, uploadFile } from "../../store/asset/assetActions";
import { hexToRgb, rgbToHex } from "../../Utils/functions";
import { tagSchema } from "../../Utils/Validations";
import { Button, Button1, ModalContent } from "../macros";
import AssetDetails from "../tags/AssetDetails";
import Files from "../tags/Files";
import Messaging from "../tags/Messaging";
import style from "./tagDetailsModal.module.scss";

const tabs = [
  { id: 1, label: "Details" },
  { id: 2, label: "Files" },
  { id: 3, label: "Comments" },
];

const TagDetailsModal = ({ open, setOpen, selectedTag, handleTagDetails }) => {
  const ability = useContext(AbilityContext);
  const canEdit = ability.can("edit", "Asset");
  const [currentTab, setCurrentTab] = useState(tabs[0].id);
  const [imageBase64, setImageBase64] = useState(null);
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [extension, setExtension] = useState("");
  const dispatch = useDispatch();
  useMemo(() => {
    if (file !== null) {
      const parts = file[0]?.name?.split(".");
      setExtension(parts[parts?.length - 1]);
      setName(parts[parts?.length - 2]);
    }
  }, [file]);
  useEffect(() => {
    if (selectedTag) {
      dispatch(getFiles(selectedTag._id));
      dispatch(getAsset(selectedTag._id));
    }
  }, []);
  const handleFileSubmit = () => {
    const form_data = { file: imageBase64 };
    dispatch(
      uploadFile({ id: selectedTag._id, name, file: form_data, extension })
    );
    setOpen(false);
  };
  const handleDetailSubmit = (data) => {
    const newdata = { ...data };
    newdata.color = hexToRgb(data?.color);
    newdata.selectedTag = selectedTag._id;
    handleTagDetails(newdata);
    setOpen(false);
  };

  const handleTabClick = (tabId) => {
    setCurrentTab(tabId);
  };
  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: selectedTag?.title,
      assetName: selectedTag?.assetName,
      description: selectedTag?.description,
      assetValue: selectedTag?.assetValue,
      assetModel: selectedTag?.assetModel,
      maintenanceDate: selectedTag?.maintenanceDate,
      maintenanceProvider: selectedTag?.maintenanceProvider,
      color: selectedTag && rgbToHex(selectedTag?.color),
    },
    resolver: yupResolver(tagSchema),
  });
  return (
    <>
      <ModalContent
        isOpen={open}
        setClose={() => console.log("set close")}
        width={780}
      >
        <form
          onSubmit={handleSubmit(handleDetailSubmit)}
          className={style.main}
        >
          <div className={style.tagDetailsMain}>
            <div className={style.tagHeadingNavbar}>
              <h2 className={style.navHeadingText}>Tag Details</h2>

              <div class={style.tagManu}>
                <ul class={style.navbarMenu}>
                  {tabs.map((tab) => (
                    <li
                      key={tab.id}
                      className={`${style.navItem} ${
                        currentTab === tab.id ? style.activeBorder : ""
                      }`}
                    >
                      <a
                        onClick={() => handleTabClick(tab.id)}
                        className={`${
                          currentTab === tab.id ? style.active : ""
                        }`}
                      >
                        {tab.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div>
            {currentTab === 1 ? (
              <AssetDetails
                register={register}
                control={control}
                canEdit={canEdit}
                errors={errors}
                chosenData={selectedTag}
              />
            ) : currentTab === 2 ? (
              <Files
                setFile={setFile}
                fileName={name}
                setImage={setImageBase64}
              />
            ) : currentTab === 3 ? (
              <Messaging chosenData={selectedTag} />
            ) : null}
          </div>

          <div className="flex items-center gap-6">
            {currentTab === 1 && canEdit ? (
              <Button1
                title={"Save"}
                btnType="submit"
                theme={{ btn: style.btn }}
              />
            ) : currentTab === 2 ? (
              <Button1
                title={"Save"}
                onClick={handleFileSubmit}
                theme={{ btn: style.btn }}
              />
            ) : (
              ""
            )}
          </div>
        </form>

        <div className={style.closeButton} onClick={() => setOpen(false)}>
          <div className={style.icon}>
            <img src="/assets/images/closeButtonIcon.svg" alt="Close" />
          </div>
        </div>
      </ModalContent>
    </>
  );
};

export default TagDetailsModal;
