import { createSlice } from "@reduxjs/toolkit";
import {
  cancelSubscription,
  checkoutSubscription,
  getSubscriptions,
  updateSubscription,
} from "./subscriptionActions";

const initialState = {
  allowedProjects: 0,
  allowedUsers: 0,
  appliedToCancel: false,
  loading: false,
  error: null,
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkoutSubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkoutSubscription.fulfilled, (state, action) => {
        state.allowedProjects = action.payload.result?.allowedProjects;
        state.allowedUsers = action.payload.result?.allowedUsers;
        state.loading = false;
        state.error = null;
      })
      .addCase(checkoutSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.data?.message;
      })
      .addCase(updateSubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        state.allowedProjects = action.payload.result?.allowedProjects;
        state.allowedUsers = action.payload.result?.allowedUsers;
        state.loading = false;
        state.error = null;
      })
      .addCase(updateSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.data?.message;
      })
      .addCase(cancelSubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.appliedToCancel = action.payload.appliedToCancel;
        state.error = null;
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.data?.message;
      })
      .addCase(getSubscriptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubscriptions.fulfilled, (state, action) => {
        state.allowedProjects = action.payload.result?.allowedProjects;
        state.allowedUsers = action.payload.result?.allowedUsers;
        state.appliedToCancel = action.payload.result?.appliedToCancel;
        state.loading = false;
        state.error = null;
      })
      .addCase(getSubscriptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.data?.message;
      });
  },
});

export const subscriptionReducer = subscriptionSlice.reducer;
