import styles from "./addProjectButton.module.scss";
import { themr } from "react-css-themr";

const AddProjectButton = (props) => {
  const { label, icon, onClick, theme } = props;
  return (
    <button className={theme.addProject} onClick={onClick}>
      <span className={theme.addOne}>
        <img src={icon} alt="" />
      </span>
      <label className={theme.headteg}>{label}</label>
    </button>
  );
};

export default themr("AddProjectButton", styles)(AddProjectButton);
