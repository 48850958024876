import React from "react";
import useDrivePicker from "react-google-drive-picker";

const GoogleDrivePicker = ({ setData }) => {
  console.log("Google Drive Picker");
  const [openPicker, authResponse] = useDrivePicker();
  const handleOpenPicker = () => {
    openPicker({
      clientId: process.env.REACT_APP_GDRIVE_CLIENT_ID,
      developerKey: process.env.REACT_APP_GDRIVE_DEVELOPER_KEY,
      viewId: "DOCS",
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        setData(data);
      },
    });
  };

  return (
    <div>
      <button onClick={() => handleOpenPicker()}>
        Select From Google Drive
      </button>
    </div>
  );
};

export default GoogleDrivePicker;
