import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { handleUserRole } from "../@permissions/Ability";
import { assetReducer } from "./asset/assetSlice";
import { customerReducer } from "./customers/customerSlice";
import { featureReducer } from "./feature/featureSlice";
import { notificationReducer } from "./notifications/notificationSlice";
import { projectReducer } from "./projects/projectSlice";
import { subscriptionReducer } from "./subscription/subscriptionSlice";
import { tutorialReducer } from "./tutorials/tutorialSlice";
import { updateReducer } from "./updates/updateSlice";
import { userReducer } from "./users/userSlice";
import { contactUsReducer } from "./contactUs/contactUsSlice";

const rootReducer = combineReducers({
  user: userReducer,
  customer: customerReducer,
  project: projectReducer,
  tutorial: tutorialReducer,
  update: updateReducer,
  feature: featureReducer,
  subscription: subscriptionReducer,
  asset: assetReducer,
  notification: notificationReducer,
  contactUs: contactUsReducer,
});
const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});
const persistor = persistStore(store);
// permissions
let currentAuth;
store?.subscribe(() => {
  const prevAuth = currentAuth;
  currentAuth = store.getState().user;
  if (prevAuth !== currentAuth) {
    handleUserRole(currentAuth.role, currentAuth.accessType);
  }
  //
});

export { store, persistor };
