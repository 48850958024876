import { createSlice } from "@reduxjs/toolkit";
import { addTutorial, getTutorials, updateTutorial } from "./tutorialActions";

const initialState = {
  tutorials: [],
  loading: false,
  error: false,
  isOpenModel: false,
  selectedValue: undefined,
};
const tutorialSlice = createSlice({
  name: "tutorial",
  initialState,
  reducers: {
    setTutorialModel: (state, action) => {
      state.isOpenModel = action.payload;
    },
    setSelectedItem: (state, action) => {
      state.selectedValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTutorial.pending, (state) => {
        state.loading = true;
      })
      .addCase(addTutorial.fulfilled, (state, action) => {
        const tutorial = action.payload.result;
        const tutorials = state.tutorials;
        state.tutorials = [tutorial, ...tutorials];
        state.loading = false;
      })
      .addCase(addTutorial.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data.message;
      })
      .addCase(getTutorials.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTutorials.fulfilled, (state, action) => {
        state.tutorials = action.payload.result;
        state.loading = false;
      })
      .addCase(getTutorials.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data.message;
      })
      .addCase(updateTutorial.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTutorial.fulfilled, (state, action) => {
        const tutorial = action.payload.result;
        const tutorials = state.tutorials;
        const filteredtutorials = tutorials.filter(function (obj) {
          return obj._id !== tutorial._id;
        });
        state.tutorials = [tutorial, ...filteredtutorials];
        state.loading = false;
      })
      .addCase(updateTutorial.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data.message;
      });
  },
});

export const tutorialReducer = tutorialSlice.reducer;
export const { setSelectedItem, setTutorialModel } = tutorialSlice.actions;
