import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../@api/axios";

export const addFeature = createAsyncThunk(
  "features/addFeature",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/featureRequest`, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getFeatures = createAsyncThunk(
  "features/getFeatures",
  async (data, { rejectWithValue }) => {
    try {
      const { rows = 100, page = 1, query } = data;
      const res = await axiosInstance.get(
        `/featureRequests?page=` + page + `&limit=` + rows + `&query=` + query
      );
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteFeature = createAsyncThunk(
  "features/deleteFeature",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.delete(`/featureRequest/` + id);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
