import { createSlice } from "@reduxjs/toolkit";
import { getNotifications } from "./notificationActions";

const initialState = {
  notifications: [],
  loading: false,
  error: false,
};
const notificationSlice = createSlice({
  name: "tutorial",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload.result.userNotifications;
        state.loading = false;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const notificationReducer = notificationSlice.reducer;
