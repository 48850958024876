import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleImageDrop } from "../../Utils/functions";
import { DropBox, ReactDropzone } from "../macros";
import { timestamptoDate } from "../../Utils/functions";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { saveAs } from "file-saver";
import DeleteModal from "../modals/DeleteModal";
import { deleteFile } from "../../store/asset/assetActions";
import { Upload, message } from "antd";
import ConfirmationModal from "../modals/ConfitmationModal";
import { showMessage } from "../../Utils/pushNotification";
import style from "./files.module.scss";

const Files = ({ fileName, setImage, setFile }) => {
  const { selectedAssetFiles, loading } = useSelector((state) => state.asset);
  const [deleteModal, setDeleteModal] = useState();
  const [chosenFile, setChosenFile] = useState();
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deleteFile(chosenFile?._id))
      .unwrap()
      .then(
        (res) => (showMessage("success", res?.message), setDeleteModal(false))
      )
      .catch((err) => {
        showMessage("error", "Could not delete file");
      });
  };
  const actions = [
    {
      id: 1,
      imageSrc: "/assets/images/IconAssignProject.svg",
      name: "Download",
      onClick: (file) => {
        saveAs(file?.file, file?.file.replace(/\.[^/.]+$/, ""));
      },
    },

    {
      id: 2,
      imageSrc: "/assets/images/IconDelete.svg",
      name: "Delete",
      onClick: (data) => {
        setChosenFile(data);
        setDeleteModal(true);
      },
    },
  ];
  return (
    <div className="h-full overflow-y-auto">
      <div className="flex flex-col gap-7 ">
        <div className="my-3">
          <div className="w-full flex flex-col gap-1 mt-1.5 max-h-[130px] overflow-auto">
            {selectedAssetFiles.length > 0 ? (
              selectedAssetFiles?.map((item, i) => (
                <div
                  key={i}
                  className="flex justify-between items-center p-1 w-full hover:bg-white/[0.3] rounded-lg"
                >
                  <div className="flex flex-col ">
                    <span className="text-xs font-medium">{item?.name}</span>
                    <span className="text-[10px]">
                      {timestamptoDate(item?.createdAt)}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    {actions?.map((action, index) => (
                      <div key={index}>
                        <img
                          data-tooltip-id={`my-tooltip-${action.id}`}
                          src={action?.imageSrc}
                          alt={action?.imageSrc}
                          className="cursor-pointer"
                          onClick={() => action?.onClick(item)}
                        />
                        <ReactTooltip
                          style={{
                            backgroundColor: "#ffff",
                            color: "#545456",
                            fontSize: 9,
                            fontWeight: 600,
                            borderRadius: "15px",
                          }}
                          id={`my-tooltip-${action.id}`}
                          place="top"
                          content={action.name}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <p className="text-secondary text-[18px] italic ">
                No file found please upload to see the search results...
              </p>
            )}
          </div>
        </div>
        <div className="mb-5">
          <ReactDropzone
            onDrop={(acceptedFile) => {
              setFile(acceptedFile);
              handleImageDrop(acceptedFile, setImage);
            }}
          >
            <div className={style.dropBoxMain}>
              <img
                src="/assets/images/fileDropImage.svg"
                alt="File drop here"
              />
              <p className={style.fileName}>{fileName}</p>
            </div>
          </ReactDropzone>
        </div>
      </div>
      {deleteModal && (
        <ConfirmationModal
          text="Do you want to delete this file?"
          isModal={deleteModal}
          setIsModal={setDeleteModal}
          onClick={handleDelete}
        />
      )}
    </div>
  );
};

export default Files;
