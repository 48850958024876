import { useMemo } from "react";
import style from "./filterBy.module.scss";
import { Popover, Select } from "antd";

const FilterBy = ({ defaultValue, onChange, options, filter, setFilter }) => {
  const filterContent = useMemo(() => {
    return (
      <div className="flex gap-2">
        <Select
          size={"middle"}
          defaultValue={defaultValue}
          onChange={onChange}
          style={{ width: 200 }}
          options={options}
        />

        <input
          className="border border-solid border-[#d9d9d9] rounded-md px-2 py-1"
          placeholder="Search..."
          type="text"
          value={filter?.value}
          maxLength={60}
          onChange={(e) =>
            setFilter((prev) => ({ ...prev, value: e.target.value }))
          }
        />
      </div>
    );
  }, [options, filter]);

  return (
    <div className="filter">
      <Popover content={filterContent} trigger="click" placement="bottom">
        <div className={style.filterClient}>
          <img src="/assets/images/Fill.svg" alt="" />
          Filter
        </div>
      </Popover>
    </div>
  );
};

export default FilterBy;
