import React, { useState } from "react";
import "./../../Styles/antd.css";

const SwitchToggle = ({ handleToggle, isChecked }) => {
  return (
    <div
      className={`hover:bg-white/[0.8] bg-white/[0.5] customTransition h-[44px] shadow-md px-3 py-2 rounded-[20px] flex items-center justify-between gap-2 text-lg font-semibold `}
    >
      <div className="flex items-center gap-3">
        <img src="/assets/images/IconPhone.svg" alt="icon" />
        <span className="heading4 text-primary">
          {!isChecked ? "Enable" : "Disable"}
        </span>
      </div>
      <label className="switch">
        <input type="checkbox" checked={isChecked} onChange={handleToggle} />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default SwitchToggle;
