import React, { useState } from "react";
import { Modal } from "antd";
import Button1 from "./../macros/Button1";
import { styled } from "styled-components";
import styles from "./confirmation.module.scss";
import { ReactComponent as LogoutIcon } from "./../../icons/svgIcon/rightTickIcon.svg";
import { ReactComponent as LogoutBackGroundImage } from "./../../icons/svgIcon/logoutBackGround.svg";

const StyledModal = styled(Modal)`
  .ant-modal {
    width: max-content;
  }
  .ant-modal-content {
    background: none; // Remove background
    box-shadow: none; // Remove shadow
  }

  .ant-modal-header,
  .ant-modal-footer {
    display: none; // Hide header and footer if not needed
  }

  .ant-modal-body {
    padding: 0; // Remove padding
  }

  .ant-modal-close {
    display: none; // Hide close button if not needed
  }
`;

const ConfirmationModal = ({ isModal, onClick, setIsModal, text }) => {
  return (
    <>
      <StyledModal
        open={isModal}
        centered
        // width={309}
        closeIcon={false}
        footer={null}
        onCancel={() => setIsModal(false)}
        maskStyle={{ opacity: 0.3 }}
      >
        <div className={styles.mainContent}>
          <div className={styles.bgliner}>
            <span className={styles.bglabel}></span>
            <span className={styles.bglabel}></span>
            <span className={styles.bglabel}></span>
          </div>
          <span className={styles.tickIcon}>
            <LogoutIcon />
          </span>
          <p className={styles.mainText}>Are you sure?</p>
          <p className={styles.text}>{text}</p>

          <Button1
            type="button"
            title="Yes"
            onClick={onClick}
            theme={{ btn: styles.yesBtn }}
          />
          <Button1
            type="button"
            title="Cancel"
            onClick={() => setIsModal(false)}
            theme={{ btn: styles.cancelBtn }}
          />
        </div>
      </StyledModal>
    </>
  );
};

export default ConfirmationModal;
