import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../@api/axios";

export const getNotifications = createAsyncThunk(
  "notification/getNotifications",
  async ({ user }, { rejectWithValue }) => {
    if (user?.role[0] === "ADMIN") {
      try {
        const res = await axiosInstance.get(
          `/user/adminnotifications/${user._id}`
        );
        return res;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
    if (user?.role[0] === "CUSTOMER") {
      try {
        const res = await axiosInstance.get(`/user/notifications/${user._id}`);
        return res;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
    if (user?.role[0] === "USER") {
      try {
        const res = await axiosInstance.get(
          `/user/peoplenotification/${user._id}`
        );
        return res;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  }
);

export const readNotification = createAsyncThunk(
  "notification/readNotification",
  async ({ logId, userId }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(`/user/notifications/markread`, {
        logId,
        userId,
      });
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
