import style from "./addBIMModel.module.scss";
import {
  Button1,
  DropBox,
  FormInput,
  ModalContent,
  ReactDropzone,
} from "../macros";
import { useState } from "react";
import axios from "axios";
import { showMessage } from "../../Utils/pushNotification";
import { useDispatch } from "react-redux";
import {
  getSingleProject,
  uploadBimModel,
} from "../../store/projects/projectActions";
import axiosInstance from "../../@api/axios";

const AddBIMModel = ({ projectId }) => {
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();

  const refetchProject = () => {
    dispatch(getSingleProject(projectId))
      .unwrap()
      .then((res) => console.log("Project refetched"))
      .catch((err) => console.log(err));
  };

  const handleSave = async () => {
    if (!file) {
      console.error("No file uploaded");
      return;
    }

    try {
      setProgress(0);

      const formData = new FormData();
      formData.append("file", file[0]);
      formData.append("fileName", file[0]?.name);
      formData.append("projectId", projectId);

      let token = localStorage.getItem("customer_access_token")
        ? localStorage.getItem("customer_access_token")
        : localStorage.getItem("access_token"); // Replace with your actual token

      const response = await axios
        .post(
          `${process.env.REACT_APP_GEOMAUS_API_BASE_URL}/bim/upload`, // Replace with your server endpoint
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const percentCompleted = Math.round((loaded * 100) / total);
              setProgress(percentCompleted);
            },
          }
        )
        .then(() => {
          setProgress(100);
          showMessage("info", "BIM Model will be available after processing");
          setIsOpenModel(false);
          refetchProject();
        });
    } catch (error) {
      showMessage("error", "Failed to upload file");
    } finally {
      setProgress(0);
    }
  };

  return (
    <>
      <Button1
        title="Add BIM model"
        theme={{ btn: style.addBimButton }}
        type="button"
        onClick={() => setIsOpenModel(true)}
      />
      {isOpenModel && (
        <ModalContent
          isOpen={isOpenModel}
          setClose={() => setIsOpenModel(false)}
        >
          <div className={style.dropZoneWrapper}>
            <h2>Upload BIM Model File</h2>
            <p className={style.uploadText}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              <br /> sed do eiusmod tempor incididunt ut labore et .
            </p>
            <ReactDropzone onDrop={(acceptedFiles) => setFile(acceptedFiles)}>
              <DropBox
                text="Upload"
                fileName={file && file[0]?.name}
                description={"Drag and Drop Your File Less than "}
                size={"1GB"}
              />
            </ReactDropzone>

            {progress > 0 && (
              <div className={style.progressMain}>
                <div>
                  <img src="/assets/images/fileIcon.svg" alt="FileIcon" />
                </div>
                <div
                  className={`${style.uploadContainer} ${
                    progress === 0 ? style.hidden : ""
                  }`}
                >
                  <div className={style.fileInfo}>
                    <span>{file && file[0]?.name}</span>
                  </div>
                  <div className={style.progressBarMain}>
                    <div className={style.progressBar}>
                      <div
                        className={style.progress}
                        style={{ width: `${progress}%` }}
                      ></div>
                    </div>
                    <span className={style.progressPercentage}>
                      {progress}%
                    </span>
                  </div>
                </div>
                <div>
                  {progress == 100 && (
                    <img src="/assets/images/blueTickIcon.svg" alt="FileIcon" />
                  )}
                </div>
              </div>
            )}

            <Button1
              title="Save"
              onClick={handleSave} // Call handleSave function on button click
              type="button"
              disabled={!file ? true : false} // Disable button if no file or uploading
            />
          </div>
        </ModalContent>
      )}
    </>
  );
};

export default AddBIMModel;
