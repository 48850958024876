import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../@api/axios";

export const createAsset = createAsyncThunk(
  "assets/createAsset",
  async (body, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/asset`, body);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchAssets = createAsyncThunk(
  "assets/fetchAssets",
  async (data, { rejectWithValue }) => {
    try {
      const { id } = data;
      const res = await axiosInstance.get(`/asset/` + id);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getFiles = createAsyncThunk(
  "assets/getFiles",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/asset/files/` + id);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteAsset = createAsyncThunk(
  "assets/deleteAsset",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.delete(`/asset/` + id);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateAsset = createAsyncThunk(
  "assets/updateAsset",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(`/asset/` + id, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const completeAsset = createAsyncThunk(
  "assets/completeAsset",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(`/asset/complete/` + id, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadFile = createAsyncThunk(
  "assets/uploadFile",
  async ({ id, name, file, extension }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/asset/Upload/` + id, {
        name,
        file,
        extension,
      });
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCustomerTags = createAsyncThunk(
  "assets/getCustomerTags",

  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/getcustomerstag`);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteFile = createAsyncThunk(
  "assets/deleteFile",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.delete(`/asset/file/` + id);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAsset = createAsyncThunk(
  "assets/getAsset",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/getAsset/` + id);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createAssetComment = createAsyncThunk(
  "assets/createAssetComment",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/assetComment`, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
