import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { RouterProvider } from "react-router-dom";
import { ability } from "./@permissions/Ability";
import { AbilityContext } from "./@permissions/Can";
import { AppRouter } from "./routes";
const App = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHKEY);
  return (
    <AbilityContext.Provider value={ability}>
      <Elements stripe={stripePromise}>
        <RouterProvider router={AppRouter} />
      </Elements>
    </AbilityContext.Provider>
  );
};

export default App;
