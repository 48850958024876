import { message, Popover } from "antd";
import React, { useState } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Button,
  Button1,
  Input,
  Input2,
  ReactDropzone,
} from "../../../component/macros";
import DeleteModal from "../../../component/modals/DeleteModal";
import StorageModal from "../../../component/modals/StorageModal";
import {
  deleteProjectFile,
  getProjectFiles,
} from "../../../store/projects/projectActions";
import ConfirmationModal from "../../../component/modals/ConfitmationModal";
import { showMessage } from "../../../Utils/pushNotification";
import SideModel from "../../../component/macros/sideModel";
import style from "./storage.module.scss";

const Storage = ({ open, setOpen }) => {
  const [isModal, setModal] = useState(false);
  const [deleteFileModal, setDeleteFileModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(false);
  const { projectFiles, loading } = useSelector((state) => state.project);
  const [searchQuery, setSearchQuery] = useState("");
  const { id: projectId } = useParams();
  const dispatch = useDispatch();
  useMemo(() => {
    dispatch(getProjectFiles(projectId));
  }, []);
  const handleDeleteFile = () => {
    dispatch(deleteProjectFile(selectedFile._id))
      .unwrap()
      .then(
        (res) => (
          showMessage("success", res?.message), setDeleteFileModal(false)
        )
      )
      .catch(
        (err) => (
          showMessage("error", "Error Deleting Asset File"),
          setDeleteFileModal(false)
        )
      );
  };

  return (
    <SideModel
      isOpen={open}
      handleReset={setOpen}
      theme={{ sideModel: style.sideModel }}
    >
      {/* <div className="my-5 pb-2 flex flex-col gap-5 h-full self-start overflow-y-auto">
        <h3 className="text-[22px] text-primary font-medium self-center">
          Storage
        </h3>
        <Input
          type="search"
          placeholder="Search File..."
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {projectFiles.length > 0 ? (
          ""
        ) : (
          <p className="italic text-secondary text-lg ">
            No file found please upload to see the search results...
          </p>
        )}
        <Button
          title="Upload File"
          width={"w-full"}
          onClick={() => {
            setModal(!isModal);
          }}
          titleColor="text-primary"
          Icon="/assets/images/IconFileUpload.svg"
          IconPlacement="left"
        />
        <div className="w-full min-h-[1px] h-[1px] bg-secondary my-5" />
        {projectFiles.length > 0 ? (
          <div>
            <h3 className="text-[22px] text-primary font-medium text-center">
              Recent Files
            </h3>

            {projectFiles
              ?.filter((item) =>
                item?.name?.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((item) => (
                <Popover
                  content={
                    <div>
                      <div
                        className="cursor-pointer"
                        onClick={() => (
                          setSelectedFile(item), window.open(item.file)
                        )}
                      >
                        Download
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => (
                          setSelectedFile(item), setDeleteFileModal(true)
                        )}
                      >
                        Delete
                      </div>
                    </div>
                  }
                  trigger="click"
                >
                  <div
                    // onClick={() => window.open(item.file)}
                    key={item?._id}
                    className="flex mt-1 hover:bg-[#D9D9D9] py-2 hover:shadow-md px-3 rounded-full  items-center justify-between "
                  >
                    <span className="text-lg text-primary truncate">
                      {item?.name}
                    </span>
                  </div>
                </Popover>
              ))}
          </div>
        ) : (
          ""
        )}
        <Button1
          type="button"
          title="Cancel"
          onClick={setOpen}
          theme={{ btn: style.cancelUpload }}
        />
        {isModal && <StorageModal open={isModal} setOpen={setModal} />}
        {deleteFileModal && (
          <ConfirmationModal
            isModal={deleteFileModal}
            setIsModal={setDeleteFileModal}
            onClick={handleDeleteFile}
          />
        )}
      </div> */}

      <div className={style.storageSideBar}>
        <h1 className={style.storageHading}>Storage</h1>
        <Input2
          placeholder="Search"
          type="search"
          icon="/assets/images/searchIcon.svg"
          name="search"
          onChange={(e) => setSearchQuery(e.target.value)}
          theme={{ input: style.input }}
        />
        {projectFiles.length > 0 ? (
          ""
        ) : (
          <p className="text-white text-lg ">
            No file found please upload to see the search results...
          </p>
        )}
        <Button1
          title="Upload File"
          type="button"
          onClick={() => {
            setModal(true);
          }}
          theme={{ btn: style.uploadFileStorage }}
        />

        {projectFiles.length > 0 ? (
          <div>
            <h3 className={style.storageListHeading}>Recent Files</h3>

            {projectFiles
              ?.filter((item) =>
                item?.name?.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((item) => (
                <div
                  // onClick={() => window.open(item.file)}
                  key={item?._id}
                  className={style.recentFileList}
                >
                  {/* flex mt-1 hover:bg-[#D9D9D9] py-2 hover:shadow-md px-3 rounded-full  items-center justify-between */}
                  <span className={style.storageListItem}>
                    {item?.name}
                    {/* text-lg text-primary truncate */}
                  </span>

                  <div className={style.listItemIcons}>
                    <span
                      className={style.listIcon}
                      onClick={() => (
                        setSelectedFile(item), window.open(item.file)
                      )}
                    >
                      <img
                        src="/assets/images/downloadWhiteIcon.svg"
                        alt="Download"
                      />
                    </span>
                    <span
                      className={style.listIcon}
                      onClick={() => (
                        setSelectedFile(item), setDeleteFileModal(true)
                      )}
                    >
                      <img
                        src="/assets/images/deleteWhiteIcon.svg"
                        alt="Delete"
                      />
                    </span>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          ""
        )}

        <Button1
          type="button"
          title="Cancel"
          onClick={setOpen}
          theme={{ btn: style.cancelUpload }}
        />
        {isModal && <StorageModal open={isModal} setOpen={setModal} />}

        {deleteFileModal && (
          <ConfirmationModal
            isModal={deleteFileModal}
            setIsModal={setDeleteFileModal}
            text={"Do you want to delete this file?"}
            onClick={handleDeleteFile}
          />
        )}
      </div>
    </SideModel>
  );
};

export default Storage;
