import { Dropdown, Empty } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useSocket from "../../hooks/useSocket";
import styles from "./topBar.module.scss";

import {
  getNotifications,
  readNotification,
} from "../../store/notifications/notificationActions";
import { removeCustomerAccess } from "../../store/users/userActions";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { getTimeAgo } from "../../Utils/functions";
import { Button1, ProfileDropdown } from "../macros";
import AddCustomerModal from "../modals/AddCustomerModal";
const TopBar = () => {
  const { notifications } = useSelector((state) => state.notification);
  const [currentRoute, setCurrentRoute] = useState("");
  const { user } = useSelector((state) => state.user.userData);
  const { isCustomer } = useSelector((state) => state.user);
  const { projects } = useSelector((state) => state.project);
  const { features } = useSelector((state) => state.feature);

  const { customers } = useSelector((state) => state.customer);

  const { isConnected, lastNotification } = useSocket();
  const [userNotifications, setUserNotifications] = useState(notifications);
  const [notificationCount, setNotificationCount] = useState(0);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const role = localStorage.getItem("role");

  useEffect(() => {
    if (lastNotification && isConnected) {
      const updatedNotifications = [
        ...userNotifications,
        lastNotification,
      ].sort((left, right) => {
        return moment.utc(right.createdAt).diff(moment.utc(left.createdAt));
      });
      setUserNotifications(updatedNotifications);
    }
  }, [lastNotification, notifications]);
  useEffect(() => {
    fetchNotifications();
  }, [lastNotification]);

  useEffect(() => {
    const notiCount = userNotifications.filter(({ read }) => !read).length;
    setNotificationCount(notiCount);
  }, [userNotifications]);

  const fetchNotifications = async () => {
    dispatch(getNotifications({ user }));
  };

  const handleReturn = () => {
    dispatch(removeCustomerAccess(user.role[0]));
    navigate(`/${user.role[0].toLowerCase()}/dashboard`);
  };

  const handleRead = async (notification) => {
    dispatch(
      readNotification({ logId: notification._id, userId: notification.userId })
    );
    fetchNotifications();
  };
  const location = useLocation();
  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location]);

  const notificationContent = (
    <div className="custom-notification-content bg-white min-w-[300px] overflow-y-auto py-2 max-w-[320px] max-h-[300px] rounded-md shadow-md">
      {userNotifications.length > 0 ? (
        userNotifications.map((notification, index) => (
          <div
            onClick={() => handleRead(notification)}
            key={index}
            className="custom-notification-item px-3 flex flex-col py-1.5 hover:bg-gray-100 cursor-pointer"
          >
            <span className="text-sm text-[#000E65]">{notification.title}</span>
            <div className="flex justify-between items-center">
              <span className="self-end text-xs text-[#6F7CA8]">
                🕓 {getTimeAgo(notification.createdAt)}
              </span>
              <span className="self-end text-xs text-[#6F7CA8]">
                {notification?.read ? (
                  <img
                    src="/assets/images/IconMailOpened.svg"
                    alt="MailIcon"
                    className="w-[15px]"
                  />
                ) : (
                  <img
                    src="/assets/images/IconMailClosed.svg"
                    alt="MailIcon"
                    className="w-[15px]"
                  />
                )}
              </span>
            </div>
          </div>
        ))
      ) : (
        <Empty
          description={
            <span className="text-xs italic text-secondary ">
              No Notifications
            </span>
          }
        />
      )}
    </div>
  );

  return (
    // <div>
    //   <div className="flex items-center justify-between">
    //     <h2
    //       className={`${
    //         currentRoute.toLowerCase().includes("matterport") && "invisible"
    //       }  text-[22px] lg:text-[28px] font-semibold select-none`}
    //     >
    //       Good Morning {user?.name && user?.name} 👋🏻
    //     </h2>

    //     <div className="flex items-center gap-2 md:gap-5">
    //       {isCustomer ? (
    //         <div
    //           onClick={handleReturn}
    //           className="text-center text-xs bg-primary hover:scale-[1.05] whitespace-nowrap customTransition py-1.5 font-medium px-2 rounded-md text-white cursor-pointer "
    //         >
    //           Return to Dashboard
    //         </div>
    //       ) : (
    //         ""
    //       )}
    //       <div
    //         onClick={() => setAddCustomerModal(true)}
    //         className="cursor-pointer hover:scale-[1.1] customTransition"
    //       >
    //         <img
    //           data-tooltip-id={`invite-tooltip-1`}
    //           src="/assets/images/IconSMS.svg"
    //           alt="IconSMS"
    //           className="w-[80%] md:w-full"
    //         />
    //         <ReactTooltip
    //           style={{
    //             backgroundColor: "#000E65",
    //             color: "#fff",
    //             fontSize: 9,
    //             fontWeight: 600,
    //             borderRadius: "15px",
    //           }}
    //           id={`invite-tooltip-1`}
    //           place="bottom"
    //           content={"Invite User"}
    //         />
    //       </div>

    //       <Dropdown
    //         placement="bottomRight"
    //         overlay={() => notificationContent}
    //         trigger={["click"]}
    //       >
    //         <div className=" cursor-pointer hover:scale-[1.1] customTransition">
    //           <img
    //             data-tooltip-id={`notifications-tooltip-1`}
    //             src="/assets/images/IconNotification.svg"
    //             alt="IconNotification"
    //             className="w-[80%] md:w-full"
    //           />
    //           <ReactTooltip
    //             style={{
    //               backgroundColor: "#000E65",
    //               color: "#fff",
    //               fontSize: 9,
    //               fontWeight: 600,
    //               borderRadius: "15px",
    //             }}
    //             id={`notifications-tooltip-1`}
    //             place="bottom"
    //             content={"Notifications"}
    //           />
    //         </div>
    //       </Dropdown>

    //       <ProfileDropdown />
    //     </div>
    //   </div>

    //   <div
    //     className={`${
    //       currentRoute?.includes("dashboard") ? " hidden " : " invisible "
    //     } bg-white w-[40px] h-[40px] rounded-full flex items-center justify-center mt-5 cursor-pointer`}
    //   >
    //     <img
    //       src="/assets/images/IconArrow.svg"
    //       alt="IconArrow"
    //       className="rotate-90 w-4"
    //     />
    //   </div>
    //   {addCustomerModal && (
    //     <AddCustomerModal
    //       open={addCustomerModal}
    //       setOpen={setAddCustomerModal}
    //     />
    //   )}
    // </div>

    <div className={styles.mainTopbar}>
      {isCustomer && (
        <div className={styles.backButton} onClick={handleReturn}>
          <img src="/assets/images/blueBackIcon.svg" alt="" />
          <span className={styles.text}>Go Back to Admin</span>
        </div>
      )}
      <div className={styles.topbarHeading}>
        <div className={styles.topTitel}>
          <h2 className={styles.headerText}>
            {!isCustomer && user.role == "ADMIN" && (
              <>Total Clients & Buildings</>
            )}
            {(user.role == "CUSTOMER" || isCustomer) && (
              <>Total Buildings and Users</>
            )}
            {user.role == "USER" && (
              <>
                Total buildings and <br />
                feature request
              </>
            )}
          </h2>

          {/* {isCustomer && (
            <div
              onClick={handleReturn}
              className="text-center text-xs bg-primary hover:scale-[1.05] whitespace-nowrap customTransition py-1.5 font-medium px-2 rounded-md text-white cursor-pointer "
            >
              Go back to Admin
            </div>
          )} */}
        </div>
        <div className={styles.topTask}>
          <div className={styles.taskInfo1}>
            <div className={styles.taskNumber}>{projects?.length}+</div>
            <p>
              Your total projects
              <br />
              (buildings)
            </p>
          </div>
          <div className={styles.taskInfo2}>
            <div className={styles.taskNumber}>
              {!isCustomer && user.role == "ADMIN" && (
                <>{customers?.length || 0}+</>
              )}
              {(user.role == "CUSTOMER" || isCustomer) && (
                <>{customers?.length || 0}+</>
              )}

              {user.role == "USER" && <>{features?.length || 0}+</>}
            </div>
            <p>
              {!isCustomer && user.role == "ADMIN" && (
                <>
                  Total Clients (Customers)
                  <br /> you have
                </>
              )}
              {(user.role == "CUSTOMER" || isCustomer) && (
                <>
                  Total Users
                  <br /> you have
                </>
              )}

              {user.role == "USER" && (
                <>
                  Total Feature
                  <br /> Request
                </>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
