import React, { useState } from "react";
import { MATTERPORT_MENU } from "../../Utils/mock";
import style from "./matterPortSIdebar.module.scss";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { Can } from "../../@permissions/Can";

const MatterportSidebar = ({ setChosen, chosen }) => {
  const [hoverItem, setHoverItem] = useState("");
  const role = useSelector((state) => state.user);

  const handleMouseEnter = (itemName) => {
    setHoverItem(itemName);
  };

  const handleMouseLeave = () => {
    setHoverItem("");
  };

  return (
    <div className={style.sidebarMain}>
      <div className={style.menuWrapper}>
        {MATTERPORT_MENU?.map((item, index) => {
          if (MATTERPORT_MENU.length - 1 == index) {
            return (
              <Can I="create" a="Asset">
                {" "}
                <div
                  key={item.id}
                  className={`${style.menuItem} ${
                    chosen === item.name ? style.active : ""
                  }`}
                  onClick={() => setChosen(item.name)}
                  onMouseEnter={() => handleMouseEnter(item.name)}
                  onMouseLeave={handleMouseLeave}
                >
                  <ReactSVG
                    src={item.icon}
                    beforeInjection={(svg) => {
                      svg.classList.add("icon");
                      const paths = svg.querySelectorAll("path");
                      paths.forEach((path) => {
                        if (hoverItem === item.name) {
                          path.setAttribute("fill", "#ffffff");
                        } else {
                          path.setAttribute(
                            "fill",
                            chosen === item.name ? "#ffffff" : "black"
                          );
                        }
                      });
                    }}
                  />
                  <p className={style.text}>{item.name}</p>
                </div>
              </Can>
            );
          } else {
            return (
              <div
                key={item.id}
                className={`${style.menuItem} ${
                  chosen === item.name ? style.active : ""
                }`}
                onClick={() => setChosen(item.name)}
                onMouseEnter={() => handleMouseEnter(item.name)}
                onMouseLeave={handleMouseLeave}
              >
                <ReactSVG
                  src={item.icon}
                  beforeInjection={(svg) => {
                    svg.classList.add("icon");
                    const paths = svg.querySelectorAll("path");
                    paths.forEach((path) => {
                      if (hoverItem === item.name) {
                        path.setAttribute("fill", "#ffffff");
                      } else {
                        path.setAttribute(
                          "fill",
                          chosen === item.name ? "#ffffff" : "black"
                        );
                      }
                    });
                  }}
                />
                <p className={style.text}>{item.name}</p>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default MatterportSidebar;
