import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_GEOMAUS_API_BASE_URL,
  // timeout: 3000,
  // headers: {
  //   "Content-Type": "*",
  // },
});

axiosInstance.interceptors.request.use(
  (config) => {
    try {
      let token = localStorage.getItem("customer_access_token")
        ? localStorage.getItem("customer_access_token")
        : localStorage.getItem("access_token");
      if (token) {
        config.headers.Authorization = "Bearer " + token;
        config.headers.Accept = "application/json";
      }
    } catch (err) {
      console.log("axiosInstance.interceptors.request error: " + err);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);
export default axiosInstance;
