import React, { useEffect, useState } from "react";
import { Button1, FormInput, RadioButtonGroup } from "./../index";

import { yupResolver } from "@hookform/resolvers/yup";
import { message, Spin } from "antd";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomer } from "../../../store/customers/customerActions";
import { handleImageDrop } from "../../../Utils/functions";
import style from "./userForm.module.scss";

import { addUserSchema } from "../../../Utils/Validations";
import {
  Button,
  Input,
  ModalContent,
  ReactDropzone,
  SingleSelect,
  TextArea,
} from "./../index";
import {
  setCustomerDataForEdit,
  setEditCustomerModal,
} from "../../../store/customers/customerSlice";
import Dropdown from "../Dropdown";
import { updateUserProfile } from "../../../store/users/userActions";
import { showMessage } from "../../../Utils/pushNotification";

const UserForm = ({ type }) => {
  const { loading, selectedCustomerForEdit } = useSelector(
    (state) => state.customer
  );
  const { role: loggedInRole, userData } = useSelector((state) => state.user);

  const [isEnabled, setIsEnabled] = useState(
    userData?.user?.twoFactorAuthentication
  );

  const [changeImage, setChangeImage] = useState(false);
  const [imageBase64, setImageBase64] = useState(null);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: selectedCustomerForEdit?.name,
      email: selectedCustomerForEdit?.email,
      phone: selectedCustomerForEdit?.phone,
      description: selectedCustomerForEdit?.description ?? "",
      address: selectedCustomerForEdit?.address,
      accessType: selectedCustomerForEdit?.accessType,
    },
    resolver: yupResolver(addUserSchema),
  });

  const handleReset = () => {
    dispatch(setEditCustomerModal(false));
    dispatch(setCustomerDataForEdit(undefined));
    reset();
  };

  const onSubmit = async (submitData) => {
    submitData.twoFactorAuthentication = isEnabled;

    if (type == "Setting") {
      if (imageBase64) {
        submitData.photo = imageBase64;
      } else {
        submitData.photo = userData?.user?.photo;
      }

      dispatch(updateUserProfile({ id: userData?.user?._id, data: submitData }))
        .unwrap()
        .then((res) => showMessage("success", res?.message), reset())

        .catch((err) => showMessage("error", "Could not update user profile"));
    } else {
      if (imageBase64) {
        submitData.photo = imageBase64;
      }
      if (loggedInRole === "ADMIN") {
        delete submitData.accessType;
      }
      dispatch(
        updateCustomer({ id: selectedCustomerForEdit._id, data: submitData })
      )
        .unwrap()
        .then((res) => (showMessage("success", res?.message), handleReset()))
        .catch((err) => {
          showMessage("error", err.data?.message);
        });
    }
  };

  useEffect(() => {
    if (selectedCustomerForEdit) {
      setValue("name", selectedCustomerForEdit?.name);
      setValue("email", selectedCustomerForEdit?.email);
      setValue("phone", selectedCustomerForEdit?.phone);
      setValue("description", selectedCustomerForEdit?.description);
      setValue("address", selectedCustomerForEdit?.address);
      setValue("accessType", selectedCustomerForEdit?.accessType);

      setIsEnabled(selectedCustomerForEdit?.twoFactorAuthentication);
    }
  }, selectedCustomerForEdit);

  useEffect(() => {
    if (userData?.user) {
      setValue("name", userData?.user?.name);
      setValue("email", userData?.user?.email);
      setValue("phone", userData?.user?.phone);
      setValue("description", userData?.user?.description);
      setValue("address", userData?.user?.address);
      setValue("accessType", userData?.user?.accessType);
    }
  }, [userData]);

  const accessTypesOptions = [
    { value: "View", label: "View" },
    { value: "Edit", label: "Edit" },
    { value: "Admin", label: "Admin" },
  ];

  const getImage = () => {
    if (type == "Setting") {
      return imageBase64 ? imageBase64 : userData?.user?.photo;
    } else if (type == "Edit") {
      if (selectedCustomerForEdit) {
        return imageBase64 ? imageBase64 : selectedCustomerForEdit?.photo;
      } else {
        return userData?.user?.photo;
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.profileFrom}>
          <div className={style.profileUpload}>
            <h4>Avatar</h4>
            <div className={style.profileImage}>
              <img
                className={style.imageLogo}
                src={
                  getImage() != null &&
                  getImage() != undefined &&
                  getImage() != ""
                    ? getImage()
                    : "/assets/images/ellipseProfile.png"
                }
                alt=""
              />

              <ReactDropzone
                onDrop={(acceptedFile) => (
                  setFile(acceptedFile),
                  handleImageDrop(acceptedFile, setImageBase64)
                )}
              >
                <Button1
                  title="Upload"
                  type="button"
                  theme={{ btn: style.uploadBtn }}
                />
              </ReactDropzone>

              <Button1
                title="Remove"
                type="button"
                onClick={() => setImageBase64(null)}
                theme={{
                  btn: imageBase64 ? style.uploadBtn : style.uploadRemove,
                }}
              />
            </div>
          </div>

          <div className={style.formInformation}>
            <div className={style.formDetails}>
              <FormInput
                label="Full Name*"
                placeholder="Enter full name"
                register={register("name")}
                name="name"
                errors={errors}
              />
            </div>
            {/* <div className={style.formDetails}>
              <FormInput
                label="Last Name*"
                placeholder="Lorem ipsum dolor sit amet"
              />
            </div> */}
            <div className={style.formDetails}>
              <FormInput
                label="Email Adress*"
                inputType="email"
                placeholder="Enter email address"
                register={register("email")}
                name="email"
                errors={errors}
              />
            </div>
            <div className={style.formDetails}>
              <FormInput
                label="Phone Number*"
                inputType="text"
                name="phone"
                placeholder="Enter phone number"
                register={register("phone")}
                errors={errors}
              />
            </div>

            {type == "Setting" && (
              <>
                {" "}
                <div className={style.formDetails}>
                  <FormInput
                    label="Password*"
                    inputType="password"
                    placeholder="Enter password"
                    register={register("password")}
                    name="password"
                    errors={errors}
                  />
                </div>
                <div className={style.formDetails}>
                  <FormInput
                    label="confirm Password*"
                    inputType="password"
                    placeholder="Enter confirm password"
                    register={register("confirmPassword")}
                    name="confirmPassword"
                    errors={errors}
                  />
                </div>{" "}
              </>
            )}
          </div>
          <div className={style.formDiscription}>
            <div className="radioButtonGroupWrapper">
              <p className={style.labelText}>Two Factor Authentication</p>
              <div className={style.radioButtonGroup}>
                <div className={style.radioItem}>
                  <input
                    type="radio"
                    id={`radioItem-1`}
                    name="twoFactorAuthentication"
                    checked={isEnabled == true}
                    onChange={(e) => {
                      setIsEnabled(true);
                    }}
                  />
                  <label
                    for="radioItem-1"
                    onClick={(e) => {
                      setIsEnabled(true);
                    }}
                  >
                    Enable
                  </label>
                </div>
                <div className={style.radioItem}>
                  <input
                    type="radio"
                    id={`radioItem-2`}
                    name="twoFactorAuthentication"
                    checked={isEnabled == false}
                    onChange={(e) => {
                      setIsEnabled(false);
                    }}
                  />
                  <label
                    for="radioItem-2"
                    onClick={(e) => {
                      setIsEnabled(false);
                    }}
                  >
                    Disable
                  </label>
                </div>
              </div>
            </div>

            <div className={style.profileDiscription}>
              <FormInput
                label="Description*"
                inputType="textArea"
                register={register("description")}
                errors={errors}
                name="description"
                theme={{ textarea: style.discriptionBtn }}
                placeholder="Two Factor Authentification
        Enable
        Disable
        Discription
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
              />
            </div>

            <div className={style.profileDiscription}>
              <FormInput
                label="Address*"
                inputType="textArea"
                register={register("address")}
                errors={errors}
                name="address"
                theme={{ textarea: style.discriptionBtn }}
                placeholder="Enter your Address"
              />
            </div>
            {type != "Setting" && (
              <>
                {loggedInRole === "CUSTOMER" && (
                  <Dropdown
                    options={accessTypesOptions}
                    label="Access Type"
                    control={control}
                    name="accessType"
                    placeholder="Select Access Type"
                  />
                )}
              </>
            )}
            <div
              className={` ${
                type == "Setting" ? style.profileSubmitBtn : style.btn
              }`}
            >
              <Button1
                title="Save"
                theme={{
                  btn: `${type == "Setting" ? style.saveBtn : style.addUpload}`,
                }}
                type="submit"
              />

              {type != "Setting" && (
                <Button1
                  theme={{ btn: style.cancleUpload }}
                  type="button"
                  title="Cancel"
                  onClick={() => {
                    handleReset();
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default UserForm;
