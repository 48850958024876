import styles from "./dashboardCard.module.scss";
import { NavLink } from "react-router-dom";

const DashboardCard = (props) => {
  const { cardData } = props;
  return (
    <div className={styles.overviwecard}>
      <h2>Overview</h2>
      {cardData?.map((item) => (
        <div className={styles.headingNub}>
          <div className={styles.hedingLeft}>
            <div className={styles.hedingNumbar}>{item.count}</div>
            <span
              className={styles.headingLeft1}
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
          </div>

          <div className={styles.overviweBtn}>
            <NavLink to={item.route} className={styles.rightArrow}>
              <img src="/assets/images/arrowRight.svg" alt="" />
            </NavLink>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardCard;
