import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Input,
  ModalContent,
  ReactDropzone,
  SwitchToggle,
  TextArea,
} from "./../component/macros";
import { updateUserProfile } from "../store/users/userActions";
import { message } from "antd";
import { profileSchema } from "../Utils/Validations";
import { handleImageDrop } from "../Utils/functions";

import {
  Button1,
  FormInput,
  RadioButtonGroup,
  UserForm,
} from "../component/macros";
import style from "./settingPage.module.scss";
const SettingPage = () => {
  const { user, loading } = useSelector((state) => state.user.userData);
  const [isFullText, setIsFullText] = useState(false);

  const toggleFullText = () => {
    setIsFullText(!isFullText);
  };

  return (
    <div className={style.settingMain}>
      <div className={style.profileDetail}>
        <div className={style.profileHeader}>
          <div className={style.profileIdName}>
            <div className={style.profileName}>
              <img
                src={`${
                  user?.photo
                    ? user?.photo
                    : "/assets/images/ellipseProfile.png"
                }`}
                alt=""
              />
              <div className={style.profileUserName}>
                <h1>{user?.name}</h1>
                <p>{user?.email}</p>
              </div>
            </div>
            <div className={style.profileCloseBtn}>
              <Button1 title="Cancel" theme={{ btn: style.profileBtn }} />
            </div>
          </div>
          <div className={style.profileIdInfo}>
            <div className={`${style.profileInfo} ${style.phone}`}>
              <h2>Phone number:</h2>
              <div className={style.celinetDetail}>
                {user?.phone ? `+${user?.phone}` : ""}
              </div>
            </div>
            <div className={`${style.profileInfo} ${style.address}`}>
              <h2>Address:</h2>
              <div className={style.celinetDetail}>{user?.address}</div>
            </div>
            <div className={`${style.profileInfo} ${style.description}`}>
              <h2>Description:</h2>
              {user?.description && (
                <div className={style.celinetDetail}>
                  {isFullText
                    ? user?.description
                    : `${user?.description?.slice(0, 55)}`}{" "}
                  <span
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={toggleFullText}
                  >
                    {isFullText ? " Read Less" : "Read More..."}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={style.profileDetail}>
        <UserForm type="Setting" />
      </div>
    </div>
  );
};

export default SettingPage;
