import { message } from "antd";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  assignProject,
  assignProjectUser,
  getProjectsByCustomer,
} from "../../store/customers/customerActions";
import { getProjects } from "../../store/projects/projectActions";
import {
  Button,
  Button1,
  DropBox,
  FormInput,
  ModalContent,
  MultiSelect,
} from "../macros";
import { SideModel } from "../macros";
import style from "./projectAssignModal.module.scss";
import Dropdown from "../macros/Dropdown";
import {
  setAssignProjectModel,
  setCustomerForAssignProject,
} from "../../store/projects/projectSlice";

import { setProjectModel } from "../../store/projects/projectSlice";
import { showMessage } from "../../Utils/pushNotification";

const ProjectAssignModal = ({ isOpen, type, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [customerProjects, setCustomerProjects] = useState([]);
  const { customers } = useSelector((state) => state.customer);
  const { role } = useSelector((state) => state.user);
  const { projects, loading } = useSelector((state) => state.project);

  useEffect(() => {
    if (id) {
      dispatch(getProjectsByCustomer({ id, type }))
        .unwrap()
        .then((res) => setCustomerProjects(res?.result));
      // dispatch(getProjects({ type: "UnAssigned" }));
    }
  }, [id]);

  const onSubmit = async (data) => {
    const Projects = data?.assignProject;
    const isLabelValueArray =
      Array.isArray(Projects) &&
      Projects.every((item) => item && item.label && item.value);
    let projectValues;
    if (isLabelValueArray) {
      projectValues = Projects.map((item) => item.value);
    } else {
      projectValues = Projects;
    }
    if (role === "ADMIN") {
      dispatch(assignProject({ id: id, data: projectValues }))
        .unwrap()
        .then((res) => (showMessage("success", res?.message), handleReset()))
        .catch((err) => showMessage("error", "Error Assigning Project"));
    } else if (role === "CUSTOMER") {
      dispatch(assignProjectUser({ id: id, data: projectValues }))
        .unwrap()
        .then((res) => (showMessage("success", res?.message), handleReset()))
        .catch((err) => showMessage("error", "Error Assigning Project"));
    }
  };
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: {},
  } = useForm();

  const assignAllBuildings = () => {
    const selectedProjects = projects?.map((project) => ({
      value: project._id,
      label: project.title,
    }));
    setValue("assignProject", selectedProjects);
  };
  useEffect(() => {
    const selectedProjects = customerProjects?.map((project) => ({
      value: project._id,
      label: project.title,
    }));
    setValue("assignProject", selectedProjects);
  }, [customerProjects]);

  useEffect(() => {
    setOptions(
      projects?.map((project) => ({
        value: project._id,
        label: project.title,
      }))
    );
  }, [projects]);

  const handleReset = () => {
    dispatch(setAssignProjectModel(false));
    dispatch(setCustomerForAssignProject(undefined));
    reset();
  };

  return (
    <SideModel isOpen={isOpen} handleReset={handleReset}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.main}>
          <h2>Assign Project</h2>
          <p className={style.uploadText}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et .
          </p>
          <div className={style.projectAddBtn}>
            <Button1
              title="Add a Project"
              type="button"
              theme={{ btn: style.btn }}
              Icon="/assets/images/IconPlus.svg"
              onClick={() => dispatch(setProjectModel(true))}
            />

            <Button1
              title="Assign All Buildings"
              type="button"
              theme={{ btn: style.btn }}
              onClick={() => assignAllBuildings()}
              Icon="/assets/images/IconPlus.svg"
            />
          </div>

          <Dropdown
            placeholder="Selects Projects..."
            options={options}
            control={control}
            name="assignProject"
            isMultiple
          />

          <div className={style.projectBtn}>
            <Button1
              theme={{ btn: style.addUpload }}
              title="Assign Project"
              type="submit"
            />

            <Button1
              theme={{ btn: style.cancleUpload }}
              type="button"
              title="Cancel"
              onClick={() => {
                handleReset();
              }}
            />
          </div>
        </div>
      </form>
    </SideModel>
  );
};

export default ProjectAssignModal;
