import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Button1, ModalContent, ReactDropzone } from "../macros";
import GoogleDrivePicker from "../macros/GooglePicker";
import { handleImageDrop } from "../../Utils/functions";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getProjectWithAssets,
  projectFileUpload,
} from "../../store/projects/projectActions";
import SideModel from "../macros/sideModel";
import style from "./storageModal.module.scss";
import CustomDropdownWithoutForm from "../macros/dropDownWithoutForm";
import { showMessage } from "../../Utils/pushNotification";

// const TABS = [
//   {
//     id: 1,
//     title: "My Drive",
//   },
//   {
//     id: 2,
//     title: "Upload",
//   },
// ];
const StorageModal = ({ open, setOpen, fromStoragePage, options }) => {
  const [imageBase64, setImageBase64] = useState(null);
  const { loading } = useSelector((state) => state.asset);
  const [name, setName] = useState("");
  const [extension, setExtension] = useState("");
  const [driveData, setDriveData] = useState();
  const [file, setFile] = useState(null);
  const [activeTab, setActiveTab] = useState(2);
  const [selectOptions, setSelectedOption] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");

  console.log("selectedProject", selectedProject);
  const { id: projectId } = useParams();
  const dispatch = useDispatch();
  useMemo(() => {
    if (file !== null) {
      const parts = file[0]?.name?.split(".");
      setExtension(parts[parts?.length - 1]);
      setName(parts[parts?.length - 2]);
    }
  }, [file]);
  const onSubmit = () => {
    const form_data = { file: imageBase64 };
    if (selectedProject) {
      dispatch(
        projectFileUpload({
          id: selectedProject,
          name,
          file: form_data,
          extension,
        })
      );
      dispatch(getProjectWithAssets());

      setOpen(false);
    } else if (!fromStoragePage) {
      dispatch(
        projectFileUpload({ id: projectId, name, file: form_data, extension })
      );
      setOpen(false);
    } else {
      showMessage("info", "Please select a project");
    }
  };

  const handleSelectProject = (value) => {
    setSelectedProject(value);
  };

  return (
    <SideModel
      isOpen={open}
      handleReset={() => setOpen(false)}
      width={631}
      theme={{ sideModel: style.sideModel }}
    >
      <div className={style.storageBar}>
        <h1 className={style.storageHading}>Upload File</h1>

        <div className={style.storageSideModel}>
          {fromStoragePage && (
            <CustomDropdownWithoutForm
              label="Select Project"
              isMultiple={false}
              onChange={handleSelectProject}
              options={options}
              value={selectedProject}
              placeholder="Select a project"
            />
          )}
          {/* flex flex-col justify-start w-full gap-3 p-5 */}
          {/* <div className={style.storageBox}>

          {TABS?.map((item) => (
            <p
              key={item?.id}
              className={`${
                activeTab === item?.id && "font-bold"
              } cursor-pointer`}
              onClick={() => setActiveTab(item?.id)}
            >
              {item?.title}
            </p>
          ))}
        </div> */}
          <div className={style.stoageFileUplod}>
            {/* {activeTab === 1 ? (
            <div
              onClick={() => setSelectedOption(!selectOptions)}
              className={`py-4 flex flex-col items-center justify-center gap-5 h-full cursor-pointer  `}
            >
              <div className="flex items-end gap-3">
                <img
                  src="/assets/images/IconGoogleDrive.svg"
                  alt="uploadImage"
                />
                or
                <img src="/assets/images/IconOneDrive.svg" alt="uploadImage" />
              </div>
              <p className="font-medium text-xs text-white text-center underline">
                Select files from Google or One Drive
              </p>
              <button className="relative customTransition shadow-md px-6 py-2 flex justify-center items-center  bg-white/[0.8] ">
                <span className="text-[12px] font-semibold text-[#000000]">
                  <GoogleDrivePicker setData={setDriveData} />
                </span>
                {selectOptions && (
                  <div className="absolute top-8 bg-white py-2 px-4 rounded-md whitespace-nowrap flex flex-col gap-2">
                    <GoogleDrivePicker setData={setDriveData} />
                  </div>
                )}
              </button>
            </div>
          ) : (
            <ReactDropzone
              onDrop={(acceptedFile) => {
                setFile(acceptedFile);
                handleImageDrop(acceptedFile, setImageBase64);
              }}
            >
              <div>
                <img
                  src="/assets/images/fileDropImage.svg"
                  alt="file drop here"
                />
                <p className={style.fileName}>{file && file[0]?.name}</p>
              </div>
            </ReactDropzone>
          )} */}

            <ReactDropzone
              onDrop={(acceptedFile) => {
                setFile(acceptedFile);
                handleImageDrop(acceptedFile, setImageBase64);
              }}
            >
              <div>
                <img
                  src="/assets/images/fileDropImage.svg"
                  alt="file drop here"
                />
                <p className={style.fileName}>{file && file[0]?.name}</p>
              </div>
            </ReactDropzone>
          </div>
          <div className={style.btnMain}>
            <Button1
              title={`Upload`}
              onClick={onSubmit}
              btnType="button"
              theme={{ btn: style.FileUpload }}
              disabled={imageBase64 !== null ? false : true}
            />
            <Button1
              title={"Cancel"}
              btnType="button"
              onClick={() => setOpen(false)}
              theme={{ btn: style.FileUploadCancel }}
            />
          </div>
        </div>
      </div>
    </SideModel>
  );
};

export default StorageModal;
