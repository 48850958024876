import React from "react";

const Input = ({
  inputType = "text",
  type = "input",
  placeholder,
  Icon,
  defaultValue,
  value,
  register,
  width,
  onChange,
  disabled,
  minValue,
  maxValue,
}) => {
  return (
    <div
      className={`${
        width ? width : " w-full "
      }  h-[44px] py-2 px-3 bg-white/[0.6] hover:bg-white/[0.8] customTransition shadow-md rounded-full flex items-center justify-between`}
    >
      <div className="flex items-center gap-3 w-full">
        <img
          src={type === "search" ? "/assets/images/IconSearch.svg" : Icon}
          alt="IconSearch"
          className=" "
        />
        <input
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          disabled={disabled}
          type={inputType}
          min={minValue}
          max={maxValue}
          placeholder={placeholder}
          className="w-full bg-transparent border-none focus:outline-none text-primary font-semibold placeholder:text-secondary placeholder:font-semibold"
          {...register}
        />
      </div>
      {type === "search" && (
        <div className="flex items-center gap-4">
          {/* <div className="h-[25px] w-[1px] bg-secondary rounded-full" /> */}
          <img
            src="/assets/images/IconFilter.svg"
            alt="IconSearch"
            className="cursor-pointer"
          />
        </div>
      )}
    </div>
  );
};

export default Input;
