import style from "./userActions.module.scss";

const UserActions = ({ actions, data }) => {
  return (
    <div className={style.actionMain}>
      {actions?.map((item) => (
        <div className={style.action} onClick={() => item.onClick(data)}>
          <img src={`/assets/images/${item.iconName}.svg`} alt="" />
          <span className={style.text}>{item.name}</span>
        </div>
      ))}
    </div>
  );
};

export default UserActions;
