import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import style from "./dropDown.module.scss";

const Dropdown = ({
  options,
  label,
  control,
  name,
  placeholder,
  isMultiple,
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #bfbfbf",
      boxShadow: "none",
      padding: "12px",
      borderRadius: "0px",
      "&:hover": {
        borderColor: "#aaa",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#ffffff",
      backgroundColor: "black",
      padding: "8px",
      border: "none",
      "&:hover": {
        color: "#ffffff",
      },
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "5px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f0f0f0" : "#fff",
      color: "#333",
      padding: "10px",
      fontSize: "13px",
      cursor: "pointer",
      "&:active": {
        backgroundColor: "#e0e0e0",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#999",
      fontSize: "16px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#333",
      fontSize: "16px",
    }),
  };

  return (
    <>
      <p className={style.labelText}>{label}</p>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            {...(isMultiple ? { isMulti: true } : {})}
            styles={customStyles}
            options={options}
            placeholder={placeholder}
            {...(isMultiple
              ? { value: field.value }
              : {
                  value: options.find((option) => option.value === field.value),
                })}
            {...(isMultiple
              ? { onChange: field.onChange }
              : {
                  onChange: (selectedOption) => {
                    field.onChange(selectedOption.value);
                  },
                })}
          />
        )}
      />
    </>
  );
};

export default Dropdown;

{
  /* <div>
            <Controller
              name="assignProject"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  placeholder="Select Project..."
                  handleSelect={field.onChange}
                  value={field.value}
                  options={options}
                />
              )}
            />
          </div> */
}
