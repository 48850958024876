import { useState, useEffect } from "react";
export default function useDebounce(value, delay, returnPreviousValue = false) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [previousValue, setPreviousValue] = useState("");
  useEffect(() => {
    const handler = setTimeout(() => {
      setPreviousValue(debouncedValue);
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  if (returnPreviousValue) {
    return [debouncedValue, previousValue];
  }
  return debouncedValue;
}
