/**@deprecated */

import { yupResolver } from "@hookform/resolvers/yup";
import { message, Spin } from "antd";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomer } from "../../store/customers/customerActions";
import { handleImageDrop } from "../../Utils/functions";

import { addUserSchema } from "../../Utils/Validations";
import {
  Button,
  Input,
  ModalContent,
  ReactDropzone,
  SingleSelect,
  TextArea,
} from "../macros";
import { showMessage } from "../../Utils/pushNotification";

const CustomerEditModal = ({ open, setOpen, data }) => {
  const { loading } = useSelector((state) => state.customer);
  const { role: loggedInRole } = useSelector((state) => state.user);
  const [changeImage, setChangeImage] = useState(false);
  const [imageBase64, setImageBase64] = useState(null);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: data?.name,
      email: data?.email,
      phone: data?.phone,
      description: data?.description,
      address: data?.address,
      accessType: data?.accessType,
    },
    resolver: yupResolver(addUserSchema),
  });

  const onSubmit = async (submitData) => {
    submitData.photo = imageBase64;
    if (loggedInRole === "ADMIN") {
      delete submitData.accessType;
    }
    dispatch(updateCustomer({ id: data._id, data: submitData }))
      .unwrap()
      .then((res) => (showMessage("success", res?.message), setOpen(false)))
      .catch((err) =>
        showMessage("error", "Could not update Customer profile")
      );
  };
  return (
    <ModalContent isOpen={open} setClose={() => setOpen(false)} width={631}>
      <div className="flex flex-col gap-2 w-full items-center px-5 ">
        <h3 className="heading3 text-primary">Edit Customer</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-y-3 gap-x-7 w-full pb-3 overflow-y-auto max-h-[60vh]">
            <div className="space-y-1">
              <span className="heading4 ml-3">Name*</span>
              <Input
                register={register("name")}
                Icon="/assets/images/IconText.svg"
                placeholder="Enter Name..."
              />
              <p className="errorStyle">{errors?.name?.message}</p>
            </div>
            <div className="mt-8 row-span-2 bg-[#B3B8BD]/[0.5] shadow-md rounded-2xl flex justify-center items-center h-[137px] max-h-[137px]">
              {!changeImage && data?.photo && (
                <img
                  src={data?.photo}
                  alt="imageUser"
                  className="w-full h-full object-cover rounded-2xl"
                />
              )}
              {!data?.photo || data?.photo === "" || changeImage ? (
                <ReactDropzone
                  onDrop={(acceptedFile) => (
                    setFile(acceptedFile),
                    handleImageDrop(acceptedFile, setImageBase64)
                  )}
                >
                  <div
                    className={`py-4 flex flex-col items-center justify-center gap-3`}
                  >
                    <img
                      src="/assets/images/IconUpload.svg"
                      alt="uploadImage"
                    />
                    <p className="font-medium text-xs text-primary text-center underline">
                      Select or drag and drop files here to upload
                    </p>
                    <p className="text-[8px]">{file && file[0]?.name}</p>
                  </div>
                </ReactDropzone>
              ) : (
                ""
              )}
            </div>
            <div className="space-y-1">
              <span className="heading4 ml-3">Email</span>
              <Input
                register={register("email")}
                Icon="/assets/images/IconUser.svg"
                placeholder="Enter Email..."
              />
              <p className="errorStyle">{errors?.email?.message}</p>
            </div>
            <div className="space-y-1">
              <span className="heading4 ml-3">Phone</span>
              <Input
                register={register("phone")}
                Icon="/assets/images/IconPhone.svg"
                placeholder="Phone"
              />
              <p className="errorStyle">{errors?.phone?.message}</p>
            </div>
            <div className="flex flex-col gap-1">
              <span className="heading4 ml-3">Display Photo</span>
              <div className="flex items-center gap-3">
                <Button
                  title={changeImage ? "Preview" : "Change"}
                  width={"w-full"}
                  disabled={!data?.photo ? true : false}
                  onClick={() => {
                    setChangeImage(!changeImage);
                  }}
                  titleColor="text-primary"
                  Icon="/assets/images/IconRecycle.svg"
                />
                <Button
                  title={"Remove"}
                  width={"w-full"}
                  onClick={() => {}}
                  titleColor="text-primary"
                  Icon="/assets/images/IconCrossBlue.svg"
                />
              </div>
            </div>
            <div className="space-y-1">
              <span className="heading4 ml-3">Address</span>
              <TextArea
                register={register("address")}
                Icon="/assets/images/IconAddress.svg"
                placeholder="Enter Address..."
              />
              <p className="errorStyle">{errors?.address?.message}</p>
            </div>
            <div className="space-y-1">
              <span className="heading4 ml-3">Description</span>
              <TextArea
                register={register("description")}
                Icon="/assets/images/IconDescription.svg"
                placeholder="Enter Description..."
              />
              <p className="errorStyle">{errors?.description?.message}</p>
            </div>
            {loggedInRole === "CUSTOMER" && (
              <div className="space-y-1">
                <span className="heading4 ml-3">Access Type*</span>
                <Controller
                  name="accessType"
                  control={control}
                  render={({ field }) => (
                    <SingleSelect
                      mode="single"
                      options={[
                        { value: "View", label: "View" },
                        { value: "Edit", label: "Edit" },
                        { value: "Admin", label: "Admin" },
                      ]}
                      handleSelect={field.onChange}
                      value={field.value}
                      placeholder="Access Type"
                    />
                  )}
                />
                <p className="errorStyle">{errors?.accessType?.message}</p>
              </div>
            )}
          </div>

          <div className="flex items-center gap-6 whitespace-nowrap mt-7 w-[70%] mx-auto">
            <Button
              loading={loading}
              title={"Confirm"}
              btnType="submit"
              className="!px-7"
              width={"w-full"}
              titleColor="text-primary"
              Icon="/assets/images/IconCheckBlue.svg"
            />
            <Button
              loading={loading}
              title={"Cancel"}
              width={"w-full"}
              className="!px-7"
              onClick={() => setOpen(false)}
              titleColor="text-primary"
              Icon="/assets/images/IconCrossBlue.svg"
            />
          </div>
        </form>
      </div>
    </ModalContent>
  );
};

export default CustomerEditModal;
