import { message } from "antd";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button1, Input, Input2 } from "../../../component/macros";
import { getProjectFiles } from "../../../store/projects/projectActions";
import { showMessage } from "../../../Utils/pushNotification";
import SideModel from "../../../component/macros/sideModel";
import style from "./share.module.scss";

const Share = ({ open, setOpen }) => {
  const { projectFiles } = useSelector((state) => state.project);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const { id: projectId } = useParams();
  useMemo(() => {
    dispatch(getProjectFiles(projectId));
  }, []);

  const handleOnClick = (item) => {
    if (window.navigator && window.navigator.clipboard) {
      window.navigator.clipboard
        .writeText(item?.file)
        .then(() => {
          showMessage("success", "Copied");
        })
        .catch(() => {
          showMessage("error", "Failed to copy");
        });
    } else {
      showMessage("error", "Clipboard not accessible");
    }
  };

  return (
    <SideModel
      isOpen={open}
      handleReset={setOpen}
      theme={{ sideModel: style.sideModel }}
    >
      {/* <div className="my-5 pb-2 flex flex-col gap-5 h-full self-start overflow-y-auto">
        <h3 className="text-[22px] text-primary font-medium self-center">
          File Sharing
        </h3>
        <Input
          type="search"
          placeholder="Search File..."
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {projectFiles.length > 0 ? (
          ""
        ) : (
          <p className="italic text-secondary text-lg ">
            No file found, upload files in order to share...
          </p>
        )}
        <div className="w-full min-h-[1px] h-[1px] bg-secondary my-5" />
        {projectFiles.length > 0 ? (
          <div>
            <h3 className="text-[22px] text-primary font-medium text-center">
              Files
            </h3>

            <p className="italic text-secondary text-base text-center ">
              Click a file to copy the link
            </p>
            {projectFiles
              ?.filter((item) =>
                item?.name?.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((item) => (
                <div
                  onClick={() =>
                    navigator.clipboard.writeText(item?.file).then(() => {
                      showMessage("success", "Copied");
                    })
                  }
                  key={item?._id}
                  className="flex mt-1 hover:bg-[#D9D9D9] py-2 hover:shadow-md px-3 rounded-full  items-center justify-between "
                >
                  <span className="text-lg text-primary truncate">
                    {item?.name}
                  </span>
                  <span className="text-lg text-primary truncate">❒</span>
                </div>
              ))}
          </div>
        ) : (
          ""
        )}

        <Button1
          type="button"
          title="Cancel"
          onClick={setOpen}
          theme={{ btn: style.cancelUpload }}
        />
      </div> */}

      <div className={style.fileShare}>
        <div className={style.fileSharingHeading}> File Sharing</div>

        <Input2
          placeholder="Search"
          type="search"
          icon="/assets/images/searchIcon.svg"
          name="search"
          onChange={(e) => setSearchQuery(e.target.value)}
          theme={{ input: style.input }}
        />
        {projectFiles.length > 0 ? (
          ""
        ) : (
          <p className="text-white text-lg ">
            No file found please upload to see the search results...
          </p>
        )}

        {projectFiles.length > 0 ? (
          <div>
            <h3 className={style.fileHeading}>Files</h3>
            {/* text-[22px] text-primary font-medium text-center */}

            <p className={style.listHeading}>Click a file to copy the link</p>
            {/* italic text-secondary text-base text-center */}
            {projectFiles
              ?.filter((item) =>
                item?.name?.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((item) => (
                <div
                  onClick={() => handleOnClick(item)}
                  key={item?._id}
                  className={style.mainListTag}
                >
                  {/* flex mt-1 hover:bg-[#D9D9D9] py-2 hover:shadow-md px-3 rounded-full  items-center justify-between  */}
                  <span className={style.storageListItem}>
                    {item?.name}
                    {/* text-lg text-primary truncate */}
                  </span>
                  {/* <span className={style.storageListIcon}>❒</span> */}
                  {/* text-lg text-primary truncate */}
                </div>
              ))}
          </div>
        ) : (
          ""
        )}

        <Button1
          type="button"
          title="Cancel"
          onClick={setOpen}
          theme={{ btn: style.cancelUpload }}
        />
      </div>
    </SideModel>
  );
};

export default Share;
