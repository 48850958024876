import { Spin } from "antd";
import React from "react";

const Button = ({
  title,
  onClick,
  width,
  loading,
  titleColor,
  Icon,
  noIcon,
  disabled,
  className,
  btnType = "button",
  IconPlacement = "right",
}) => {
  return (
    <button
      type={btnType}
      disabled={loading}
      onClick={onClick}
      className={`${width} ${
        disabled && "pointer-events-none !bg-gray-300"
      } bg-white/[0.5] ${
        titleColor ? titleColor : " text-secondary "
      } hover:bg-white/[0.8] customTransition h-[44px] shadow-md ${className} px-4 py-2 rounded-[20px] flex ${
        IconPlacement === "left" ? "flex-row-reverse" : "flex-row"
      } items-center justify-center gap-2 text-lg font-semibold whitespace-nowrap cursor-pointer`}
    >
      <span className={`text-[18px]`}>{title}</span>
      {loading ? (
        <Spin />
      ) : (
        !noIcon && (
          <div className="w-[16px] flex items-center justify-center">
            <img
              src={Icon ? Icon : "/assets/images/IconPlus.svg"}
              alt="Icon"
              className="auto "
            />
          </div>
        )
      )}
    </button>
  );
};

export default Button;
