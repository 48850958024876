import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../@api/axios";

export const addProject = createAsyncThunk(
  "project/addProject",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/project`, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const uploadBimModel = createAsyncThunk(
  "project/uploadBimModel",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/bim/upload`, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBimModelAccessToken = createAsyncThunk(
  "project/getBimModelAccessToken",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/bim/getToken`);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateProject = createAsyncThunk(
  "project/updateProject",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(`/project/` + id, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProjects = createAsyncThunk(
  "project/getProjects",
  async (data, { rejectWithValue }) => {
    try {
      const { searchValue, type, sortType } = data;
      const res = await axiosInstance.get(
        `/projects?searchText=` +
          searchValue +
          `&type=` +
          type +
          `&sort=` +
          sortType
      );
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProjectWithAssets = createAsyncThunk(
  "project/getProjectWithAssets",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/projectsWithAssets`);

      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSingleProject = createAsyncThunk(
  "project/getSingleProject",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/project/` + id);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteProject = createAsyncThunk(
  "project/deleteProject",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.delete(`/project/` + id);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const deleteProjectFile = createAsyncThunk(
  "project/deleteProjectFile",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.delete(`/project/file/` + id);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const projectFileUpload = createAsyncThunk(
  "project/projectFileUpload",
  async ({ id, name, file, extension }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/project/Upload/` + id, {
        name,
        file,
        extension,
      });
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProjectFiles = createAsyncThunk(
  "project/files",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/project/files/` + id);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
