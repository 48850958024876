import { useMemo } from "react";
import styles from "./sortBy.module.scss";
import { Popover } from "antd";

const SortBy = ({ items = [], onChange }) => {
  const sortByOptions = useMemo(() => {
    return (
      <div className={styles.sortByPopover}>
        {items?.map((item) => (
          <label className="flex justify-start items-center gap-2">
            <input
              name="sortByOptions"
              type="radio"
              value={item.value}
              onChange={onChange}
              {...(item?.isSelected ? { checked: true } : {})}
            />
            {item.label}
          </label>
        ))}
      </div>
    );
  }, [items]);

  return (
    <div className="sort">
      <Popover content={sortByOptions} trigger="click" placement="bottom">
        <div className={styles.SortFilter}>
          <img src="/assets/images/sortFilter.svg" alt="" />
          Sort
        </div>
      </Popover>
    </div>
  );
};

export default SortBy;
