import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
  Button1,
  FormInput,
  SideModel,
  ReactDropzone,
  RadioButtonGroup,
  DropBox,
} from "../macros/index";

import { projectSchema } from "../../Utils/Validations";
import { addProject, updateProject } from "../../store/projects/projectActions";
import { message, Popover, Spin } from "antd";
import { handleImageDrop } from "../../Utils/functions";
import { getProjectCustomers } from "../../store/subscription/subscriptionActions";
import style from "./addUpdateProjectModal.jsx.module.scss";
import {
  setProjectModel,
  setSelectedItem,
} from "../../store/projects/projectSlice";
import Dropdown from "../macros/Dropdown";
import { showMessage } from "../../Utils/pushNotification";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import AddBIMModel from "./AddBIMModel";

const statusOptions = [
  { label: "In-Progress", value: "In-Progress", isDefault: true },
  { label: "Completed", value: "Completed" },
];

const AddUpdateProjectModel = ({ isOpen, setOpen, selectedData }) => {
  const type = selectedData ? "Edit" : "Add";
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      title: selectedData?.title,
      // clickEmbedUrl: selectedData?.clickEmbedUrl,
      googleMapEmbed: selectedData?.googleMapEmbed,
      youtubeVideoEmbed: selectedData?.youtubeVideoEmbed,
      matterPortUrl: selectedData?.matterPortUrl
        ? selectedData?.matterPortUrl
        : "Qojjx8x9Fu4",
      geomausMarketingModel: selectedData?.geomausMarketingModel
        ? selectedData?.geomausMarketingModel
        : "https://my.matterport.com/show/?m=Qojjx8x9Fu4&play=1",
      description: selectedData?.description,
      status:
        selectedData?.status || statusOptions?.find((i) => i.isDefault).value,
      customerId: selectedData?.customerId?._id,
      geomausBimModel: selectedData?.geomausBimModel,
      bimModelRegisterDate: selectedData?.bimModelRegisterDate,
    },
    resolver: yupResolver(projectSchema),
  });
  const clearForm = () => {
    reset();
  };

  useEffect(() => {
    if (selectedData) {
      // Set form values from selectedData
      setValue("title", selectedData.title || "");
      // setValue("clickEmbedUrl", selectedData.clickEmbedUrl || "");
      setValue("googleMapEmbed", selectedData.googleMapEmbed || "");
      setValue("youtubeVideoEmbed", selectedData.youtubeVideoEmbed || "");
      setValue("matterPortUrl", selectedData.matterPortUrl || "Qojjx8x9Fu4");
      setValue(
        "geomausMarketingModel",
        selectedData.geomausMarketingModel ||
          "https://my.matterport.com/show/?m=Qojjx8x9Fu4&play=1"
      );
      setValue("description", selectedData.description || "");
      setValue("status", selectedData.status);
      setValue("customerId", selectedData.customerId?._id);
      setValue("geomausBimModel", selectedData?.geomausBimModel);
      setValue("bimModelRegisterDate", selectedData?.bimModelRegisterDate);
    }
  }, [selectedData, setValue]);

  const { customers } = useSelector((state) => state.customer);
  const { loading } = useSelector((state) => state.project);
  const { role } = useSelector((state) => state.user);
  const [changeImage, setChangeImage] = useState(false);
  const [file, setFile] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [imageBase64, setImageBase64] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjectCustomers())
      .unwrap()
      .then((res) =>
        setCustomerOptions(
          res.users?.map(({ _id, name }) => ({ value: _id, label: name }))
        )
      );
  }, []);

  const onSubmit = async (data) => {
    if (imageBase64) {
      data.coverPhoto = imageBase64;
      data.logoPhoto = imageBase64;
    }
    localStorage.getItem("customer_access_token") && (data.customerId = "self");
    if (type === "Add") {
      dispatch(addProject(data))
        .unwrap()
        .then(
          (res) => (
            showMessage("success", res?.message),
            dispatch(setProjectModel(false))
          )
        )
        .catch(
          (err) => (
            showMessage("error", "Error Creating Project"),
            dispatch(setProjectModel(false))
          )
        );
    } else if (type === "Edit") {
      dispatch(updateProject({ id: selectedData?._id, data }))
        .unwrap()
        .then(
          (res) => (
            showMessage("success", res?.message),
            dispatch(setProjectModel(false))
          )
        )
        .catch(
          (err) => (
            showMessage("error", "Error Updating Project"),
            dispatch(setProjectModel(false))
          )
        );
    }

    clearForm();
  };
  useEffect(() => {
    dispatch(getProjectCustomers())
      .unwrap()
      .then((res) =>
        setCustomerOptions(
          res.users?.map(({ _id, name }) => ({ value: _id, label: name }))
        )
      );
  }, []);

  const handleReset = () => {
    dispatch(setProjectModel());
    dispatch(setSelectedItem(undefined));

    reset();
  };
  const bimModelTips = (
    <div className={style.tipSection}>
      {/* <h2>BIM model</h2> */}

      <h5 className={style.shortText}>
        You can upload BIM Model only after project created. So you need to come
        back here to edit project and upload BIM Model file.
      </h5>

      {/* <img
        src="/assets/images/Autodesk-Viewer-Free-Online-File-Viewer.png"
        alt=""
      />

      <strong>NOTE: Need to extend the BIM model every 30 days</strong> */}
    </div>
  );
  return (
    <div className="addNewProjectWrapper">
      {}

      <SideModel isOpen={isOpen} width={"xl"} handleReset={handleReset}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={style.project}>
            <div className={style.projectForm}>
              <h2>{type == "Add" ? "Add Project" : "Edit Project"}</h2>
              <p className={style.uploadText}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                <br /> sed do eiusmod tempor incididunt ut labore et .
              </p>

              <ReactDropzone
                onDrop={(acceptedFile) => (
                  setFile(acceptedFile),
                  handleImageDrop(acceptedFile, setImageBase64)
                )}
              >
                <DropBox text="Upload" fileName={file && file[0]?.name} />
              </ReactDropzone>

              <FormInput
                inputType="text"
                placeholder="Add the property name"
                label="Property Name"
                register={register("title")}
                name="title"
                errors={errors}
              />
              <FormInput
                inputType="text"
                placeholder="Add the Matterport ID"
                label="Matterport ID"
                register={register("matterPortUrl")}
                name="matterPortUrl"
                errors={errors}
              />
              <FormInput
                inputType="text"
                placeholder="Add Matterport Link"
                label="Geomaus Marketing Model"
                register={register("geomausMarketingModel")}
                name="geomausMarketingModel"
                errors={errors}
              />

              {/* <div className={style.bimModel}>
                <FormInput
                  inputType="text"
                  placeholder="Add BIM model"
                  label="BIM Model URL"
                  register={register("geomausBimModel")}
                  name="geomausBimModel"
                  errors={errors}
                />
                <div className={style.icon}>
                  <Popover content={bimModelTips} trigger="click">
                    <QuestionCircleTwoTone style={{ fontSize: "22px" }} />
                  </Popover>
                </div>
              </div>
              {type === "Add" && (
                <FormInput
                  label="BIM Model Register Date"
                  inputType="date"
                  errors={errors}
                  register={register("bimModelRegisterDate")}
                  name="bimModelRegisterDate"
                />
              )} */}
              {type === "Edit" ? (
                <AddBIMModel projectId={selectedData._id} />
              ) : (
                <Popover
                  content={bimModelTips}
                  trigger="click"
                  placement="right"
                >
                  <Button1
                    title="Add BIM model"
                    theme={{ btn: style.addBimButton }}
                    type="button"
                  />
                </Popover>
              )}

              <Dropdown
                options={customerOptions}
                label="Client Name"
                control={control}
                name="customerId"
                placeholder="Select Customer"
              />

              <RadioButtonGroup
                items={statusOptions}
                name="status"
                isRequired
                setValue={setValue}
                watch={watch}
              />
              <FormInput
                inputType="textArea"
                placeholder="Add a brief description to this building to make it easier to recognise"
                label="Description"
                register={register("description")}
                name="description"
                errors={errors}
              />
              {/*<FormInput
                inputType="text"
                placeholder="Clickup Embed Link..."
                label="Clickup Embed Link"
                register={register("clickEmbedUrl")}
                name="clickEmbedUrl"
                errors={errors}
            />*/}

              <FormInput
                inputType="text"
                placeholder="Property Google Map..."
                label="Property Google Map"
                register={register("googleMapEmbed")}
                name="googleMapEmbed"
                errors={errors}
              />

              <FormInput
                inputType="text"
                placeholder="Youtube Embed Link.."
                label="Youtube Embed Video"
                register={register("youtubeVideoEmbed")}
                name="youtubeVideoEmbed"
                errors={errors}
              />
            </div>
            <div className={style.projectBtn}>
              <Button1
                theme={{ btn: style.addUpload }}
                title={type}
                type="submit"
              />

              <Button1
                theme={{ btn: style.cancleUpload }}
                type="button"
                title="Cancel"
                onClick={() => {
                  dispatch(setProjectModel(false));
                  dispatch(setSelectedItem(undefined));
                  reset();
                }}
              />
            </div>
          </div>
        </form>
      </SideModel>
    </div>
  );
};

export default AddUpdateProjectModel;
