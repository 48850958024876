import { useDispatch, useSelector } from "react-redux";
import ProjectStorageCard from "../component/macros/projectStorageCard/ProjectStorageCard";
import style from "./storage.module.scss";
import { useEffect, useState } from "react";
import {
  Button1,
  HorizontalScrollbar,
  TableComponent,
} from "../component/macros";
import { getProjectWithAssets } from "../store/projects/projectActions";
import { timestamptoDate1 } from "../Utils/functions";
import { useNavigate } from "react-router-dom";
import { Popover } from "antd";
import { Storage } from "./Matterport/Components";
import StorageModal from "../component/modals/StorageModal";

const StoragePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const role = useSelector((state) => state.user.role).toLowerCase();

  const { projectsWithAssets, loading, isOpenModel } = useSelector(
    (state) => state.project
  );
  const [chosenProject, setChosenProject] = useState({});
  const [isOpenStorageModel, setIsOpenStorageModel] = useState(false);

  useEffect(() => {
    dispatch(getProjectWithAssets());
  }, []);

  const formatSize = (size) => {
    if (!size || isNaN(size)) {
      return "0 KB";
    }
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return (
      Math.round(size / Math.pow(1024, i)) +
      " " +
      ["B", "KB", "MB", "GB", "TB"][i]
    );
  };

  const formatFileSizeForStorageSpace = (size) => {
    if (!size || isNaN(size)) {
      return "0 KB";
    }
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return (
      (size / Math.pow(1024, i)).toFixed(2) * 1 +
      " " +
      ["B", "KB", "MB", "GB", "TB"][i]
    );
  };
  const colors = ["#007bff", "#ffc107", "#28a745", "#dc3545", "#000000"];

  function shuffleArray(array) {
    // Fisher-Yates shuffle algorithm
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  // Shuffle the colors array to ensure colors are not repeated in order
  const shuffledColors = shuffleArray([...colors]);

  const projectStorageCardData = projectsWithAssets?.map((projectData) => {
    const { project, assets, projectsFiles } = projectData;

    // Calculate total files count from assets
    const totalFilesCount = assets?.reduce(
      (acc, asset) => acc + asset.files.length,
      0
    );

    const filesCount = projectsFiles?.length + totalFilesCount;

    // Calculate total size from assets
    const totalSizeFromAssets = assets?.reduce((acc, asset) => {
      const assetSize = asset?.files?.reduce(
        (assetAcc, file) => assetAcc + file.size,
        0
      );
      return acc + assetSize;
    }, 0);

    // Calculate total size from project files
    const totalSizeFromProjectFiles = projectsFiles?.reduce(
      (acc, projectFile) => acc + projectFile.size,
      0
    );

    // Calculate total project size
    const totalProjectSize = totalSizeFromAssets + totalSizeFromProjectFiles;

    return {
      projectId: project._id,
      logo: project.logoPhoto || "", // Ensures logo is an empty string if undefined
      title: project.title,
      filesCount: filesCount,
      size: formatSize(totalProjectSize),
      createdAt: project.createdAt,
      project: project,
      assets: assets,
    };
  });

  const sortedProjects = projectStorageCardData?.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  const options = sortedProjects?.map((project) => {
    return { value: project.projectId, label: project.title };
  });

  console.log("options", options);

  useEffect(() => {
    if (sortedProjects && sortedProjects?.length > 0) {
      setChosenProject(sortedProjects[0]);
    }
  }, [projectsWithAssets]);

  const filesData = [];

  projectsWithAssets?.forEach((projectData) => {
    const { assets, project, projectsFiles } = projectData;

    if (project._id === chosenProject.projectId) {
      // Add files from assets
      assets?.forEach((asset) => {
        asset.files.forEach((file) => {
          filesData.push({
            name: file.name,
            size: file.size,
            assetName: asset.title || project.title, // Use project title if asset title is not found
            updatedAt: file.updatedAt,
            fileUrl: file.file,
          });
        });
      });

      // Add files from projectsFiles
      projectsFiles?.forEach((projectFile) => {
        filesData.push({
          name: projectFile.name,
          size: projectFile.size,
          assetName: project.title, // Use project title for project files
          updatedAt: projectFile.updatedAt,
          fileUrl: projectFile.file,
        });
      });
    }
  });

  const totalSizeInMB = filesData.reduce((acc, file) => (acc += file.size), 0);

  const maxSize = filesData
    ? Math.max(...filesData?.map((file) => file.size))
    : 0;

  const fileDownloadContent = () => {
    return <span>Download</span>;
  };

  const handleFileDownload = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  const groupedData = filesData?.reduce((acc, item) => {
    if (!acc[item.assetName]) {
      acc[item.assetName] = { ...item, size: 0, updatedAt: item.updatedAt };
    }
    acc[item.assetName].size += item.size;
    if (new Date(item.updatedAt) > new Date(acc[item.assetName].updatedAt)) {
      acc[item.assetName].updatedAt = item.updatedAt;
    }
    return acc;
  }, {});

  const storagePageData = Object.values(groupedData);

  return (
    <div className={style.main}>
      <HorizontalScrollbar>
        {sortedProjects?.length > 0 &&
          sortedProjects?.map((project, index) => (
            // <div className={style.projectFullCard}>
            <ProjectStorageCard
              key={index}
              projectData={project}
              setChosenProject={setChosenProject}
              chosenProject={chosenProject}
              {...(project.projectId == chosenProject.projectId
                ? { isSelected: true }
                : {})}
            />
            // </div>
          ))}

        <div
          className={style.addStorageFile}
          onClick={() => setIsOpenStorageModel(true)}
        >
          <Button1
            theme={{ btn: style.addFile }}
            type="button"
            title="Add File"
          />
          <p className={style.addFileText}>
            Lorem ipsum dolor sit amet, <br /> consectetur adipiscing elit, sed
            do .
          </p>
        </div>
      </HorizontalScrollbar>
      {/* All Files and Storage Space */}
      <div className={style.storageInfo}>
        {/* All selected Project Files */}

        <div className={style.storageFileList}>
          <div className={style.tableContainer}>
            <h1 className={style.tableTitle}>All Files</h1>
            <table className={style.fileTable}>
              <thead className={style.tableHeader}>
                <tr className={style.headerRow}>
                  <th className={style.headerCell}>Name</th>
                  <th className={style.headerCell}>Size</th>
                  <th className={style.headerCell}>Tag</th>
                  <th className={`${style.headerCell}  ${style.actionCell}`}>
                    Time modified
                  </th>
                  <th className={style.headerCell}></th>
                </tr>
              </thead>
              <tbody className={style.tableBody}>
                {filesData?.length > 0 &&
                  filesData
                    .sort(
                      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
                    )
                    .map((file, index) => {
                      return (
                        <tr className={style.bodyRow}>
                          <td className={style.bodyCell}>
                            <div className={style.fileNameCell}>
                              <img
                                src="/assets/images/storageFile.svg"
                                alt=""
                                className={style.iconFile}
                              />
                              {file.name}
                            </div>
                          </td>
                          <td className={style.bodyCell}>
                            <div className={style.sizeCell}>
                              {formatSize(file.size)}
                            </div>
                          </td>
                          <td className={style.bodyCell}>
                            <div className={style.tagCell}>
                              <span className={style.tagCallDot}>•</span>{" "}
                              {file.assetName}
                            </div>
                          </td>
                          <td className={style.bodyCell}>
                            <div className={style.actionCell}>
                              {timestamptoDate1(file.updatedAt)}
                            </div>
                          </td>
                          <td className={style.bodyCell}>
                            <Popover
                              align="right"
                              content={fileDownloadContent}
                            >
                              <div
                                className={style.cell}
                                onClick={() => handleFileDownload(file.fileUrl)}
                              >
                                {/* <div className={style.moreData}>
                                <span className={style.dotte}></span>
                                <span className={style.dotte}></span>
                                <span className={style.dotte}></span>
                              </div> */}
                                <img
                                  src="/assets/images/Download.svg"
                                  alt=""
                                  className={style.downloadBtn}
                                />
                              </div>
                            </Popover>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>

        {/* File Storage Space */}
        <div className={style.storageSpace}>
          <div className={style.storageSpaceHeading}>
            <h2 className={style.spaceHeading}>Storage Space</h2>

            <div className={style.upgradeMore}>
              <div className={style.spaceMb}>
                {formatSize(totalSizeInMB)}
                {/* / */}
                {/* <span className={style.spaceMbbold}>20Mb</span> */}
              </div>
              <Button1
                theme={{ btn: style.upgradeSpaceBtn }}
                type="button"
                title="Upgrade For More Spaces"
                icon="/assets/images/arrowRight.svg"
                onClick={() => navigate(`/support`)}
              />
            </div>
          </div>

          <div className={style.storageFileSpace}>
            {storagePageData?.length > 0 &&
              storagePageData
                .sort((a, b) => b.size - a.size)
                .map((file, index) => {
                  const widthPercentage = (file.size / maxSize) * 100;

                  const backgroundColor =
                    shuffledColors[index % shuffledColors.length];

                  return (
                    <div
                      className={style.storageFile}
                      key={index}
                      style={{
                        width: `${widthPercentage}%`,
                        backgroundColor,
                        maxWidth: "100%",
                      }}
                    >
                      <h4 className={style.fileName}>{file.assetName}</h4>
                      <div className={style.fileSpace}>
                        {formatFileSizeForStorageSpace(file.size)}
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>

      {isOpenStorageModel && (
        <StorageModal
          open={isOpenStorageModel}
          setOpen={() => setIsOpenStorageModel(false)}
          fromStoragePage
          options={options}
        />
      )}
    </div>
  );
};

export default StoragePage;
