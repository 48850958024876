import React from "react";

const TextArea = ({
  width,
  placeholder,
  Icon,
  value,
  onChange,
  height,
  register,
}) => {
  return (
    <div
      className={`${
        width ? width : " w-full "
      } py-2 px-3 bg-white/[0.5] hover:bg-white/[0.8] ${
        height ? height : " h-auto "
      }  customTransition shadow-md rounded-2xl flex items-center justify-between`}
    >
      <div className="flex items-center gap-3 w-full h-full">
        <img src={Icon} alt="IconSearch" className="self-start" />
        <textarea
          value={value}
          onChange={onChange}
          {...register}
          placeholder={placeholder}
          className="resize-none min-h-[160px] mt-1 w-full h-full pr-3 bg-transparent border-none focus:outline-none text-primary font-semibold placeholder:text-secondary placeholder:font-semibold"
        />
      </div>
    </div>
  );
};

export default TextArea;
