import { message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import TagDetailsModal from "../../component/modals/TagDetailsModal";
import {
  createAsset,
  deleteAsset,
  fetchAssets,
  updateAsset,
} from "../../store/asset/assetActions";
import {
  AssetTagging,
  Communication,
  ProjectDetails,
  Share,
  Storage,
} from "./Components";

import { getSingleProject } from "../../store/projects/projectActions";
import MatterportSidebar from "./MatterportSidebar";
import style from "./matterPort.module.scss";
import { showMessage } from "../../Utils/pushNotification";
import AddEditTagModal from "../../component/modals/NewTagModal";
import ActiveTab from "../../component/modals/ActiveTagDetailsModal";
import AutodeskViewer from "../AutodeskViewer/AutodeskViewer";
const MatterPort = () => {
  const { selectedProject } = useSelector((state) => state.project);
  const role = useSelector((state) => state.user.role).toLowerCase();

  const { assets } = useSelector((state) => state.asset);
  const { id: projectId } = useParams();
  const sdkKey = process.env.REACT_APP_MATTERPORT_SDK;
  const [mpSdk, setMpSdk] = useState({});
  const [addingTag, setAddingTag] = useState(false);
  const [matterPortAssetsState, setMatterPortAssetsState] = useState([]);
  const [movingTag, setMovingTag] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState("");
  const [tagDetailModal, setTagDetailModal] = useState(false);
  const [chosen, setChosen] = useState(null);
  const [tagPos, setTagPos] = useState({});
  const subscription = useRef(null);
  const [tag, setTag] = useState(null);
  const iframe_ref = useRef(null);
  const matterPortAssets = useRef([]);
  const tagPosition = useRef({});
  const tagItem = useRef(null);
  const tagDataItem = useRef({});
  const selectedProjectApiCalled = useRef(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleProject(projectId));
    dispatch(fetchAssets({ id: projectId }));
  }, []);

  const handleDeleteTag = (data) => {
    mpSdk.Mattertag.remove(data.tagId)
      .then((value) => {})
      .catch((e) => {
        console.log(e);
      });

    dispatch(deleteAsset(data._id))
      .unwrap()
      .then((res) => {
        showMessage("success", res?.message);
      })
      .catch((err) => {
        showMessage("error", "Could not delete asset");
      });
  };

  const submitAddTag = (data) => {
    tagDataItem.current = data;
    let AssetId = data?.selectedTag;

    if (data?.selectedTag) {
      delete data.selectedTag;
      dispatch(updateAsset({ id: AssetId, data }))
        .unwrap()
        .then((res) => showMessage("success", res?.message))
        .catch((err) => showMessage("error", "Could not update Asset"));
    } else {
      if (!addingTag) {
        setAddingTag(true);
        mpSdk?.Mattertag?.add([
          {
            label: data?.title,
            description: data?.description,
            anchorPosition: { x: 0, y: 0, z: 3 },
            stemVector: { x: 0, y: 0, z: 0 },
            color: data?.color,
          },
        ])
          .then((sid) => {
            setTag(sid[0]);
            tagItem.current = sid[0];
            return mpSdk.Mattertag.getData();
          })
          .then((collection) => {
            const t_sid = collection.find((elem) => elem.sid === tag);
            setAddingTag(false);
          })
          .then(() => {})
          .catch((e) => {
            console.error(e);
            setAddingTag(false);
          });
      }
    }
  };
  useEffect(() => {
    if (mpSdk && Object.keys(mpSdk).length > 0) {
      mpSdk.on(mpSdk.Mattertag.Event.CLICK, function (tagSid) {
        var asset = matterPortAssets.current.find(
          (asset) => asset.sid === tagSid
        );
        setSelectedAsset(asset);
        setTagDetailModal(true);
      });
      setTimeout(() => {}, 3000);
    }
  }, [mpSdk]);

  useEffect(() => {
    var tags = assets.map((asset) => ({
      ...asset,
      tagSid: asset.tagId,
      label: asset.title,
      description: asset.description,
      anchorPosition: asset?.assetLocation?.newPos
        ? asset.assetLocation.newPos
        : { x: 0, y: 0, z: 3 },
      stemVector: asset?.assetLocation?.stemVector
        ? asset.assetLocation.stemVector
        : { x: 0, y: 0, z: 0 },
      color: asset.color,
    }));
    if (mpSdk && Object.keys(mpSdk).length > 0) {
      mpSdk.Mattertag.getData()
        .then((oldTags) => {
          let oldTagSids = oldTags.map((oldTag) => oldTag.sid);
          return mpSdk.Mattertag.remove(oldTagSids);
        })
        .then(() => {
          return mpSdk.Mattertag.add(tags);
        })
        .then((newSids) => {
          tags.forEach((tag, i) => {
            mpSdk.Mattertag.preventAction(newSids[i], {
              opening: true,
            });
            tag.sid = newSids[i];
            tag.index = i;
          });
          matterPortAssets.current = tags;
          setMatterPortAssetsState(tags);
          return tags;
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [assets, assets?.length, mpSdk]);

  const createTagInDb = (tag, pos) => {
    tagItem.current = null;
    tagPosition.current = {};
    tagDataItem.current.tagId = tag;
    tagDataItem.current.assetLocation = pos;

    const form_data = { projectId, ...tagDataItem.current };
    dispatch(createAsset(form_data))
      .unwrap()
      .then((res) => showMessage("success", res?.message))
      .catch((err) => showMessage("error", "Could not create Asset"));
  };
  const updateTagPos = (newPos, newNorm = undefined, scale = undefined) => {
    if (!newPos) return;
    if (!scale) scale = 0.33;
    if (!newNorm) newNorm = { x: 0, y: 1, z: 0 };
    const stemVector = {
      x: scale * newNorm.x,
      y: scale * newNorm.y,
      z: scale * newNorm.z,
    };

    setTagPos({ stemVector, newPos });
    tagPosition.current = { stemVector, newPos };

    mpSdk.Mattertag.editPosition(tag, {
      anchorPosition: newPos,
      stemVector: stemVector,
    }).catch((e) => {
      console.error(e);
      setTag(null);
      setMovingTag(false);
    });
  };

  const handleNavigateAsset = (data) => {
    mpSdk.Mattertag.navigateToTag(data.sid, mpSdk.Mattertag.Transition.FLY);
  };
  useEffect(() => {
    const eventListener = window.addEventListener("blur", function () {
      if (document.activeElement === iframe_ref.current) {
        if (tagItem.current && tagItem.current.length > 0) {
          createTagInDb(tagItem.current, tagPosition.current);
          setTag(null);
          setMovingTag(false);
        }
        setTimeout(function () {
          window.focus();
        }, 0);
      }
    });
    return () => window.removeEventListener("blur", eventListener);
  }, []);
  useEffect(() => {
    if (mpSdk && Object.keys(mpSdk).length > 0) {
      subscription.current = mpSdk.Pointer.intersection.subscribe(
        (intersectionData) => {
          if (tag && !movingTag) {
            if (
              intersectionData.object === "intersectedobject.model" ||
              intersectionData.object === "intersectedobject.sweep"
            ) {
              updateTagPos(intersectionData.position, intersectionData.normal);
            }
          }
        }
      );
    }
    return () => {
      if (tag) {
        window.subscription = subscription;

        subscription?.current?.cancel();
      }
    };
  }, [tag]);

  useEffect(() => {
    if (selectedProject && selectedProject._id === projectId) {
      if (true) {
        // selectedProjectApiCalled.current = true;
        iframe_ref.current.setAttribute(
          "src",
          "https://my.matterport.com/show/?m=" +
            selectedProject.matterPortUrl +
            "&hhl=0&play=1&tiles=1&hl=0"
        );
        iframe_ref.current.addEventListener("load", () => {
          try {
            window.MP_SDK.connect(iframe_ref.current, sdkKey, "3.10")
              .then((mySdk) => setMpSdk(mySdk))
              .catch(console.error);
          } catch (e) {
            console.error(e);
          }
        });
      }
    }
  }, [selectedProject]);

  return (
    <div
      className={`md:max-w-[calc(100vw-153px)] shadow-2xl lg:max-w-[calc(100vw-170px)] overflow-hidden flex justify-between bg-[#FCFCFC]/[0.6] max-h-[calc(100vh-180px)] h-full lg:max-h-[calc(100vh)] ${style.matterPortMain}`}
    >
      <iframe
        ref={iframe_ref}
        width="100%"
        height="100%"
        allow="fullscreen; vr"
        id="iframe"
      />

      <MatterportSidebar setChosen={setChosen} chosen={chosen} />

      <div className={style.chosenModel}>
        {chosen === "Tags List" ? (
          <AssetTagging
            assets={matterPortAssetsState}
            submitAddTag={submitAddTag}
            handleDeleteTag={handleDeleteTag}
            handleNavigateAsset={handleNavigateAsset}
            setChosen={setChosen}
          />
        ) : chosen === "Storage" ? (
          <Storage open={true} setOpen={() => setChosen("")} />
        ) : chosen === "Share" ? (
          <Share open={true} setOpen={() => setChosen("")} />
        ) : chosen === "Add Tag" ? (
          <AddEditTagModal
            open={true}
            setOpen={() => setChosen("")}
            type="Add"
            submitAddTag={submitAddTag}
          />
        ) : chosen === "BIM Model" ? (
          <>{navigate(`/${role.toLowerCase()}/project/bim/${projectId}`)}</>
        ) : (
          ""
        )}
      </div>
      {tagDetailModal && (
        <TagDetailsModal
          selectedTag={selectedAsset}
          open={tagDetailModal}
          setOpen={setTagDetailModal}
          handleTagDetails={submitAddTag}
        />
      )}
    </div>
  );
};

export default MatterPort;
