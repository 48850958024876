/* global Autodesk */
import React, { useEffect, useRef } from "react";
import style from "./autodeskViewer.module.scss";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  getBimModelAccessToken,
  getSingleProject,
} from "../../store/projects/projectActions";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../Utils/pushNotification";

const AutodeskViewer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const viewerDiv = useRef(null);
  const { bimModelAccessToken, selectedProject } = useSelector(
    (state) => state.project
  );
  const role = useSelector((state) => state.user.role).toLowerCase();

  // useEffect(() => {
  //   dispatch(getSingleProject(projectId));
  // }, [dispatch, projectId]);

  useEffect(() => {
    if (selectedProject?.geomausBimModel) {
      dispatch(getBimModelAccessToken())
        .unwrap()
        .then((res) => console.log("DONE"))
        .catch((err) => showMessage("error", err?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, selectedProject?.geomausBimModel]);

  useEffect(() => {
    if (selectedProject?.geomausBimModel) {
      const loadForgeViewer = async () => {
        const accessToken = bimModelAccessToken;
        console.log("Access Token:", accessToken);
        if (!accessToken) {
          return;
        }

        const options = {
          env: "AutodeskProduction",
          api: "derivativeV2",
          getAccessToken: (onSuccess) => {
            onSuccess(accessToken, Date.now() + 60 * 30 * 1000); // Token expiration in milliseconds
          },
        };

        Autodesk.Viewing.Initializer(options, () => {
          const viewer = new Autodesk.Viewing.GuiViewer3D(viewerDiv.current, {
            extensions: ["Autodesk.DocumentBrowser", "Autodesk.BimWalk"], // Load necessary extensions
            qualityLevel: false, // Lower the quality level for performance
          });
          viewer.start();

          const documentId = `urn:${selectedProject.geomausBimModel}`;

          Autodesk.Viewing.Document.load(documentId, (doc) => {
            const viewables = doc.getRoot().getDefaultGeometry();

            viewer.loadDocumentNode(doc, viewables).then(
              () => {
                console.log("Viewer loaded successfully");

                // Ensure the extension is loaded
                viewer.loadExtension("Autodesk.BimWalk").then(() => {
                  // Call the floorSelectionValid function
                  const floorExtension =
                    viewer.getExtension("Autodesk.BimWalk");
                  if (floorExtension && floorExtension.floorSelectionValid) {
                    const isValid = floorExtension.floorSelectionValid();
                    console.log("Floor selection valid:", isValid);
                  } else {
                    console.log("Floor selection not available.");
                  }
                });
              },
              (error) => {
                console.error("Error loading document node:", error);
              }
            );
          });
        });
      };

      // Check if Autodesk namespace is available
      if (window.Autodesk) {
        loadForgeViewer();
      } else {
        const script = document.createElement("script");
        script.src =
          "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js";
        script.onload = loadForgeViewer;
        document.head.appendChild(script);
      }

      return () => {
        if (window.Autodesk && Autodesk.Viewing && Autodesk.Viewing.shutdown) {
          Autodesk.Viewing.shutdown();
        }
      };
    }
  }, [bimModelAccessToken, selectedProject?.geomausBimModel, projectId]);

  return (
    <>
      <div
        className={style.backButton}
        onClick={() =>
          navigate(`/${role.toLowerCase()}/projects/matterport/${projectId}`)
        }
      >
        <img src="/assets/images/blueBackIcon.svg" alt="" />
        <span className={style.text}>Back to 3D scan</span>
      </div>
      <div className={style.viewerContainer}>
        {selectedProject?.geomausBimModel ? (
          <div ref={viewerDiv} className={style.viewerDiv} />
        ) : (
          "Model not found. Please first upload a model."
        )}
      </div>
    </>
  );
};

export default AutodeskViewer;
