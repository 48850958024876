import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";
import { message } from "antd";
import { showMessage } from "../Utils/pushNotification";
function initSocket() {
  return io(process.env.REACT_APP_GEOMAUS_API_BASE, {
    auth: {
      token:
        localStorage.getItem("customer_access_token") ||
        localStorage.getItem("access_token") ||
        null,
    },
  });
}
let socket = null;
//
export default function useSocket() {
  const [isConnected, setIsConnected] = useState(socket?.connected);
  const [lastNotification, setLastNotification] = useState(null);
  const { isCustomer } = useSelector((state) => state.user);
  //
  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        setIsConnected(true);
      });
      socket.on("reconnect", () => {
        setIsConnected(true);
      });
      //
      socket.on("disconnect", () => {
        setIsConnected(false);
      });
      //
      socket.on("notification", (data) => {
        console.log("notification", data);
        setLastNotification(data);
        showMessage("info", data.title);
      });
      //
      return () => {
        socket.off("connect");
        socket.off("disconnect");
        socket.off("notificaiton");
      };
    }
  }, [socket]);
  useEffect(() => {
    if (socket) {
      socket.disconnect();
    }
    socket = initSocket();
  }, [isCustomer]);
  //
  return {
    isConnected,
    lastNotification,
  };
}
