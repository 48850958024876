import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LogoutModal from "../modals/LogoutModal";
import ProfileModal from "../modals/ProfileModal";
import { Tooltip } from "antd";
import { toTitleCase } from "../../Utils/functions";
import styles from "./sidebar.module.scss";
import { AddProjectButton, SvgIcon } from "../macros/index";
import AddProject from "../modals/addUpdateProjectModal";
import { setProjectModel } from "../../store/projects/projectSlice";
import ConfirmationModal from "../modals/ConfitmationModal";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.user);
  // const { isSubscribed } = useSelector((state) => state.user.userData.user);
  const { isSubscribed } = useSelector((state) => state.user);

  const role = useSelector((state) => state.user.role).toLowerCase();

  const email = userData?.user?.email;
  const name = userData?.user?.name;
  const photo = userData?.user?.photo;

  const [userRole, setUserRole] = useState(null);
  const [profileModal, setProfileModal] = useState(false);
  const [currentRoute, setCurrentRoute] = useState("");
  const [logoutModal, setLogoutModal] = useState(false);
  const [addProjectModal, setAddProjectModal] = useState(false);

  const [hoveredItem, setHoveredItem] = useState(null);

  const location = useLocation();
  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location]);
  useEffect(() => {
    setUserRole(role);
  }, [role]);

  // const Pages = [
  //   {
  //     name: "dashboard",
  //     src: "/assets/images/IconDashboard.svg",
  //     route: `${userRole}/dashboard`,
  //     permission: ["admin", "customer", "user"],
  //   },
  //   {
  //     name: "projects",
  //     src: "/assets/images/IconProject.svg",
  //     route: `${userRole}/projects`,
  //     permission: ["admin", "customer", "user"],
  //   },

  //   {
  //     name: "notifications",
  //     src: "/assets/images/IconNotification.svg",
  //     route: `${userRole}/notifications`,
  //     permission: ["customer", "user"],
  //   },
  //   {
  //     name: "features",
  //     src: "/assets/images/IconFeature.svg",
  //     route: `${userRole}/features`,
  //     permission: ["admin", "customer", "user"],
  //   },
  //   {
  //     name: "updates",
  //     src: "/assets/images/IconUpdates.svg",
  //     route: `${userRole}/updates`,
  //     permission: ["admin"],
  //   },
  //   {
  //     name: "customers",
  //     src: "/assets/images/IconCustomers.svg",
  //     route: `${userRole}/people`,
  //     permission: ["admin", "customer"],
  //   },
  //   {
  //     name: "tutorials",
  //     src: "/assets/images/IconTutorials.svg",
  //     route: `${userRole}/tutorials`,
  //     permission: ["admin", "customer", "user"],
  //   },
  // ];

  const pageGroup1 = [
    {
      name: "dashboard",
      label: "Dashboard",
      iconType: "homeIcon",
      route: `${userRole}/dashboard`,
      permission: ["admin", "customer", "user"],
    },
    {
      name: "buildings",
      label: "Buildings",
      iconType: "discoveryIcon",
      route: `${userRole}/projects`,
      permission: ["admin", "customer", "user"],
    },
    {
      name: "requests",
      label: "Feature Requests",
      iconType: "requestsIcon",
      route: `${userRole}/features`,
      permission: ["admin", "customer", "user"],
    },
    {
      name: "updates",
      label: "Updates",
      iconType: "starIcon",
      route: `${userRole}/updates`,
      permission: ["admin"],
    },
    {
      name: "folder",
      label: "Storage",
      iconType: "folderIcon",
      route: `${userRole}/storage`,
      permission: ["admin", "customer", "user"],
    },
    {
      name: "client",
      label: `${userRole == "admin" ? "Clients" : "Peoples"}`,
      iconType: "memberIcon",
      route: `${userRole}/people`,
      permission: ["admin", "customer"],
    },
    {
      name: "invoice",
      label: "Invoices",
      iconType: "memberIcon",
      route: `${userRole}/customer-invoice`,
      permission: ["admin"],
    },
  ];

  const pageGroup2 = [
    {
      name: "tutorials",
      label: "Tutorials",
      iconType: "scanIcon",
      route: `${userRole}/tutorials`,
      permission: ["admin", "customer", "user"],
    },
    {
      name: "contacts",
      label: "Contact Support",
      iconType: "dangerIcon",
      route: `/support`,
      permission: ["admin", "customer", "user"],
    },
  ];

  const bottomItems = [
    {
      name: "setting",
      label: "Settings",
      iconType: "settingIcon",
      onClick: () => navigate(`${userRole}/profile/setting`),
    },
    {
      name: "logout",
      label: "Log Out",
      iconType: "logIcon",
      onClick: () => {
        setLogoutModal(true);
      },
    },
  ];

  const handleMouseEnter = (itemName) => {
    setHoveredItem(itemName);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <div className={styles.sideBarLeft}>
      <div className={styles.profile}>
        <img
          src={`${photo ? photo : "/assets/images/ProfilePic.png"}`}
          alt=""
        />
        <div className={styles.userId}>
          <h2>{name}</h2>
          <p>{email}</p>
        </div>
        <div className={styles.notifi}>
          <NavLink to={`${role.toLowerCase()}/notifications`}>
            <img src="/assets/images/Notification.svg" alt="" />
          </NavLink>
        </div>
      </div>

      <div className={styles.nevMenu}>
        <h3>Overview</h3>

        <ul className={styles.navItem}>
          {pageGroup1
            ?.filter((item) => item.permission.includes(role))
            .map((item) => (
              <li
                className={`${
                  currentRoute.toLowerCase().includes(item.route.toLowerCase())
                    ? styles.activePage
                    : ""
                }`}
              >
                <NavLink
                  to={item.route}
                  onMouseEnter={() => handleMouseEnter(item.name)}
                  onMouseLeave={handleMouseLeave}
                  className={
                    hoveredItem === item.name ? styles.opacityHigh : ""
                  }
                >
                  <SvgIcon
                    type={item.iconType}
                    hoverOpacity="1"
                    color="white"
                    isHovered={
                      hoveredItem === item.name ||
                      currentRoute
                        .toLowerCase()
                        .includes(item.route.toLowerCase())
                    }
                  />
                  {item.label}
                </NavLink>
              </li>
            ))}

          {role == "admin" && (
            <li className={styles.projectAdd}>
              <AddProjectButton
                label="Add new project"
                icon="/assets/images/AddOne.svg"
                type="Add"
                onClick={() => dispatch(setProjectModel(true))}
              />
            </li>
          )}

          {pageGroup2
            ?.filter((item) => item.permission.includes(role))
            .map((item) => (
              <li
                className={`${
                  currentRoute.toLowerCase().includes(item.route.toLowerCase())
                    ? styles.activePage
                    : ""
                }`}
              >
                <NavLink
                  to={item.route}
                  onMouseEnter={() => handleMouseEnter(item.name)}
                  onMouseLeave={handleMouseLeave}
                  className={`${
                    item.name === "contacts" ? styles.support : ""
                  } ${hoveredItem === item.name ? styles.opacityHigh : ""} `}
                >
                  <SvgIcon
                    type={item.iconType}
                    hoverOpacity="1"
                    color="white"
                    isHovered={
                      hoveredItem === item.name ||
                      currentRoute
                        .toLowerCase()
                        .includes(item.route.toLowerCase())
                    }
                  />
                  {item.label}
                </NavLink>
              </li>
            ))}

          {/* {role != "admin" && role != "user" && (
            <>
              <NavLink to={`${role}/payment`} className={styles.upgradesub}>
                <div className={styles.subscription}>
                  <div className={styles.bgRight}>
                    <span className={styles.bglabel}></span>
                    <span className={styles.bglabel}></span>
                    <span className={styles.bglabel}></span>
                  </div>
                  <h2>Upgrade your subscription</h2>
                  <p>Lorem ipsum dolor sit amet</p>

                  <button type="button" class={styles.upgradebtn}>
                    Upgrade{" "}
                  </button>
                </div>
              </NavLink>
            </>
          )} */}

          {bottomItems.map((item) => (
            <li key={item.name}>
              <a
                onClick={item.onClick}
                onMouseEnter={() => handleMouseEnter(item.name)}
                onMouseLeave={handleMouseLeave}
                className={hoveredItem === item.name ? styles.opacityHigh : ""}
              >
                <SvgIcon
                  type={item.iconType}
                  hoverOpacity="1"
                  color="white"
                  isHovered={hoveredItem === item.name}
                />
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.footerLogo}>
        <p>Powered By:</p>
        <img src="/assets/images/Logo.png" alt="" />
      </div>

      {profileModal && (
        <ProfileModal open={profileModal} setOpen={setProfileModal} />
      )}
      <AddProject
        isOpen={addProjectModal}
        setOpen={setAddProjectModal}
        type="Add"
      />
      {logoutModal && (
        <ConfirmationModal
          text={"Do you want to logout?"}
          onClick={handleLogout}
          isModal={logoutModal}
          setIsModal={setLogoutModal}
        />
      )}
    </div>
  );
};

export default Sidebar;
