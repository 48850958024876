import React, { useState } from "react";
import { useController } from "react-hook-form";
import style from "./colorInput.module.scss";

const ColorInput = ({ register, name, control, disabled }) => {
  const { field } = useController({ name, control });
  const color = field.value || null;
  return (
    <div className={style.colorInput}>
      <label className={style.label}>Tag Color</label>
      <div className={style.inputMain}>
        <div className="flex items-center">
          <span className={color ? style.inputText : style.placeholderText}>
            {color ? color : "Select a Color"}
          </span>
        </div>
        <div className="h-[24px] w-[24px] bg-[#868cb1] rounded-lg flex justify-center items-center">
          <label
            className={`bg-[${
              color ? color : "#45E09F"
            }] h-[14px] w-[14px] rounded-full cursor-pointer`}
          >
            <input
              disabled={disabled}
              type="color"
              className="invisible"
              {...register}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default ColorInput;
