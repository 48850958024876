import React, { useState, useEffect } from "react";

const CountDown = ({ duration, onTimeout }) => {
  const [seconds, setSeconds] = useState(duration);

  useEffect(() => {
    // Create a countdown interval
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(interval);
        onTimeout(); // Call the callback function when timer expires
      }
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, [seconds, onTimeout]);

  // Format seconds into minutes and seconds
  const displayTime = `${Math.floor(seconds / 60)}:${String(
    seconds % 60
  ).padStart(2, "0")}`;

  return <div>Time remaining: {displayTime}</div>;
};

export default CountDown;
