import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { message, Spin } from "antd";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addFeature } from "../../store/feature/featureActions";
import { addUpdate } from "../../store/updates/updateActions";
import { update_feature_Schema } from "../../Utils/Validations";
import {
  Button,
  Button1,
  FormInput,
  Input,
  ModalContent,
  ReactDropzone,
  TextArea,
} from "../macros";
import { SideModel } from "../macros";
import style from "./insertFeatureUpdateModal.module.scss";
import { setUpdateModel } from "../../store/updates/updateSlice";
import { setFeatureModel } from "../../store/feature/featureSlice";
import { showMessage } from "../../Utils/pushNotification";

const AddUpdateFeatureModel = ({ isOpen, type }) => {
  const dispatch = useDispatch();

  const handleCancel = () => {
    if (type == "Update") {
      dispatch(setUpdateModel(false));
    } else {
      dispatch(setFeatureModel(false));
    }
    reset();
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(update_feature_Schema),
  });
  const onSubmit = async (data) => {
    if (type === "Update") {
      dispatch(addUpdate(data))
        .unwrap()
        .then((res) => (showMessage("success", res?.message), handleCancel()))
        .catch(
          (err) => (showMessage("error", "Error Adding Update"), handleCancel())
        );
    } else if (type === "Feature") {
      dispatch(addFeature(data))
        .unwrap()
        .then((res) => (showMessage("success", res?.message), handleCancel()))
        .catch(
          (err) => (
            showMessage("error", "Error Adding Feature Request"), handleCancel()
          )
        );
    }
  };

  return (
    <SideModel isOpen={isOpen} width={"xl"} handleReset={handleCancel}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className={style.featureUpdateForm}>
            <h2>
              {type == "Update" ? "Add Update Request" : "Add Feature Request"}
            </h2>
            <p className={style.uploadText}>
              {type == "Update"
                ? "Add an update here so your clients can be notified on exciting additions to GeoMaus!"
                : " Looking for a feature or a quality of life addition to be made?"}
            </p>

            <FormInput
              inputType="text"
              placeholder="Write your title here..."
              label="Title"
              register={register("title")}
              name="title"
              errors={errors}
            />
            <FormInput
              inputType="text"
              placeholder="Add a description here..."
              label="Description"
              register={register("details")}
              name="details"
              errors={errors}
            />
          </div>
          <div className={style.featureBtn}>
            <Button1
              theme={{ btn: style.addUpload }}
              title="Add"
              type="submit"
            />

            <Button1
              theme={{ btn: style.cancleUpload }}
              type="button"
              title="Cancel"
              onClick={() => {
                handleCancel();
              }}
            />
          </div>
        </div>
      </form>
    </SideModel>
  );
};

export default AddUpdateFeatureModel;
