import { createSlice } from "@reduxjs/toolkit";
import {
  addCustomer,
  deleteCustomer,
  getPeople,
  getProjectsByCustomer,
  getUsersByProject,
  updateCustomer,
} from "./customerActions";

const initialState = {
  customers: [],
  totalCustomer: null,
  numberOfRows: null,
  pageIndex: null,
  usersByProject: [],
  loading: false,
  error: null,
  isEditCustomerModal: false,
  selectedCustomerForEdit: undefined,
  isAddCustomerModal: false,
};
const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setEditCustomerModal: (state, action) => {
      state.isEditCustomerModal = action.payload;
    },
    setCustomerDataForEdit: (state, action) => {
      state.selectedCustomerForEdit = action.payload;
    },
    setAddCustomerModal: (state, action) => {
      state.isAddCustomerModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getPeople.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPeople.fulfilled, (state, action) => {
        state.loading = false;
        state.customers = action?.payload?.result?.docs;
        state.totalCustomer = action?.payload?.result?.totalDocs;
        state.numberOfRows = action?.payload?.result?.limit;
        state.pageIndex = action?.payload?.result?.page;
        state.error = null;
      })
      .addCase(getPeople.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data.message;
      })
      .addCase(addCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.customers = [action.payload.message.user, ...state.customers];
        state.totalCustomer++;
        state.error = null;
      })
      .addCase(addCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data.message;
      })
      .addCase(updateCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.loading = false;
        const customer = action.payload.result;
        const customers = state.customers;
        const filteredCustomers = customers.filter((obj) => {
          return obj._id !== customer._id;
        });
        state.customers = [customer, ...filteredCustomers];
        state.error = null;
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data.message;
      })
      .addCase(deleteCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.loading = false;
        const deletedId = action.payload.result;
        const customers = state.customers;
        const filteredCustomers = customers.filter(function (obj) {
          return obj._id !== deletedId;
        });
        state.customers = [...filteredCustomers];
        state.totalCustomer--;
        state.error = null;
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data.message;
      })
      .addCase(getProjectsByCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectsByCustomer.fulfilled, (state, action) => {
        if (action.payload.result && action.payload.result.length > 0) {
          const selectedCustomer = state.customers.find(
            (item) => item._id === action.payload.result[0].customerId
          );
          if (selectedCustomer) {
            selectedCustomer.projects = action.payload.result;
          }
        }
        state.error = null;
      })
      .addCase(getProjectsByCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      })
      .addCase(getUsersByProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsersByProject.fulfilled, (state, action) => {
        state.usersByProject = action.payload.result;
        state.loading = false;
      })
      .addCase(getUsersByProject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data.message;
      });
  },
});

export const customerReducer = customerSlice.reducer;
export const {
  setEditCustomerModal,
  setAddCustomerModal,
  setCustomerDataForEdit,
} = customerSlice.actions;
