import { useEffect, useState } from "react";
import {
  setSelectedItem,
  setTutorialModel,
} from "../../../store/tutorials/tutorialSlice";
import Button1 from "../Button1";
import ContactSupport from "../pageFooter/ContactSupport";
import styles from "./tutorialCard.module.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const TutorialCard = ({ items, handleVideo }) => {
  const dispatch = useDispatch();

  const [processedData, setProcessedData] = useState([]);

  // Function to extract the YouTube video ID from a URL
  const extractVideoId = (url) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/|youtube.com\/live\/|youtube.com\/shorts\/|)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  console.log("items", items);
  const processData = (data) => {
    return data.map((item) => {
      const videoId = extractVideoId(item.video);
      const standardThumbnail = videoId
        ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
        : "";
      return { ...item, defaultThumbnail: standardThumbnail };
    });
  };

  useEffect(() => {
    setProcessedData(processData(items));
  }, [items]);

  const role = useSelector((state) => state.user.role);

  return (
    <>
      <div className={styles.tutorialsMain}>
        {processedData?.map((item) => (
          <div className={styles.tutorialCard}>
            <div className={styles.videoCard}>
              <div className={styles.videoImg}>
                <img
                  className={styles.img}
                  src={`${
                    item.thumbnail ? item.thumbnail : item.defaultThumbnail
                  }`}
                  alt=""
                />
                {/* <img src="/assets/images/videoImg.svg" alt="" /> */}
                {role == "ADMIN" && (
                  <div
                    onClick={() => {
                      dispatch(setSelectedItem(item));
                      dispatch(setTutorialModel(true));
                    }}
                  >
                    <a href="#" className={styles.tutorialEdit}>
                      <img
                        className={styles.img}
                        src="/assets/images/editIcon.png"
                        alt=""
                      />
                    </a>
                  </div>
                )}
              </div>

              <div className={styles.videoDetile}>
                <h3>{item.title}</h3>
                {/* <div className={styles.detile}>
                  Lorem ipsum dolor sit amet, consectetur <br /> adipiscing
                  elit.
                </div> */}
                <div className={styles.tutorialAdmin}>
                  {/* <div className={styles.tutorialInfo}>
                    <h3>Mentor</h3>
                    <div className={styles.createrInfo}>
                      <div className={styles.mentorImg}>
                        <img src="/assets/images/profilepic.svg" alt="" />
                      </div>
                      <h3>Haithem Hadded</h3>
                    </div>
                  </div> */}

                  <Button1
                    title="Watch  Now"
                    icon="/assets/images/ArrowRightWhite.png"
                    onClick={() => handleVideo(item.video)}
                    theme={{ btn: styles.createrBtn }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ContactSupport />
    </>
  );
};

export default TutorialCard;
