import React, { useState } from "react";
import styles from "./input2.module.scss";
import { themr } from "react-css-themr";

const Input2 = (props) => {
  const {
    label,
    type,
    name,
    placeholder,
    classNames,
    width,
    icon,
    alt,
    bg,
    maxLength,
    onChange,
    value,
    disabled,
    register,
    errors,
    theme,
  } = props;
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  const inputType = passwordVisible ? "text" : type;
  const eyeOpenIcon = "/assets/images/eyeClose.svg";
  const eyeCloseIcon = "/assets/images/eyeClose.svg";

  return (
    <>
      {label && <label htmlFor="Password">{label}</label>}

      <div
        className={`${theme.input} ${theme.inputwithicon} ${theme.iconemail}`}
      >
        {icon && (
          <span>
            <img src={icon} alt={alt ? alt : ""} />
          </span>
        )}

        <input
          disabled={disabled}
          value={value}
          type={inputType}
          name={name}
          maxLength={maxLength}
          onChange={onChange}
          placeholder={placeholder}
          {...(register ? { ...register } : {})}
        />
        {type === "password" && (
          <div
            className="absolute right-4 bottom-6 cursor-pointer"
            onClick={togglePasswordVisibility}
          >
            {passwordVisible ? (
              <img src={eyeCloseIcon} alt="Hide password" />
            ) : (
              <img src={eyeOpenIcon} alt="Show password" />
            )}
          </div>
        )}

        {errors && <div className={theme.invalid}>{errors[name]?.message}</div>}
      </div>
    </>
  );
};

export default themr("Input2", styles)(Input2);
