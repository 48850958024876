import React, { useEffect, useMemo } from "react";
import styles from "./authLayoutForms.module.scss";
import classNames from "classnames";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import {
  Button1,
  Button2,
  Input2,
  LanguageSelect,
} from "../../component/macros";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Spin } from "antd";
import {
  loginUser,
  googleAuth,
  getGoogleAuthData,
} from "../../store/users/userActions";
import { message } from "antd";
import AuthLayout from "./AuthLayout";
import { showMessage } from "./../../Utils/pushNotification";

const schema = yup.object({
  email: yup
    .string()
    .required("Email is required")
    .email("Email must be valid"),
  password: yup
    .string()
    .required("Password is required")
    .min(5, "Minimum 5 characters"),
});

const Signin = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { loading, userData } = useSelector((state) => state?.user);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function loginSuccess(res) {
    const role = res.result?.user.role[0].toLowerCase();
    localStorage.setItem("role", role);
    localStorage.setItem("access_token", res?.result?.accessToken);

    showMessage("success", res?.message);

    if (role === "admin") {
      navigate(`/admin/dashboard`, { replace: true });
    } else if (role === "customer") {
      navigate(`/customer/dashboard`, { replace: true });
    } else if (role === "user") {
      navigate(`/user/dashboard`, { replace: true });
    }
  }
  const onSubmit = (data) => {
    dispatch(loginUser(data))
      .unwrap()
      .then((res) => loginSuccess(res))
      .catch((err) => showMessage("error", err?.data?.message));
  };

  // Google Auth
  const googleCode =
    useMemo(() => {
      const code = queryParams.get("code");
      const scope = queryParams.get("scope");
      return { code, scope };
    }, [queryParams]) || {};

  useEffect(() => {
    if (googleCode && Object.keys(googleCode).length > 0) {
      if (googleCode?.scope?.includes("googleapis")) {
        dispatch(
          getGoogleAuthData({
            code: googleCode.code,
            callbackUrl: "login",
            role: "ADMIN",
            action: loginSuccess,
          })
        );
      }
    }
  }, []);

  return (
    <AuthLayout>
      <div className={classNames([styles.signinForm, styles.subForm])}>
        <h2>Login</h2>
        <p>
          Gain access to all of your buildings, all in one simple to use app
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputgroup}>
            <Input2
              label="Email"
              type="email"
              name="email"
              placeholder="olivia@untitledui.com"
              icon="assets/images/EmailInput.svg"
              alt=""
              register={register("email")}
              errors={errors}
            />
          </div>

          <div className={styles.inputgroup}>
            <Input2
              label="Password"
              type="password"
              name="password"
              placeholder="Password"
              icon="assets/images/PasswordEye.svg"
              alt=""
              register={register("password")}
              errors={errors}
            />
          </div>

          <div className={styles.forgot}>
            <NavLink className={styles.link} to="/forgot-password">
              Forgot Password?
            </NavLink>
          </div>

          <div className={styles.loginbtn}>
            <Button1 title="Log In" type="submit" />
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default Signin;
