import React, { useEffect, useState } from "react";
import { Button1, DropBox, FormInput, SideModel } from "../macros";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Button, Input, ModalContent, ReactDropzone } from "../macros";
import { tutorial } from "../../Utils/Validations";
import {
  addTutorial,
  updateTutorial,
} from "../../store/tutorials/tutorialActions";
import { message } from "antd";
import { handleImageDrop } from "../../Utils/functions";
import style from "./addUpdateTutorialModal.module.scss";
import {
  setTutorialModel,
  setSelectedItem,
} from "../../store/tutorials/tutorialSlice";
import { showMessage } from "../../Utils/pushNotification";

const AddUpdateTutorialModel = ({ isOpen, selectedData }) => {
  const type = selectedData ? "Edit" : "Add";

  const { loading } = useSelector((state) => state.tutorial);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      title: selectedData?.title,
      video: selectedData?.video,
    },
    resolver: yupResolver(tutorial),
  });

  useEffect(() => {
    if (selectedData) {
      // Set form values from selectedData
      setValue("title", selectedData.title || "");
      setValue("video", selectedData.video || "");
    }
  }, [selectedData, setValue]);

  const [imageBase64, setImageBase64] = useState(
    selectedData?.thumbnail ? selectedData?.thumbnail : ""
  );

  const onSubmit = async (data) => {
    data.thumbnail = imageBase64;
    if (type === "Add") {
      dispatch(addTutorial(data))
        .unwrap()
        .then(
          (res) => (
            showMessage("success", res?.message),
            dispatch(setTutorialModel(false))
          )
        )
        .catch(
          (err) => (
            showMessage("error", "Adding Tutorial Failed"),
            dispatch(setTutorialModel(false))
          )
        );
    } else if (type === "Edit") {
      dispatch(updateTutorial({ id: selectedData._id, data }))
        .unwrap()
        .then(
          (res) => (
            showMessage("success", res?.message),
            dispatch(setTutorialModel(false))
          )
        )
        .catch(
          (err) => (
            showMessage("error", "Updating Tutorial Failed"),
            dispatch(setTutorialModel(false))
          )
        );
    }
  };

  const handleReset = () => {
    dispatch(setTutorialModel(false));
    dispatch(setSelectedItem(undefined));
    reset();
  };

  return (
    <SideModel isOpen={isOpen} width={"xl"} handleReset={handleReset}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.tutorial}>
          <div className={style.tutorialForm}>
            <h2>Add Tutorial</h2>
            <p className={style.uploadText}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              <br /> sed do eiusmod tempor incididunt ut labore et .
            </p>

            <ReactDropzone
              onDrop={(acceptedFile) => (
                setFile(acceptedFile),
                handleImageDrop(acceptedFile, setImageBase64)
              )}
            >
              <DropBox
                text="Upload Thumbnail"
                fileName={file && file[0]?.name}
              />
            </ReactDropzone>

            <FormInput
              inputType="text"
              placeholder="Add the tutorial title"
              label="Tutorial Title"
              register={register("title")}
              name="title"
              errors={errors}
            />
            <FormInput
              inputType="text"
              placeholder="Add the tutorial link"
              label="Tutorial Link"
              register={register("video")}
              name="video"
              errors={errors}
            />
          </div>
          <div className={style.tutorialBtn}>
            <Button1
              theme={{ btn: style.addUpload }}
              title={type}
              type="submit"
            />

            <Button1
              theme={{ btn: style.cancleUpload }}
              type="button"
              title="Cancel"
              onClick={() => {
                dispatch(setTutorialModel(false));
                dispatch(setSelectedItem(undefined));
                reset();
              }}
            />
          </div>
        </div>
      </form>
    </SideModel>
  );
};

export default AddUpdateTutorialModel;
