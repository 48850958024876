import styles from "./authLayout.module.scss";

const AuthLayout = ({ children }) => {
  return (
    <div className={styles.headerText}>
      <div className={styles.bgImage}>
        <div className={styles.logo}>
          <img src="assets/images/Logo.png" alt="" className={styles.img} />
        </div>
        <h1>
          GeoMaus - Access everything <br /> you need, quick and easily
        </h1>
        {/* <div className={styles.about}>
          <h3>About</h3>
          <p>
            State of the art apps to <br /> make your life easier
          </p>
        </div> */}
      </div>
      <div className={styles.headerInfo}>
        <div className={styles.black}>
          <div className={styles.online}>
            <div className={styles.infoOnline}>
              <div className={styles.appmember}>Geomaus App</div>
              <div className={styles.appmember}>3 team members online</div>
            </div>
            <div className={styles.member}>
              <img src="assets/images/geomaus-team-members-online.svg" alt="" />
            </div>
            <div className={styles.AddBtn}>
              <div className={styles.newtag}>
                <img src="assets/images/FrameAdd.png" alt="" />
                <h2>Add New Tag</h2>
              </div>
              <div className={styles.newtag}>
                <img src="assets/images/FrameDown.png" alt="" />
                <h2>Add New Project</h2>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.form}>{children}</div>
      </div>
    </div>
  );
};

export default AuthLayout;
