import React, { useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Country } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import {
  checkoutSubscription,
  updateSubscription,
} from "../../store/subscription/subscriptionActions";
import { Spin, message } from "antd";
import { useNavigate } from "react-router-dom";
import { showMessage } from "../../Utils/pushNotification";
import { Button1, FormInput } from "../../component/macros";
import style from "./checkoutForm.module.scss";
import { setIsSubscribe } from "../../store/users/userSlice";

export default function CheckoutForm({ userCount, projectCount }) {
  const { loading } = useSelector((state) => state.subscription);
  // const { isSubscribed } = useSelector((state) => state.user.userData.user);
  const { isSubscribed } = useSelector((state) => state.user);

  const { role } = useSelector((state) => state.user);
  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  const navigate = useNavigate();
  const [cardInfo, setCardInfo] = useState({
    name: "",
    address: {
      country: "",
      line: "",
    },
  });

  const [locations, setLocations] = useState({
    countries: "",
    states: "",
    cities: "",
  });

  function handleChangeName(e) {
    const { value } = e.target;
    setCardInfo((prev) => ({ ...prev, name: value }));
  }

  function parseForSelect(arr) {
    return arr.map((item) => ({
      label: item.name,
      value: item.isoCode ? item.isoCode : item.name,
    }));
  }

  function handleSelectCountry(event) {
    setCardInfo((prev) => ({
      ...prev,
      address: { ...prev.address, country: event.target.value },
    }));
  }

  function handleChangeAddressLine(e) {
    const { value } = e.target;
    setCardInfo((prev) => ({
      ...prev,
      address: { ...prev.address, line: value },
    }));
  }

  async function handleSubmit() {
    const address = cardInfo.address;
    const billingDetails = {
      name: cardInfo.name,
      address: {
        country: address.country,
        line1: address.line,
      },
    };

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { token, error } = await stripe.createToken(cardElement, {
      name: cardInfo.name,
      address: billingDetails.address,
    });

    if (error) {
      console.log("error", error);
      return;
    }

    const payload = {
      token: token?.id,
      access: { name: "Features_Access", userCount, projectCount },
    };

    const action = isSubscribed
      ? updateSubscription(payload)
      : checkoutSubscription(payload);

    dispatch(action)
      .unwrap()
      .then((res) => {
        dispatch(setIsSubscribe(true));
        showMessage("success", res?.message);
        navigate(`/${role.toLowerCase()}/people`);
      })
      .catch((err) => {
        const message = isSubscribed
          ? "Error in updating Subscription"
          : "Error in Creating Subscription";
        showMessage("error", message);
        navigate(`/${role.toLowerCase()}/people`);
      });
  }

  useEffect(() => {
    const allCountry = Country.getAllCountries();
    setLocations((prev) => ({
      ...prev,
      countries: parseForSelect(allCountry),
    }));
  }, []);

  return (
    <div className="w-full">
      <h2 className={style.cardDetailsHeading}>Add Your Card Details</h2>
      {loading && (
        <div className={style.loader}>
          <Spin spinning={loading} size={24} />
        </div>
      )}

      <FormInput
        inputType="text"
        name="name"
        onChange={handleChangeName}
        placeholder="Enter card holder name"
        label="Cardholder name"
      />

      <div className={style.cardMain}>
        <label className={style.cardLabel}>Card Details</label>

        <div className={style.cardDetailsInput}>
          <CardElement />
        </div>
      </div>
      <div>
        <FormInput
          inputType="text"
          name="name"
          onChange={handleChangeAddressLine}
          placeholder="Enter Full Address"
          label="Address"
        />
        <div className={style.countryMain}>
          <label className={style.dropDownLabel}>Country</label>
          <select
            onChange={handleSelectCountry}
            name="countries"
            className={style.dropDownSelect}
          >
            {locations.countries !== "" &&
              locations?.countries?.map((country) => (
                <option key={country?.key} value={country?.value}>
                  {country?.label}
                </option>
              ))}
          </select>
        </div>
        <Button1
          type="button"
          title={isSubscribed ? "Submit" : "Add"}
          disabled={loading}
          theme={{ btn: style.checkOutBtn }}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
