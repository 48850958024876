import styled from "styled-components";
import ContactSupport from "../component/macros/pageFooter/ContactSupport";
import style from "./support.module.scss";

const SupportPage = () => {
  return (
    <div className={style.contactPageWrapper}>
      <ContactSupport />
    </div>
  );
};

export default SupportPage;
