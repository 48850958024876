import { useEffect, useState } from "react";
import Button1 from "./Button1";
import Button2 from "./Button2";
import style from "./projectCard.module.scss";
import { timestamptoDate } from "../../Utils/functions";
import HorizontalScrollbar from "./HorizontalScrollbar";
import { useDispatch, useSelector } from "react-redux";
import {
  setProjectModel,
  setSelectedItem,
} from "../../store/projects/projectSlice";
import { NavLink } from "react-router-dom";
import { Can } from "../../@permissions/Can";

const ProjectCard = ({ projects, setChosenProject, setDeleteModal }) => {
  const [projectData, setProjectData] = useState([]);
  const { role } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (projects) {
      setProjectData(projects);
    }
  }, [projects]);

  return (
    <>
      {projectData.length > 0 &&
        projectData.map((item) => (
          <>
            <div className={style.projectCard}>
              <NavLink
                to={`/${role.toLowerCase()}/projects/matterport/${item?._id}`}
              >
                <div className={style.projectImg}>
                  <img
                    src={`${
                      item.logoPhoto
                        ? item.logoPhoto
                        : "/assets/images/projectCardSeating.png"
                    } `}
                    alt=""
                  />
                </div>
              </NavLink>
              <div className={style.projectInfo}>
                <div
                  className={`${
                    item.status == "Completed"
                      ? style.completeStatus
                      : style.pendingStatus
                  }`}
                >
                  {item.status}
                </div>
                <h2>{item.title}</h2>
                <p className={style.description}>{item.description}</p>

                <div className={style.clientDetails}>
                  <div className={style.clintInfo}>
                    <div className={style.lightdotte1}></div>
                    <span className={style.textId}>Client Name</span>
                    <h4>{item.customerId?.name}</h4>
                  </div>

                  <div className={style.clintTags}>
                    <div className={style.lightdotte2}></div>
                    <span className={style.textId}>Tags</span>
                    <h4>{item?.assets?.length}</h4>
                  </div>

                  <div className={style.clintDate}>
                    <div className={style.lightdotte3}></div>
                    <span className={style.textId}>Date Add</span>
                    <h4>{timestamptoDate(item.createdAt)}</h4>
                  </div>
                </div>

                <div className={style.cardBtn}>
                  <Can I="edit" a="Project">
                    <Button1
                      type="button"
                      title="Edit Project"
                      theme={{ btn: style.btn }}
                      onClick={() => {
                        dispatch(setSelectedItem(item));
                        dispatch(setProjectModel(true));
                      }}
                    />
                  </Can>

                  {role.toLowerCase() === "admin" && (
                    <Can I="delete" a="Project">
                      <Button1
                        type="button"
                        icon="/assets/images/delete.svg"
                        theme={{ btn: style.removeBtn, icon: style.icon }}
                        onClick={() => {
                          setChosenProject(item);
                          setDeleteModal(true);
                        }}
                      />
                    </Can>
                  )}
                </div>
              </div>
            </div>
          </>
        ))}
    </>
  );
};

export default ProjectCard;
