import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { getSubscriptions } from "../../store/subscription/subscriptionActions";
import style from "./layoutMain.module.scss";

import Sidebar from "./Sidebar";
import TopBar from "./TopBar";
import AddUpdateProjectModel from "../modals/addUpdateProjectModal";
import { setProjectModel } from "../../store/projects/projectSlice";

const LayoutMain = () => {
  const role = useSelector((state) => state.user.role).toLowerCase();
  const { isOpenModel, selectedValue } = useSelector((state) => state.project);

  const [currentRoute, setCurrentRoute] = useState("");
  const { id, projectId } = useParams(); // Use useParams hook to get the ID from the URL

  const location = useLocation();
  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubscriptions());
  }, [dispatch]);

  const token = localStorage.getItem("access_token");
  if (!token || token === undefined) {
    return <Navigate to="/" replace />;
  }

  // Define a function to check if currentRoute matches a specific pattern
  const matchPattern = (pattern) => currentRoute.startsWith(pattern);

  // Conditionally render TopBar based on currentRoute and extracted ID
  const shouldRenderTopBar =
    !matchPattern(`/admin/dashboard`) &&
    !matchPattern(`/${role}/tutorials`) &&
    !currentRoute.startsWith(`/${role}/projects/matterport/${id ? id : ""}`) &&
    !currentRoute.startsWith(
      `/${role}/project/bim/${projectId ? projectId : ""}`
    ) &&
    !matchPattern(`/${role}/profile/setting`) &&
    !matchPattern(`/${role}/payment`) &&
    !matchPattern(`/support`);
  // && !matchPattern(`/admin/customer-invoice`);

  return (
    <div className={style.mainBg}>
      <Sidebar />
      {shouldRenderTopBar && <TopBar />}
      <div className={style.LayoutMain}>
        <Outlet />
      </div>
      <AddUpdateProjectModel
        isOpen={isOpenModel}
        {...(selectedValue ? { selectedData: selectedValue } : {})}
      />
    </div>
  );
};

export default LayoutMain;
