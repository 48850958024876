import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import style from "./comments.module.scss";
import { formatDate, formatTime } from "../../Utils/functions";

const Comments = ({ data }) => {
  const [comment, setComment] = useState();
  useEffect(() => {
    setComment(
      [...data].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    );
  }, [data]);
  const messagesContainerRef = useRef();

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [comment]);
  const currentUserId = useSelector((state) => state.user?.userData?.user?._id);
  const userLogo = useSelector((state) => state.user?.userData?.user?.photo);

  const lastCommentTime = () => {
    if (comment) {
      const lastMsgDate = comment[comment.length - 1]?.createdAt;
      return formatDate(lastMsgDate);
    }
  };

  return (
    // <div
    //   ref={messagesContainerRef}
    //   className="h-full w-full flex flex-col gap-2 overflow-y-scroll"
    // >
    //   {comment?.map((item, index) => (
    //     <div
    //       key={index}
    //       className={`${
    //         item?.userId._id === currentUserId ? " self-end" : " self-start"
    //       } flex items-start gap-4`}
    //     >
    //       {item?.userId._id !== currentUserId && (
    //         <div className="w-[27px] h-[27px] rounded-full">
    //           <img
    //             src={
    //               item?.profileImage
    //                 ? item?.profileImage
    //                 : "/assets/images/IconUser.svg"
    //             }
    //             alt="user_profile"
    //             className="w-full h-full object-contain"
    //           />
    //         </div>
    //       )}
    //       <div
    //         className={`relative rounded-xl px-4 py-2.5 w-[219px] ${
    //           item?.userId._id === currentUserId
    //             ? " bg-white mr-4"
    //             : " bg-[#A2A7C3]"
    //         }`}
    //       >
    //         {item?.userId._id !== currentUserId ? (
    //           <img
    //             src="/assets/images/ReceiverMessageHorn.svg"
    //             alt="ReceiverMessageHorn"
    //             className="absolute top-1.5 -left-[10px]"
    //           />
    //         ) : (
    //           <img
    //             src="/assets/images/SenderMessageHorn.svg"
    //             alt="SenderMessageHorn"
    //             className="absolute top-1.5 -right-3"
    //           />
    //         )}
    //         <h3 className="text-[17px] font-medium text-[#353535] mb-1">
    //           {item?.userId?.name}
    //         </h3>
    //         <p className="text-[17px] leading-5">{item?.title}</p>
    //       </div>
    //     </div>
    //   ))}
    // </div>

    <div className={style.messagesBox}>
      <div className={style.delieyTime}>
        <span className={style.timeText}>{lastCommentTime()}</span>
      </div>

      {comment?.map((item, index) => (
        <>
          {currentUserId == item?.userId?._id ? (
            <div key={index} className={style.ownerMain}>
              <div className={style.profileInfo}>
                <p className={style.sendTime}>{formatTime(item?.createdAt)}</p>

                <div className={style.profileImage}>
                  <div className={style.imgProfile}>
                    <img
                      src={`${
                        userLogo ? userLogo : "/assets/images/userProfile.svg"
                      }`}
                      alt=""
                    />
                  </div>
                  <h3 className={style.userName}>{item?.userId?.name}</h3>
                </div>
              </div>

              <div className={style.textMessage}>
                <p className={style.messageInput}>{item?.title}</p>
              </div>
            </div>
          ) : (
            <div className={style.replayMain}>
              <div className={style.profileInfo}>
                <div className={style.profileImage}>
                  <div className={style.imgProfile}>
                    <img
                      src={`${
                        userLogo ? userLogo : "/assets/images/userProfile.svg"
                      }`}
                      alt=""
                    />
                  </div>
                  <h3 className={style.userName}>{item?.userId?.name}</h3>
                </div>

                <p className={style.sendTime}>{formatTime(item?.createdAt)}</p>
              </div>

              <div className={style.textMessage}>
                <p className={style.messageInput}>{item?.title}</p>
              </div>
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default Comments;
