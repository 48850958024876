import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import LayoutMain from "./component/layout/LayoutMain";
import {
  Dashboard,
  Customer,
  Feature,
  Projects,
  Tutorial,
  Updates,
  ForgetPassword,
  Signin,
  MatterPort,
  Notification,
  Payment,
  Signup,
  SettingPage,
  Storage,
  Support,
} from "./pages";
import AutodeskViewer from "./pages/AutodeskViewer/AutodeskViewer";
import Invoice from "./pages/Invoices/invoice";

export const AppRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* ------- admin ----- */}
      <Route element={<LayoutMain />}>
        <Route
          index
          path="/admin"
          element={<Navigate to="/admin/dashboard" />}
        />
        <Route index path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/projects" element={<Projects />} />
        <Route path="/admin/features" element={<Feature />} />
        <Route path="/admin/updates" element={<Updates />} />
        <Route path="/admin/people" element={<Customer />} />
        <Route path="/admin/tutorials" element={<Tutorial />} />
        <Route path="/admin/notifications" element={<Notification />} />
        <Route path="/admin/projects/matterport/:id" element={<MatterPort />} />
        <Route path="/admin/profile/setting" element={<SettingPage />} />
        <Route path="/admin/storage" element={<Storage />} />
        <Route
          path="/admin/project/bim/:projectId"
          element={<AutodeskViewer />}
        />
        <Route path="/support" element={<Support />} />
        <Route path="/admin/customer-invoice" element={<Invoice />} />
      </Route>

      {/* -------   customers ----- */}
      <Route element={<LayoutMain />}>
        <Route
          index
          path="/customer"
          element={<Navigate to="/customer/dashboard" />}
        />
        <Route index path="/customer/dashboard" element={<Dashboard />} />
        <Route path="/customer/projects" element={<Projects />} />
        <Route path="/customer/features" element={<Feature />} />
        <Route path="/customer/notifications" element={<Notification />} />
        <Route path="/customer/people" element={<Customer />} />
        <Route path="/customer/tutorials" element={<Tutorial />} />
        <Route
          path="/customer/projects/matterport/:id"
          element={<MatterPort />}
        />
        <Route path="/customer/payment" element={<Payment />} />
        <Route path="/customer/profile/setting" element={<SettingPage />} />
        <Route path="/customer/storage" element={<Storage />} />
        <Route
          path="/customer/project/bim/:projectId"
          element={<AutodeskViewer />}
        />
        <Route path="/support" element={<Support />} />
      </Route>

      {/* ------- users ----- */}
      <Route element={<LayoutMain />}>
        <Route index path="/user/dashboard" element={<Dashboard />} />
        <Route path="/user/projects" element={<Projects />} />
        <Route path="/user/features" element={<Feature />} />
        <Route path="/user/notifications" element={<Notification />} />
        <Route path="/user/tutorials" element={<Tutorial />} />
        <Route path="/user/projects/matterport/:id" element={<MatterPort />} />
        <Route path="/user/profile/setting" element={<SettingPage />} />
        <Route path="/user/storage" element={<Storage />} />
        <Route
          path="/user/project/bim/:projectId"
          element={<AutodeskViewer />}
        />
        <Route path="/support" element={<Support />} />
      </Route>

      {/* -------  non protected routes ----- */}
      <Route>
        <Route index path="/" element={<Signin />} />
        <Route path="/forgot-password" element={<ForgetPassword />} />
        <Route path="/signup" element={<Signup />} />
      </Route>
    </>
  )
);
