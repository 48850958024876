import { message, Popover, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { Can } from "../@permissions/Can";
import {
  AddProjectButton,
  Button,
  Button1,
  FormInput,
  Input,
  Input2,
  TableComponent,
} from "../component/macros";
import AddEditProjectModal from "../component/modals/AddEditProjectModal";
import DeleteModal from "../component/modals/DeleteModal";
import useDebounce from "../hooks/useDebounce";
import {
  deleteProject,
  getProjects,
  getProjectWithAssets,
} from "../store/projects/projectActions";
import { timestamptoDate } from "../Utils/functions";
import { NavLink, useSearchParams } from "react-router-dom";
import ProjectCard from "./../component/macros/ProjectCard";
import styles from "./project.module.scss";
import { setProjectModel } from "../store/projects/projectSlice";
import FilterBy from "../component/macros/filterBy/filterBy";
import SortBy from "../component/macros/SortBy/SortBy";
import ConfirmationModal from "../component/modals/ConfitmationModal";
import { showMessage } from "../Utils/pushNotification";

const filterOptions = [
  { label: "Project Name", value: "title" },
  { label: "Status", value: "status" },
  { label: "Client Name", value: "client_name" },
];

const Projects = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { projects, loading, isOpenModel } = useSelector(
    (state) => state.project
  );

  const { role } = useSelector((state) => state.user);
  const [addModal, setAddModal] = useState(false);
  const [addProjectModal, setAddProjectModal] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [chosenProject, setChosenProject] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [value, setValue] = useState(() => searchParams.get("search") || "");
  const [searchValue, oldValue] = useDebounce(value, 500, true);
  const [modifiedDataSource, setModifiedDataSource] = useState(() => projects);
  const [sortType, setSortType] = useState("desc");
  const [filter, setFilter] = useState({
    name: filterOptions[0].value,
    value: "",
  });

  useMemo(() => {
    if (searchValue !== "" || (searchValue === "" && oldValue !== "")) {
      dispatch(getProjects({ searchValue, sortType }));
    } else {
      dispatch(getProjects({ searchValue, sortType }));
    }
  }, [searchValue, oldValue]);

  const handleDeleteProject = () => {
    dispatch(deleteProject(chosenProject?._id))
      .unwrap()
      .then((res) => {
        dispatch(getProjectWithAssets());
        showMessage("success", res?.message);
      })
      .catch((err) => showMessage("error", "Error Deleting Project"))
      .finally(setDeleteModal(false));
  };

  useEffect(() => {
    setFilter({
      name: filterOptions[0].value,
      value: "",
    });
    return () => {
      setFilter({
        name: filterOptions[0].value,
        value: "",
      });
    };
  }, []);

  useEffect(() => {
    if (projects) {
      setExportData(
        projects?.map((project) => {
          const item = {
            Project_Name: project?.title,
            Description: project?.description,
            Client_Name: project?.customerId?.name,
            Status: project?.status,
            Matterport_Code: project?.matterPortUrl,
            // Click_Embed_URL: project?.clickEmbedUrl,
            Marketing_Model: project?.geomausMarketingModel,
            Google_Map: project?.googleMapEmbed,
            Youtube_Video: project?.youtubeVideoEmbed,
          };
          return item;
        })
      );
    }
  }, [projects]);

  useEffect(() => {
    if (filter?.value > "") {
      const path = filter.name;

      if (path === "client_name") {
        const data = projects?.filter((project) =>
          project?.customerId?.name
            ?.toLowerCase()
            ?.includes(filter?.value?.toLowerCase())
        );
        setModifiedDataSource(handleSort(data));
      } else {
        const data = projects?.filter((project) =>
          project[path]?.toLowerCase()?.includes(filter?.value?.toLowerCase())
        );
        setModifiedDataSource(handleSort(data));
      }
    } else {
      if (projects) {
        setModifiedDataSource(handleSort(projects));
      }
    }
  }, [filter?.name, filter?.value, sortType, projects]);

  let PROJECT_COLUMNS = [
    {
      title: (
        <div className="flex items-center gap-3">
          Project Name{" "}
          <img
            src="/assets/images/IconArrow.svg"
            alt="sorterarrow"
            className="w-[10px]"
          />
        </div>
      ),
      dataIndex: "title",
      width: 100,
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (text, record) => {
        return <ProjectNameColumn text={text} record={record} />;
      },
    },
    {
      title: (
        <div className="flex items-center gap-3">
          Status
          <img
            src="/assets/images/IconArrow.svg"
            alt="sorterarrow"
            className="w-[10px]"
          />
        </div>
      ),
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text) => (
        <div className=" font-jost p-1 rounded-full w-[120px] text-center bg-[#babed4] text-primary">
          {text}
        </div>
      ),
    },
    {
      id: "client_name",
      title: (
        <div className="flex items-center gap-3">
          Client Name
          <img
            src="/assets/images/IconArrow.svg"
            alt="sorterarrow"
            className="w-[10px]"
          />
        </div>
      ),
      dataIndex: ["customerId", "name"],
      sorter: (a, b) => a.customerId.name.localeCompare(b.customerId.name),
    },
    {
      title: (
        <div className="flex items-center gap-3">
          Tags
          <img
            src="/assets/images/IconArrow.svg"
            alt="sorterarrow"
            className="w-[10px]"
          />
        </div>
      ),
      dataIndex: "assets",
      sorter: (a, b) => a.assets.length - b.assets.length,
      render: (text, record) => (
        <div className="flex items-center gap-2">
          <img
            src="/assets/images/IconPin.svg"
            alt={"tags"}
            style={{ width: "15px" }}
          />
          {record?.assets?.length}
        </div>
      ),
    },
    {
      title: (
        <div className="flex items-center gap-3">
          Date Added
          <img
            src="/assets/images/IconArrow.svg"
            alt="sorterarrow"
            className="w-[10px]"
          />
        </div>
      ),
      dataIndex: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (text) => {
        return <p>{timestamptoDate(text)}</p>;
      },
    },
    {
      title: (
        <div className="flex items-center gap-3">
          matterport URL
          <img
            src="/assets/images/IconArrow.svg"
            alt="sorterarrow"
            className="w-[10px]"
          />
        </div>
      ),
      dataIndex: "matterPortUrl",
      sorter: (a, b) => a.matterPortUrl.localeCompare(b.matterPortUrl),

      render: (text) => {
        return (
          <img
            src="/assets/images/LogoMatterport.svg"
            alt="delete"
            onClick={() =>
              navigator.clipboard.writeText(text).then(() => {
                showMessage("success", "Copied Matterport URL Code");
              })
            }
            className="cursor-pointer m-auto"
          />
        );
      },
    },
    // {
    //   title: (
    //     <div className="flex items-center gap-3">
    //       Click Embed URL
    //       <img
    //         src="/assets/images/IconArrow.svg"
    //         alt="sorterarrow"
    //         className="w-[10px]"
    //       />
    //     </div>
    //   ),
    //   dataIndex: "clickEmbedUrl",
    //   sorter: (a, b) => a.clickEmbedUrl.localeCompare(b.clickEmbedUrl),
    //   render: (text) => (
    //     <div
    //       onClick={() => window.open(text, "_blank")}
    //       className="cursor-pointer font-jost p-1 rounded-full w-[120px] text-center bg-[#D6D7DA] hover:bg-[#c4c4c5] text-primary"
    //     >
    //       Embed URL
    //     </div>
    //   ),
    // },
    {
      title: (
        <div className="flex items-center gap-3">
          Marketing Model
          <img
            src="/assets/images/IconArrow.svg"
            alt="sorterarrow"
            className="w-[10px]"
          />
        </div>
      ),
      dataIndex: "geomausMarketingModel",
      sorter: (a, b) =>
        a.geomausMarketingModel.localeCompare(b.geomausMarketingModel),
      render: (text) => (
        <div
          onClick={() => window.open(text, "_blank")}
          className="cursor-pointer font-jost p-1 rounded-full w-[140px] text-center bg-[#D6D7DA] hover:bg-[#c4c4c5] text-primary"
        >
          Preview Model
        </div>
      ),
    },
    {
      title: (
        <div className="flex items-center gap-3">
          Google Map
          <img
            src="/assets/images/IconArrow.svg"
            alt="sorterarrow"
            className="w-[10px]"
          />
        </div>
      ),
      dataIndex: "googleMapEmbed",
      sorter: (a, b) => a.googleMapEmbed.localeCompare(b.googleMapEmbed),

      render: (text) => {
        return (
          <div onClick={() => window.open(text, "_blank")}>
            <img
              src="/assets/images/IconGMap.svg"
              alt=""
              className="cursor-pointer m-auto"
            />
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center gap-3">
          Youtube Videos
          <img
            src="/assets/images/IconArrow.svg"
            alt="sorterarrow"
            className="w-[10px]"
          />
        </div>
      ),
      dataIndex: "youtubeVideoEmbed",

      sorter: (a, b) => a.youtubeVideoEmbed.localeCompare(b.youtubeVideoEmbed),
      render: (text) => {
        return (
          <div onClick={() => window.open(text, "_blank")}>
            <img
              src="/assets/images/IconYoutube.svg"
              alt=""
              className="cursor-pointer m-auto"
            />
          </div>
        );
      },
    },
    {
      id: "action",
      title: <div className="flex items-center gap-3">Actions</div>,
      dataIndex: "action",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div className="flex items-center gap-5 px-3">
          <Can I="delete" a="Project">
            <img
              src="/assets/images/IconTrash.svg"
              alt="delete"
              style={{ width: "18px" }}
              className="cursor-pointer"
              onClick={() => (setChosenProject(record), setDeleteModal(true))}
            />
          </Can>
          <Can I="edit" a="Project">
            <img
              src="/assets/images/IconSlider.svg"
              alt="slider"
              style={{ width: "18px" }}
              className="cursor-pointer"
              onClick={() => (setChosenProject(record), setEditModal(true))}
            />
          </Can>
        </div>
      ),
    },
  ];
  if (role === "USER") {
    PROJECT_COLUMNS = PROJECT_COLUMNS.filter(
      (pc) => pc.id !== "client_name" && pc.id !== "action"
    );
  }
  const handleRadioChange = (e) => {
    setSortType(e.target.value);
  };

  const sortByOptions = useMemo(
    () => [
      {
        label: "Ascending",
        value: "asc",
        isSelected: sortType === "asc",
      },
      {
        label: "Descending",
        value: "desc",
        isSelected: sortType === "desc",
      },
    ],
    [sortType]
  );

  const handleFilterChange = (option) => {
    setFilter({ name: option, value: "" });
  };

  const handleSort = (data) => {
    return [...data].sort((a, b) => {
      if (sortType === "asc") {
        return a.title.localeCompare(b.title);
      } else if (sortType === "desc") {
        return b.title.localeCompare(a.title);
      }
      return 0;
    });
  };
  return (
    // <div className="flex flex-col gap-5">
    //   <h1 className="headingPage  xl:hidden">
    //     {role === "ADMIN" ? "Projects" : "Buildings"}
    //   </h1>
    //   <div className="flex items-center justify-between gap-3 flex-wrap">
    //     <div className="flex items-center gap-8">
    //       <h1 className="headingPage hidden xl:block">
    //         {role === "ADMIN" ? "Projects" : "Buildings"}
    //       </h1>
    //       <div className="flex items-center gap-5">
    //         <Can I="create" a="Project">
    //           <Button
    //             title="Add a Project"
    //             onClick={() => {
    //               setAddModal(true);
    //             }}
    //           />
    //         </Can>
    //         <CSVLink data={exportData} filename={"Tutorials"}>
    //           <Button title="Export Projects" />
    //         </CSVLink>
    //       </div>
    //     </div>
    //     <div>
    //       <Input
    //         type="search"
    //         placeholder="Search..."
    //         width="w-[360px]"
    //         value={value}
    //         onChange={(e) => setValue(e?.target?.value)}
    //       />
    //     </div>
    //   </div>
    //   <div className="md:max-w-[calc(100vw-153px)] lg:max-w-[calc(100vw-170px)] bg-[#FCFCFC] pt-10 pb-4 px-10 flex flex-col items-center gap-3 rounded-2xl max-h-[calc(100vh-368px)] xl:max-h-[calc(100vh-250px)]">
    //     <div className="pl-[22px] flex items-center gap-7 self-start">
    //       <Popover content={sortContent} trigger="click" placement="bottom">
    //         <div className="flex items-center gap-3  cursor-pointer">
    //           <span className="heading4 text-[#545456]">Sort</span>
    //           <img src="/assets/images/IconSort.svg" alt="IconSearch" />
    //         </div>
    //       </Popover>
    //       <Popover content={filterContent} trigger="click" placement="bottom">
    //         <div className="flex items-center gap-3  cursor-pointer">
    //           <span className="heading4 text-[#545456]">Filter Results</span>
    //           <img src="/assets/images/IconFilter.svg" alt="IconSearch" />
    //         </div>
    //       </Popover>
    //       {filter?.value && (
    //         <>
    //           <p>Filter Applied:</p>
    //           <div
    //             className="border border-secondary rounded-xl px-4 py-1 text-xs text-secondary cursor-pointer"
    //             onClick={() => {
    //               setFilter({ name: "title", value: "" });
    //             }}
    //           >
    //             <p>
    //               {
    //                 filterOptions.filter((flt) => flt.value === filter?.name)[0]
    //                   ?.label
    //               }
    //               : {filter?.value}
    //             </p>
    //           </div>
    //         </>
    //       )}
    //     </div>
    //     {loading ? (
    //       <Spin />
    //     ) : (
    //       <TableComponent
    //         columns={PROJECT_COLUMNS}
    //         data={modifiedDataSource}
    //         placeholder="No Projects to show..."
    //       />
    //     )}
    //   </div>
    //   {addModal && (
    //     <AddEditProjectModal open={addModal} setOpen={setAddModal} type="Add" />
    //   )}
    //   {editModal && (
    //     <AddEditProjectModal
    //       open={editModal}
    //       setOpen={setEditModal}
    //       type="Edit"
    //       selectedData={chosenProject}
    //     />
    //   )}
    //   {deleteModal && (
    //     <DeleteModal
    //       open={deleteModal}
    //       loading={loading}
    //       setOpen={setDeleteModal}
    //       handleDelete={handleDeleteProject}
    //     />
    //   )}
    // </div>

    <div className={styles.main}>
      <div className={styles.headerTop}>
        <div className={styles.leftside}>
          <Can I="create" a="Project">
            <Button1
              title="Add New"
              theme={{ btn: styles.addNew }}
              onClick={() => {
                dispatch(setProjectModel(true));
              }}
            />
          </Can>
          <CSVLink data={exportData} filename={"Projects"}>
            <Button1 title="Export " theme={{ btn: styles.exportBtn }} />
          </CSVLink>
        </div>

        <div className={styles.rightside}>
          <div className="search">
            {/* <FormInput
              inputType="search"
              placeholder="Search"
              theme={{ labelText: styles.labelInput }}
              onChange={(e) => setValue(e?.target?.value)}
            /> */}

            <Input2
              placeholder="Search"
              type="search"
              icon="/assets/images/searchIconBlack.svg"
              name="search"
              onChange={(e) => setValue(e?.target?.value)}
              theme={{ input: styles.labelInput }}
            />
          </div>

          <FilterBy
            defaultValue={filterOptions[0]?.value}
            onChange={handleFilterChange}
            options={filterOptions}
            filter={filter}
            setFilter={setFilter}
          />

          <SortBy
            items={sortByOptions}
            onChange={(e) => setSortType(e.target.value)}
          />
        </div>
      </div>

      <div className={styles.projectMain}>
        <ProjectCard
          projects={modifiedDataSource}
          setChosenProject={setChosenProject}
          setDeleteModal={setDeleteModal}
        />

        {role == "ADMIN" && (
          <AddProjectButton
            label="Add new project"
            icon="/assets/images/AddOne.svg"
            onClick={() => dispatch(setProjectModel(true))}
            theme={{ addProject: styles.addProject, headteg: styles.headteg }}
          />
        )}
      </div>

      {deleteModal && (
        <ConfirmationModal
          isModal={deleteModal}
          setIsModal={setDeleteModal}
          text="Do you want to delete this project?"
          onClick={handleDeleteProject}
        />
      )}
    </div>
  );
};

const ProjectNameColumn = ({ text, record }) => {
  const [showSecondImage, setShowSecondImage] = useState(false);
  const { role } = useSelector((state) => state.user);
  return (
    <NavLink
      to={`/${role.toLowerCase()}/projects/matterport/${record?._id}`}
      className=" relative min-w-[170px] flex items-center gap-3 cursor-pointer"
    >
      <img
        src={
          record.coverPhoto && record.coverPhoto !== "Empty"
            ? record.coverPhoto
            : "/assets/images/ProjectThumbnailSample.svg"
        }
        alt={record.project}
        style={{
          width: "60px",
          height: "60px",
          borderRadius: "16px",
          cursor: "pointer",
          objectFit: "cover",
        }}
        onMouseOver={() => setShowSecondImage(true)}
      />
      <p className="truncate">{text}</p>

      {showSecondImage && (
        <img
          src={
            record.coverPhoto
              ? record.coverPhoto
              : "/assets/images/ProjectThumbnailSample.svg"
          }
          alt={record.project}
          className="absolute h-[150px] w-[150px] left-1 -top-3 z-50"
          style={{ borderRadius: "16px", cursor: "pointer" }}
          onMouseOut={() => setShowSecondImage(false)}
        />
      )}
    </NavLink>
  );
};

export default Projects;
