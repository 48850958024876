import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import style from "./invoice.module.scss";
import CustomDropdownWithoutForm from "../../component/macros/dropDownWithoutForm";
import { Button1, FormInput, TableComponent } from "../../component/macros";
import { Popover, Spin } from "antd";
import { timestamptoDate } from "../../Utils/functions";
import axiosInstance from "../../@api/axios";

const Invoice = () => {
  const { customers, loading } = useSelector((state) => state.customer);
  const [options, setOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [invoiceData, setInvoiceData] = useState([]);
  const [perUserCost, setPerUserCost] = useState(0);
  const [perProjectCost, setPerProjectCost] = useState(0);
  const [clientInformation, setClientInformation] = useState({});

  useEffect(() => {
    if (customers && [...customers].length > 0) {
      const customersOption = customers?.map((customer) => {
        return { value: customer._id, label: customer.name };
      });

      setOptions(customersOption);
    }
  }, [customers]);

  const monthOptions = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  useEffect(() => {
    if (selectedClient && selectedMonth) {
      axiosInstance
        .get(
          `/customers/information?customerId=${selectedClient}&month=${selectedMonth}`
        )
        .then((res) => setClientInformation(res.result));
    }
  }, [selectedClient, selectedMonth]);

  useEffect(() => {
    if (clientInformation) {
      if (clientInformation?.projects && clientInformation?.createdUsers) {
        setInvoiceData([
          ...clientInformation.projects,
          ...clientInformation.createdUsers,
        ]);
      }
    }
  }, [clientInformation]);

  const tableColumn = [
    {
      title: <div>Name</div>,
      dataIndex: "name",
      render: (text) => <div className={style.cell}>{text}</div>,
    },
    {
      title: <div>Type</div>,
      dataIndex: "type",
      render: (text) => <div className={style.cell}>{text}</div>,
    },
    {
      title: <div>Date</div>,
      dataIndex: "createdAt",
      render: (text) => (
        <div className={style.cell}>{timestamptoDate(text)}</div>
      ),
    },
    {
      title: <div>Price</div>,
      dataIndex: "type",
      render: (text) => (
        <div className={style.cell}>
          ${text == "Building" ? perProjectCost : perUserCost}
        </div>
      ),
    },
  ];

  // Calculate the total amount
  const calculateTotalAmount = () => {
    let total = 0;
    invoiceData.forEach((item) => {
      if (item.type == "Building") {
        total += parseInt(perProjectCost);
      } else if (item.type == "People") {
        total += parseInt(perUserCost);
      }
    });
    return total;
  };
  const downloadPdfDocument = () => {
    const input = document.getElementById("invoice");
    html2canvas(input, { scrollY: -window.scrollY }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a3"); // Use A3 size for PDF

      const imgWidth = pdf.internal.pageSize.width; // PDF width in mm
      const imgHeight = pdf.internal.pageSize.height; // PDF height in mm

      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // Calculate the width and height to fit the canvas into the PDF
      const pdfWidth = imgWidth;
      const pdfHeight = (canvasHeight * imgWidth) / canvasWidth;

      let heightLeft = pdfHeight;
      let position = 0;

      // Add the first page
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      heightLeft -= imgHeight;

      // Add subsequent pages if needed
      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, pdfWidth, pdfHeight);
        heightLeft -= imgHeight;
      }

      pdf.save("invoice.pdf");
    });
  };

  return (
    <div className={style.invoiceMain}>
      <div className={style.selectionWrapper}>
        <div className={style.selectionArea}>
          <div>
            <CustomDropdownWithoutForm
              label="Select Client"
              isMultiple={false}
              onChange={(client) => setSelectedClient(client)}
              options={options}
              value={selectedClient}
              placeholder="Select Client"
            />
          </div>

          <div>
            {" "}
            <CustomDropdownWithoutForm
              label="Select Month"
              isMultiple={false}
              onChange={(month) => setSelectedMonth(month)}
              options={monthOptions}
              value={selectedMonth}
              placeholder="Select Month"
            />
          </div>
          <div>
            <FormInput
              label="Per People Cost"
              inputType="number"
              placeholder=""
              onChange={(e) => setPerUserCost(e.target.value)}
              value={perUserCost}
              minValue={0}
            />
          </div>
          <div>
            <FormInput
              label="Per Building Cost"
              inputType="number"
              placeholder=""
              onChange={(e) => setPerProjectCost(e.target.value)}
              value={perProjectCost}
              minValue={0}
            />
          </div>

          <div>
            <Button1
              title="Download"
              onClick={() => downloadPdfDocument()}
              theme={{ btn: style.downloadButton }}
            />
          </div>
        </div>
      </div>

      {clientInformation && (
        <div className={style.invoice}>
          <div id="invoice" className={style.invoiceContent}>
            <div className={style.invoiceHeader}>
              <h1>Invoice</h1>
              <div className={style.clientInfo}>
                <p>
                  <strong>Name:</strong> {clientInformation?.customer?.name}
                </p>
                <p>
                  <strong>Address:</strong>{" "}
                  {clientInformation?.customer?.address}
                </p>
                <p>
                  <strong>Phone:</strong> {clientInformation?.customer?.phone}
                </p>
                <p>
                  <strong>Email:</strong> {clientInformation?.customer?.email}
                </p>
              </div>
            </div>

            <table className={style.invoiceTable}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {invoiceData.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.type}</td>
                    <td>{timestamptoDate(item.createdAt)}</td>
                    <td>
                      ${item.type === "Building" ? perProjectCost : perUserCost}
                    </td>
                  </tr>
                ))}
                <tr className={style.totalRow}>
                  <td colSpan="3">Total</td>
                  <td>${calculateTotalAmount()}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Invoice;
