import styles from "./radioButton.module.scss";

const RadioButtonGroup = ({ isRequired, items, name, setValue, watch }) => {
  if (items?.length < 1) {
    return null;
  }

  const formValue = watch(name);
  const checkedValue = formValue || items.find((i) => i.isDefault).value;

  return (
    <div className="radioButtonGroupWrapper">
      <p className={styles.labelText}>Status {isRequired ? "*" : ""}</p>
      <div className={styles.radioButtonGroup}>
        {items?.map((radioItem, index) => (
          <div key={`radioItemkey-${index}`} className={styles.radioItem}>
            <input
              type="radio"
              id={`radioItem-${index}`}
              name={name}
              value={radioItem.value}
              onClick={(e) => {
                setValue(name, radioItem.value);
              }}
              {...(checkedValue == radioItem.value ? { checked: true } : {})}
            />
            <label
              for={`radioItem-${index}`}
              onClick={(e) => {
                setValue(name, radioItem.value);
              }}
            >
              {radioItem.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioButtonGroup;
