import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAssetComment, uploadFile } from "../../store/asset/assetActions";
import { Comments, InputComments } from "../macros";

const Messaging = ({ chosenData }) => {
  const { selectedAssetComments } = useSelector((state) => state.asset);
  const [input, setInput] = useState("");

  const dispatch = useDispatch();
  const handleSend = () => {
    if (input !== "") {
      const data = {
        assetId: chosenData?._id,
        title: input,
        messageType: "text",
      };
      dispatch(createAssetComment(data));

      setInput("");
    }
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const base64file = event.target.result;
      console.log("base64file", base64file);
      if (e.target.files[0] !== null) {
        const parts = e.target.files[0]?.name?.split(".");
        const ext = parts[parts?.length - 1];
        const name = parts[parts?.length - 2];
        const form_data = { file: base64file };
        dispatch(
          uploadFile({
            id: chosenData._id,
            name,
            file: form_data,
            extension: ext,
          })
        );

        const sendMessage = {
          assetId: chosenData?._id,
          title: `📁 ${name}.${ext}`,
          messageType: "file",
        };
        dispatch(createAssetComment(sendMessage));

        handleSend();
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="bg-white/[0.5] flex flex-col mt-4">
      <Comments data={selectedAssetComments} />
      <InputComments
        handleSend={handleSend}
        input={input}
        setInput={setInput}
        handleFile={handleFile}
      />
    </div>
  );
};

export default Messaging;
