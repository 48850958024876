import * as yup from "yup";
export const profileSchema = yup.object({
  name: yup.string().required("Name is required"),
  phone: yup
    .string()
    .max(15, "Phone number is too long")
    .required("Phone is required"),
  description: yup.string(),
  email: yup
    .string()
    .required("Email is required")
    .email("Email must be valid"),
  password: yup.string(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const addUserSchema = yup.object({
  name: yup.string().required("Name is required"),
  phone: yup
    .string()
    .max(15, "Phone number is too long")
    .required("Phone is required"),
  description: yup.string(),
  address: yup.string(),
  email: yup
    .string()
    .required("Email is required")
    .email("Email must be valid"),
  password: yup.string(),
  twoFactorAuthentication: yup.boolean(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const contactSchema = yup.object({
  fullName: yup.string().required("Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .email("Email must be valid"),
  phone: yup
    .string()
    .max(15, "Phone number is too long")
    .required("Phone is required"),

  enquiryType: yup.string().required("Enquiry Type is required"),
});

export const tutorial = yup.object({
  title: yup.string().required("Title is required"),
  video: yup.string().required("Tutorial Link is required"),
});

export const projectSchema = yup.object({
  title: yup.string().required("Title is required"),
  matterPortUrl: yup.string().required("Matterport ID is required"),
  // clickEmbedUrl: yup.string(),
  geomausMarketingModel: yup.string(),
  description: yup.string().required("Description is required"),
  googleMapEmbed: yup.string(),
  geomausBimModel: yup.string(),
  bimModelRegisterDate: yup.string(),
  youtubeVideoEmbed: yup.string(),
  customerId: yup.string(),
  status: yup.string().required("Status is required"),
});

export const update_feature_Schema = yup.object({
  title: yup.string().required("Title is required"),
  details: yup.string(),
});

export const tagSchema = yup.object({
  title: yup.string().required("Title is required"),
  assetName: yup.string(),
  description: yup.string(),
  assetValue: yup.string(),
  assetModel: yup.string(),
  maintenanceProvider: yup.string(),
  people: yup.string(),
  maintenanceDate: yup.string(),
  warrantyExpirationDate: yup.string(),
  warrantyProvider: yup.string(),
});

export const subscriptionSchema = yup.object({
  users: yup.number().required("Users field is required"),
  projects: yup.number().required("Project field is required"),
});
