import { createSlice } from "@reduxjs/toolkit";
import {
  addProject,
  deleteProject,
  deleteProjectFile,
  getProjectFiles,
  getProjects,
  getSingleProject,
  updateProject,
  getProjectWithAssets,
  getBimModelAccessToken,
  uploadBimModel,
} from "./projectActions";

const initialState = {
  projects: [],
  totalProjects: 0,
  selectedProject: {},
  projectFiles: [],
  pageIndex: 1,
  projectsWithAssets: [],
  numberOfRows: 5,
  loading: false,
  error: false,
  isOpenModel: false,
  selectedValue: undefined,
  isAssignProjectModal: false,
  CustomerForAssignProject: undefined,
  bimModelAccessToken: "",
};
const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProjectModel: (state, action) => {
      state.isOpenModel = action.payload;
    },
    setAssignProjectModel: (state, action) => {
      state.isAssignProjectModal = action.payload;
    },
    setSelectedItem: (state, action) => {
      state.selectedValue = action.payload;
    },
    setCustomerForAssignProject: (state, action) => {
      state.CustomerForAssignProject = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProject.fulfilled, (state, action) => {
        state.projects = [action.payload.result, ...state.projects];
        state.loading = false;
      })
      .addCase(addProject.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.projects = action.payload.result.docs;
        state.numberOfRows = action.payload.result.limit;
        state.pageIndex = action.payload.result.page;

        state.loading = false;
      })
      .addCase(getProjects.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        const filteredProjects = state.projects.filter(function (obj) {
          return obj._id !== action.payload.result;
        });
        state.projects = [...filteredProjects];
        state.loading = false;
      })
      .addCase(deleteProject.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteProjectFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProjectFile.fulfilled, (state, action) => {
        const filteredProjects = state.projectFiles.filter(function (obj) {
          return obj._id !== action.payload.result;
        });
        state.projectFiles = [...filteredProjects];
        state.loading = false;
      })
      .addCase(deleteProjectFile.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        const project = action.payload.result;
        const projects = state.projects;
        const filteredProjects = projects.filter(function (obj) {
          return obj._id !== project._id;
        });
        state.projects = [project, ...filteredProjects];
        state.loading = false;
      })
      .addCase(updateProject.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSingleProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleProject.fulfilled, (state, action) => {
        state.selectedProject = action.payload.result;
        state.loading = false;
      })
      .addCase(getSingleProject.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getProjectFiles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectFiles.fulfilled, (state, action) => {
        state.projectFiles = action.payload.result;
        state.loading = false;
      })
      .addCase(getProjectFiles.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getProjectWithAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectWithAssets.fulfilled, (state, action) => {
        state.projectsWithAssets = action.payload.result;
        state.loading = false;
      })
      .addCase(getProjectWithAssets.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getBimModelAccessToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBimModelAccessToken.fulfilled, (state, action) => {
        state.bimModelAccessToken = action.payload.result;
        state.loading = false;
      })
      .addCase(getBimModelAccessToken.rejected, (state) => {
        state.loading = false;
      })
      .addCase(uploadBimModel.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadBimModel.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(uploadBimModel.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const projectReducer = projectSlice.reducer;
export const {
  setProjectModel,
  setSelectedItem,
  setAssignProjectModel,
  setCustomerForAssignProject,
} = projectSlice.actions;
