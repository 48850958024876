import React, { useState } from "react";
import { Table } from "antd";
// import "./../../Styles/antd.css";
import "./../../Styles/index.scss";
import { useEffect } from "react";
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
};
const TableComponent = ({
  columns,
  data,
  placeholder,
  checkbox = true,
  className,
}) => {
  const [placeholderText, setPlaceholderText] = useState("");
  useEffect(() => {
    if (data?.length < 1) {
      setPlaceholderText(placeholder);
    } else setPlaceholderText("");
  }, [data]);
  return (
    <div className="tableWrapper">
      <Table
        locale={{
          triggerDesc: "Sort field",
          triggerAsc: "Sort field",
          cancelSort: "Sort field",
        }}
        rowKey="_id"
        // rowSelection={
        //   checkbox && {
        //     type: "checkbox",
        //     ...rowSelection,
        //   }
        // }
        scroll={true}
        columns={columns}
        dataSource={data}
        rowClassName={() => "ant-row"}
        pagination={false}
        className={className}
      />
      {placeholderText && (
        <div className="tabelPlaceholder">{placeholderText}</div>
      )}
    </div>
  );
};
export default TableComponent;
