import { createSlice } from "@reduxjs/toolkit";

import { createEnquiryRecord } from "./contactUsActions";

const initialState = {
  loading: false,
  error: null,
};

const contactUsSlice = createSlice({
  name: "contactUs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createEnquiryRecord.pending, (state) => {
        state.loading = true;
      })
      .addCase(createEnquiryRecord.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createEnquiryRecord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data.message;
      });
  },
});

export const contactUsReducer = contactUsSlice.reducer;
export const { crea } = contactUsSlice.actions;
