import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Button1,
  Button2,
  Input2,
  LanguageSelect,
} from "../../component/macros";
import { googleAuth, registerUser } from "../../store/users/userActions";
import { useDispatch } from "react-redux";
import { message } from "antd";
import styles from "./authLayoutForms.module.scss";
import classNames from "classnames";
import AuthLayout from "./AuthLayout";
import { showMessage } from "../../Utils/pushNotification";
const schema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .email("Email must be valid"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Minimum 8 characters"),
});

const Signup = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(registerUser(data))
      .unwrap()
      .then((res) => (showMessage("success", res?.message), navigate("/")))
      .catch((err) => showMessage("error", err?.data?.message))
      .finally(reset());
  };

  return (
    <AuthLayout>
      <div className={classNames([styles.signinForm, styles.subForm])}>
        <h2>Create Your Account</h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputgroup}>
            <Input2
              label="Name"
              type="text"
              name="name"
              placeholder="Your Name"
              icon="assets/images/EmailInput.svg"
              alt=""
              register={register("name")}
              errors={errors}
              maxLength={40}
            />
          </div>

          <div className={styles.inputgroup}>
            <Input2
              label="Email"
              type="email"
              name="email"
              placeholder="olivia@untitledui.com"
              icon="assets/images/EmailInput.svg"
              alt=""
              register={register("email")}
              errors={errors}
            />
          </div>

          <div className={styles.inputgroup}>
            <Input2
              label="Password"
              type="password"
              name="password"
              placeholder="Password"
              icon="assets/images/PasswordEye.svg"
              alt=""
              register={register("password")}
              errors={errors}
            />
          </div>

          <div className={styles.forgot}>
            <NavLink className={styles.link} to="/">
              Already have account?
            </NavLink>
          </div>

          <div className={styles.loginbtn}>
            <Button1 title="Sign Up" type="submit" />
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default Signup;
