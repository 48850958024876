import { useEffect, useRef } from "react";
import styles from "./sideModel.module.scss";
import { themr } from "react-css-themr";

const SideModel = ({ isOpen, children, width, handleReset, theme }) => {
  const modalRef = useRef(null);

  return (
    <div
      className={`${theme.sideModel} ${isOpen ? theme.open : ""}`}
      ref={modalRef}
    >
      {isOpen && (
        <div className={styles.backDrop} onClick={() => handleReset()}></div>
      )}

      {children}
    </div>
  );
};

export default themr("SideModel", styles)(SideModel);
