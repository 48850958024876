import { yupResolver } from "@hookform/resolvers/yup";
import React, { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getFiles } from "../../store/asset/assetActions";
import { handleImageDrop, hexToRgb, rgbToHex } from "../../Utils/functions";
import { tagSchema } from "../../Utils/Validations";
import {
  Button,
  Button1,
  ColorInput,
  DateInput,
  DropBox,
  FormInput,
  Input,
  ModalContent,
  MultiSelect,
  ReactDropzone,
  SideModel,
} from "../macros";
import Dropdown from "../macros/Dropdown";
import style from "./newTagModal.module.scss";

const AddEditTagModal = ({ open, setOpen, submitAddTag, type, chosenData }) => {
  const dispatch = useDispatch();
  const { selectedAssetFiles, loading } = useSelector((state) => state.asset);
  const { usersByProject } = useSelector((state) => state.customer);
  const [imageBase64, setImageBase64] = useState(null);
  const [file, setFile] = useState();
  const [progress, setProgress] = useState(0);

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: chosenData?.title,
      users: chosenData?.users,
      assetName: chosenData?.assetName,
      description: chosenData?.description,
      assetValue: chosenData?.assetValue,
      assetModel: chosenData?.assetModel,
      maintenanceDate: chosenData?.maintenanceDate,
      maintenanceProvider: chosenData?.maintenanceProvider,
      warrantyExpirationDate: chosenData?.warrantyExpirationDate,
      warrantyProvider: chosenData?.warrantyProvider,
      color: chosenData && rgbToHex(chosenData?.color),
      file: type === "Edit" ? selectedAssetFiles[0]?.file : "",
      fileId: type === "Edit" ? selectedAssetFiles[0]?._id : "",
    },
    resolver: yupResolver(tagSchema),
  });
  const onSubmit = async (data) => {
    const newdata = { ...data };
    newdata.selectedTag = chosenData?._id;
    newdata.color = hexToRgb(data?.color);
    if (imageBase64 !== null) {
      newdata.file = imageBase64;
    }
    if (type === "Add") {
      newdata.fileId = "";
      submitAddTag(newdata);
      reset();
      setOpen(false);
    } else if (type === "Edit") {
      submitAddTag(newdata);
      reset();
      setOpen(false);
    }
  };
  useMemo(() => {
    if (chosenData?._id) dispatch(getFiles(chosenData?._id));
  }, [chosenData?._id]);

  const fakeUpload = () => {
    return new Promise((resolve) => {
      let uploaded = 0;
      const interval = setInterval(() => {
        uploaded += 10;
        setProgress(uploaded);
        if (uploaded >= 100) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  };

  console.log("progress", progress);
  return (
    // <ModalContent isOpen={open} setClose={() => setOpen(false)} width={631}>
    //   <form onSubmit={handleSubmit(onSubmit)} className="w-full">
    //     <div className="flex flex-col gap-2 w-full items-center px-5 ">
    //       <h3 className="heading3 text-primary">
    //         {type === "Add" ? "Add a New Tag" : "Edit Tag"}
    //       </h3>
    //       <div className="grid grid-cols-2 gap-y-3 gap-x-7 w-full overflow-y-auto h-[60vh]">
    //         <div className="space-y-1 col-span-2">
    //           <span className="heading4 ml-3">Title*</span>
    //           <Input
    //             register={register("title")}
    //             Icon="/assets/images/IconText.svg"
    //             placeholder="Enter Title..."
    //           />
    //           <p className="errorStyle">{errors?.title?.message}</p>
    //         </div>

    //         <div className="space-y-1">
    //           <span className="heading4 ml-3">People</span>
    //           <Controller
    //             name="users"
    //             control={control}
    //             render={({ field }) => (
    //               <MultiSelect
    //                 options={usersByProject?.map((user) => ({
    //                   value: user._id,
    //                   label: user.name,
    //                 }))}
    //                 handleSelect={field.onChange}
    //                 value={field.value}
    //                 placeholder="Status"
    //               />
    //             )}
    //           />
    //         </div>
    //         <div className="space-y-1">
    //           <span className="heading4 ml-3">Asset Name</span>
    //           <Input
    //             register={register("assetName")}
    //             Icon="/assets/images/IconText.svg"
    //             placeholder="Enter Name..."
    //           />
    //           <p className="errorStyle">{errors?.assetName?.message}</p>
    //         </div>
    //         <div className="space-y-1">
    //           <span className="heading4 ml-3">Description</span>
    //           <Input
    //             register={register("description")}
    //             Icon="/assets/images/IconPhone.svg"
    //             placeholder="Enter Text..."
    //           />
    //           <p className="errorStyle">{errors?.description?.message}</p>
    //         </div>

    //         <div className="space-y-1">
    //           <span className="heading4 ml-3">Asset Value</span>
    //           <Input
    //             register={register("assetValue")}
    //             Icon="/assets/images/IconDollar.svg"
    //             placeholder="Enter Text..."
    //           />
    //           <p className="errorStyle">{errors?.assetValue?.message}</p>
    //         </div>

    //         <div className="space-y-1">
    //           <span className="heading4 ml-3">Tag Color</span>
    //           <ColorInput
    //             register={register("color")}
    //             control={control}
    //             name="color"
    //           />
    //         </div>

    //         <div className="space-y-1">
    //           <span className="heading4 ml-3">Asset Model</span>
    //           <Input
    //             register={register("assetModel")}
    //             Icon="/assets/images/IconPhone.svg"
    //             placeholder="Enter Text..."
    //           />
    //           <p className="errorStyle">{errors?.assetModel?.message}</p>
    //         </div>
    //         <div className="space-y-1">
    //           <span className="heading4 ml-3">Asset Maintenance Date</span>
    //           <DateInput register={register("maintenanceDate")} />
    //         </div>

    //         <div className="space-y-1">
    //           <span className="heading4 ml-3">Asset Maintenance Provider</span>
    //           <Input
    //             register={register("maintenanceProvider")}
    //             Icon="/assets/images/IconAsset.svg"
    //             placeholder="Enter Text..."
    //           />
    //           <p className="errorStyle">
    //             {errors?.maintenanceProvider?.message}
    //           </p>
    //         </div>

    //         <div className="space-y-1">
    //           <span className="heading4 ml-3">Warranty Expiry</span>
    //           <DateInput register={register("warrantyExpirationDate")} />
    //         </div>

    //         <div className="space-y-1">
    //           <span className="heading4 ml-3">Warranty Provider</span>
    //           <Input
    //             register={register("warrantyProvider")}
    //             Icon="/assets/images/IconWarrantyTwo.svg"
    //             placeholder="Enter Text..."
    //           />
    //           <p className="errorStyle">{errors?.warrantyProvider?.message}</p>
    //         </div>
    //         <div className="space-y-1 col-span-2">
    //           <ReactDropzone
    //             borderRadius="rounded-full"
    //             onDrop={(acceptedFile) =>
    //               handleImageDrop(acceptedFile, setImageBase64)
    //             }
    //           >
    //             <div
    //               className={`  py-4 flex items-center justify-center gap-5`}
    //             >
    //               <img src="/assets/images/IconUpload.svg" alt="uploadImage" />
    //               <p className="font-medium text-xs text-primary text-center underline">
    //                 Select or drag and drop files here to upload
    //               </p>

    //               <button className="customTransition shadow-md px-6 py-1 flex justify-center items-center bg-white/[0.5] hover:bg-white/[0.8] rounded-[20px]">
    //                 <span className="text-[12px] font-semibold text-[#000E65]">
    //                   Upload
    //                 </span>
    //               </button>
    //             </div>
    //           </ReactDropzone>
    //         </div>
    //       </div>
    //       <div className="flex items-center gap-6 whitespace-nowrap mt-7 w-[70%] mx-auto">
    //         <Button
    //           loading={loading}
    //           title={type === "Add" ? "Add Tag" : "Edit Tag"}
    //           btnType="submit"
    //           className="!px-7"
    //           width={"w-full"}
    //           titleColor="text-primary"
    //           Icon="/assets/images/IconCheckBlue.svg"
    //         />
    //         <Button
    //           loading={loading}
    //           title={"Cancel"}
    //           width={"w-full"}
    //           className="!px-7"
    //           onClick={() => setOpen(false)}
    //           titleColor="text-primary"
    //           Icon="/assets/images/IconCrossBlue.svg"
    //         />
    //       </div>
    //     </div>
    //   </form>
    // </ModalContent>

    <SideModel isOpen={open} handleReset={() => setOpen(false)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.tagModel}>
          <h2>{type == "Add" ? "Add a New Tag" : "Edit Tag"}</h2>
          <p className={style.uploadText}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            <br /> sed do eiusmod tempor incididunt ut labore et .
          </p>
          <div className={style.formInputs}>
            <FormInput
              label="Title*"
              placeholder="Add a title to your tag..."
              errors={errors}
              register={register("title")}
              name="title"
              theme={{ input: style.inputNewTag }}
            />

            <Dropdown
              isMultiple={true}
              control={control}
              label="Peoples"
              name="users"
              placeholder="Select users"
              options={usersByProject?.map((user) => ({
                value: user._id,
                label: user.name,
              }))}
            />

            <FormInput
              label="Asset Name"
              placeholder="Name the asset"
              errors={errors}
              register={register("assetName")}
              name="assetName"
            />

            <FormInput
              inputType="textArea"
              label="Description"
              placeholder="Add a description"
              errors={errors}
              register={register("description")}
              name="description"
            />

            <FormInput
              label="Asset Value"
              placeholder="Add a value to this asset"
              errors={errors}
              register={register("assetValue")}
              name="assetValue"
            />
            <ColorInput
              label="Tag Color"
              register={register("color")}
              control={control}
              name="color"
            />

            {/* <FormInput
              inputType="color"
              register={register("color")}
              name="color"
              control={control}
            /> */}

            <FormInput
              label="Asset Model"
              placeholder="Add a the asset model"
              errors={errors}
              register={register("assetModel")}
              name="assetModel"
            />

            <FormInput
              label="Asset Maintenance Date"
              inputType="date"
              errors={errors}
              register={register("maintenanceDate")}
              name="maintenanceDate"
            />

            <FormInput
              label="Asset Maintenance Provider"
              placeholder="Who is the responsible for this asset?"
              errors={errors}
              register={register("maintenanceProvider")}
              name="maintenanceProvider"
            />

            <FormInput
              label="Warranty Expity Date"
              inputType="date"
              errors={errors}
              register={register("warrantyExpirationDate")}
              name="warrantyExpirationDate"
            />

            <FormInput
              label="Warranty Provider"
              placeholder="Who is the responsible for warranty?"
              errors={errors}
              register={register("warrantyProvider")}
              name="warrantyProvider"
            />
            <div className={style.dropZone}>
              <ReactDropzone
                onDrop={(acceptedFile) => {
                  setProgress(0);
                  setFile(acceptedFile);

                  handleImageDrop(acceptedFile, setImageBase64);
                  fakeUpload();
                }}
              >
                <img
                  src="/assets/images/fileDropImage.svg"
                  alt="file drop here"
                />
                <p className={style.fileName}>{file && file[0]?.name}</p>
              </ReactDropzone>
            </div>

            {progress > 0 && (
              <div className={style.progressMain}>
                <div>
                  <img src="/assets/images/fileIcon.svg" alt="FileIcon" />
                </div>
                <div
                  className={`${style.uploadContainer} ${
                    progress === 0 ? style.hidden : ""
                  }`}
                >
                  <div className={style.fileInfo}>
                    <span>{file && file[0]?.name}</span>
                  </div>
                  <div className={style.progressBarMain}>
                    <div className={style.progressBar}>
                      <div
                        className={style.progress}
                        style={{ width: `${progress}%` }}
                      ></div>
                    </div>
                    <span className={style.progressPercentage}>
                      {progress}%
                    </span>
                  </div>
                </div>
                <div>
                  {progress == 100 && (
                    <img src="/assets/images/blueTickIcon.svg" alt="FileIcon" />
                  )}
                </div>
              </div>
            )}
          </div>
          <div className={style.projectBtn}>
            <Button1
              type="submit"
              title="SAVE"
              theme={{ btn: style.addUpload }}
            />

            <Button1
              type="button"
              title="Cancel"
              onClick={() => setOpen(false)}
              theme={{ btn: style.cancelUpload }}
            />
          </div>
        </div>
      </form>
    </SideModel>
  );
};

export default AddEditTagModal;
