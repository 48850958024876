import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../@api/axios";

export const addCustomer = createAsyncThunk(
  "people/addCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/user`, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPeople = createAsyncThunk(
  "people/getPeople",
  async (data, { rejectWithValue }) => {
    try {
      const { searchValue, role } = data;
      if (role === "ADMIN") {
        const res = await axiosInstance.get(`/customers?query=` + searchValue);
        return res;
      } else if (role === "CUSTOMER") {
        const res = await axiosInstance.get(`/users`);
        return res;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "people/updateCustomer",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(`/user/` + id, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "people/delete",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.delete(`/user/` + id);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProjectsByCustomer = createAsyncThunk(
  "people/getProjectsByCustomer",
  async ({ id, type }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `/projectsByCustomer/` + id + "/" + type
      );
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const assignProject = createAsyncThunk(
  "people/assignProject",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(
        `/projects/AssignCustomer/` + id,
        data
      );
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const assignProjectUser = createAsyncThunk(
  "people/assignProjectUser",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(`/project/assign/` + id, data);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUsersByProject = createAsyncThunk(
  "people/getUsersByProject",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`user/getusersbyproject/` + id);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
