import { createSlice } from "@reduxjs/toolkit";
import { addFeature, deleteFeature, getFeatures } from "./featureActions";

const initialState = {
  features: [],
  totalFeatures: 0,
  pageIndex: 1,
  numberOfRows: 5,
  loading: false,
  isOpenModel: false,
};
const featureSlice = createSlice({
  name: "feature",
  initialState,
  reducers: {
    setFeatureModel: (state, action) => {
      state.isOpenModel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addFeature.pending, (state) => {
        state.loading = true;
      })
      .addCase(addFeature.fulfilled, (state, action) => {
        state.features = [action.payload.result, ...state.features];
        state.totalFeatures = state.totalFeatures + 1;
        state.loading = false;
      })
      .addCase(addFeature.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getFeatures.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFeatures.fulfilled, (state, action) => {
        state.features = action.payload.result.docs;
        state.totalFeatures = action.payload.result.totalDocs;
        state.numberOfRows = action.payload.result.limit;
        state.pageIndex = action.payload.result.page;
        state.loading = false;
      })
      .addCase(getFeatures.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteFeature.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteFeature.fulfilled, (state, action) => {
        const deletedId = action.payload.result;

        const filteredFeatures = state.features.filter(function (obj) {
          return obj._id !== deletedId;
        });
        state.features = [...filteredFeatures];
        state.totalFeatures = state.totalFeatures - 1;
        state.loading = false;
      })
      .addCase(deleteFeature.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const featureReducer = featureSlice.reducer;
export const { setFeatureModel } = featureSlice.actions;
