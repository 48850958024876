import style from "./projectStorage.module.scss";

const ProjectStorageCard = ({ projectData, setChosenProject, isSelected }) => {
  let logo = "/assets/images/ProjectDefaultImage.svg";
  if (projectData.logo) {
    logo = projectData?.logo;
  }

  return (
    <div
      className={`${style.card} ${isSelected ? style.isSelected : ""}`}
      onClick={() => setChosenProject(projectData)}
    >
      <div className={style.leftContent}>
        <img src={logo} alt="" className={style.projectImage} />
        <h2 className={style.storageTitel}>{projectData.title}</h2>
      </div>
      <div className={style.rightContent}>
        <p className={style.fileTitle}>{`${projectData.filesCount} Files`}</p>
        <span className={style.fileSize}>{projectData.size}</span>
      </div>
    </div>
  );
};

export default ProjectStorageCard;
