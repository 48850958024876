import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../@api/axios";

export const attatchPayment = createAsyncThunk(
  "subscription/attatchPayment",
  async ({ type, billing_details, token }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        `subscription/attatchpaymentmethod`,
        {
          type,
          billing_details,
          token,
        }
      );
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const checkoutSubscription = createAsyncThunk(
  "subscription/checkoutSubscription",
  async ({ access, token }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        `subscription/checkoutsubscription`,
        {
          token,
          access,
        }
      );
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSubscriptions = createAsyncThunk(
  "subscription/getsubscriptions",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/subscription/getsubscriptions`);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateSubscription = createAsyncThunk(
  "subscription/updateSubscription",
  async ({ access, token }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`subscription/updatesubscription`, {
        token,
        access,
      });
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "subscription/cancelSubscription",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`subscription/cancelsubscription`);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProjectCustomers = createAsyncThunk(
  "subscription/getProjectCustomers",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/subscription/getprojectCustomers`);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
