import { Button, message } from "antd";
import React from "react";
import { useState } from "react";
import styles from "./payment_module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Button1, FormInput, ModalContent } from "../../component/macros";
import SubscriptionModal from "../../component/modals/SubscriptionModal";
import { cancelSubscription } from "../../store/subscription/subscriptionActions";
import CheckoutForm from "./CheckoutForm";
import { showMessage } from "../../Utils/pushNotification";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ConfirmationModel from "../../component/modals/ConfitmationModal";

const Payment = () => {
  const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  // const { isSubscribed } = useSelector((state) => state.user.userData.user);
  const { isSubscribed } = useSelector((state) => state.user);

  const { allowedUsers, allowedProjects } = useSelector(
    (state) => state.subscription
  );

  const { appliedToCancel, loading } = useSelector(
    (state) => state.subscription
  );
  const [updateModal, setUpdateModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [plans, setPlans] = useState({});
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    setPlans(data);
    setUpdateModal(false);
    setShowForm(true);
  };
  const handleCancel = () => {
    dispatch(cancelSubscription())
      .unwrap()
      .then(
        (res) => (showMessage("success", res?.message), setCancelModal(false))
      )
      .catch(
        (err) => (
          showMessage("error", "Error in cancelling"), setCancelModal(false)
        )
      );
  };
  // Load Stripe with your publishable key
  const stripePromise = loadStripe(stripePublishableKey);

  return (
    // <div className="flex flex-col gap-5">
    //   <div className="flex items-center justify-between gap-3 flex-wrap">
    //     <div className="flex items-center gap-8">
    //       <h1 className="headingPage ">Payments</h1>
    //     </div>
    //   </div>
    //   <div className="grid grid-cols-1 gap-5 h-[calc(100vh-250px)] max-h-[calc(100vh-250px)]">
    //     <div className="flex flex-col gap-5 justify-between bg-white rounded-xl p-6 h-full ">
    //       {showForm ? (
    //         <Elements stripe={stripePromise}>
    //           <CheckoutForm
    //             projectCount={plans?.projects}
    //             userCount={plans?.users}
    //             isSubscribed={isSubscribed}
    //           />
    //         </Elements>
    //       ) : (
    //         <>
    //           <div className="flex flex-col gap-5">
    //             <h2 className="text-[20px] font-bold text-primary">
    //               Your Subscription
    //             </h2>
    //             <div className="flex flex-col bg-secondary text-white rounded-lg p-4">
    //               <div className="flex items-end justify-between gap-5 mb-6">
    //                 <h2 className="text-[20px] font-bold">
    //                   {isSubscribed ? "Custom" : "Not Subscribed"}
    //                 </h2>
    //                 <h2 className="text-[20px] font-bold">
    //                   {isSubscribed
    //                     ? `$ ${allowedProjects * 5 + allowedUsers}`
    //                     : "$0"}
    //                 </h2>
    //               </div>
    //               <div className="flex items-end justify-between gap-5">
    //                 <h2 className="text-[16px]">
    //                   You are allowed to add {isSubscribed ? allowedUsers : "0"}{" "}
    //                   Users / mo
    //                 </h2>

    //                 <h2 className="text-xs text-end">
    //                   ${allowedUsers ? allowedUsers : 0}
    //                 </h2>
    //               </div>
    //               <div className="flex items-end justify-between gap-5">
    //                 <h2 className="text-[16px]">
    //                   You are allowed to be assigned{" "}
    //                   {isSubscribed ? allowedProjects : "0 "}
    //                   Projects / mo
    //                 </h2>

    //                 <h2 className="text-xs text-end">
    //                   ${allowedProjects ? allowedProjects * 5 : 0}
    //                 </h2>
    //               </div>
    //             </div>
    //           </div>
    //           {appliedToCancel ? (
    //             <div className="text-center text-sm text-primary">
    //               Your subscription will be cancelled after paid duration
    //             </div>
    //           ) : (
    //             <div className="flex flex-col items-center justify-center gap-3 mb-3">
    //               <p
    //                 onClick={() => setUpdateModal(true)}
    //                 className="bg-primary border-2 border-primary hover:bg-transparent rounded-md p-2 text-white hover:text-primary font-semibold cursor-pointer"
    //               >
    //                 {isSubscribed ? "Update" : "Add"} Subscription
    //               </p>
    //               {isSubscribed ? (
    //                 <p
    //                   onClick={() => setCancelModal(true)}
    //                   className="text-sm text-primary hover:scale-[1.01] font-semibold cursor-pointer"
    //                 >
    //                   Cancel Subscription
    //                 </p>
    //               ) : (
    //                 ""
    //               )}
    //             </div>
    //           )}
    //         </>
    //       )}
    //     </div>
    //   </div>
    //   {updateModal && (
    //     <SubscriptionModal
    //       open={updateModal}
    //       setOpen={setUpdateModal}
    //       onSubmit={onSubmit}
    //     />
    //   )}
    //   {cancelModal && (
    //     <ModalContent
    //       isOpen={cancelModal}
    //       loading={loading}
    //       setClose={() => setCancelModal(false)}
    //       btnYes="End Subscription"
    //       handleYes={handleCancel}
    //       width={470}
    //       buttons
    //     >
    //       <div className="flex flex-col gap-2 w-full items-center px-5 overflow-y-auto ">
    //         <h3 className="heading3 text-primary">End Subscription</h3>
    //         <div className="text-lg font-medium text-center">
    //           Are you sure you want to end your subscription?
    //         </div>
    //       </div>
    //     </ModalContent>
    //   )}
    // </div>
    <div className="subscription">
      <div className="subscription-Info">
        <div className="subscription-title">
          <h1 className="title-text">
            Select Your <br /> Plan
          </h1>

          <div className="subscription-details">
            <span className="checkIcon">
              <img src="/assets/images/IconCheckWhite.png" alt="" />
            </span>
            Basic Package{" "}
          </div>

          <div className="subscription-details">
            <span className="checkIcon">
              <img src="/assets/images/IconCheckWhite.png" alt="" />
            </span>
            Enterprise Package{" "}
          </div>
        </div>
        <div className="subscription-img">
          <img src="/assets/images/Group-member.png" alt="" />
        </div>
      </div>

      {/*  */}
      <div className="subscription-Payment-Info">
        <div className="subscription-Payment">
          <div className={styles.formMain}>
            {showForm ? (
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  projectCount={plans?.projects}
                  userCount={plans?.users}
                />
              </Elements>
            ) : (
              <>
                <div className="flex flex-col gap-4">
                  <h2 className=" price-info ">Your Subscription</h2>
                  <p className="subscription-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                  <div className="activeMembership  ">
                    <div className="membershipCard-heading ">
                      <h2 className="heading-card-text">
                        {isSubscribed
                          ? "Your Current Active Membership"
                          : "Not Subscribed"}
                      </h2>
                      <h2 className="heading-card-text ">
                        {isSubscribed
                          ? `$ ${allowedProjects * 5 + allowedUsers}/month`
                          : "$0"}
                      </h2>
                    </div>
                    <div className=" membershipCard-Info ">
                      <h2 className="info-text-detail ">
                        You are allowed to add{" "}
                        {isSubscribed ? allowedUsers : "0"} Users / month
                      </h2>

                      <h2 className="info-text-doller ">
                        ${allowedUsers ? allowedUsers : 0}
                      </h2>
                    </div>
                    <div className="membershipCard-Info ">
                      <h2 className="info-text-detail">
                        You are allowed to be assigned{" "}
                        {isSubscribed ? allowedProjects : "0 "}
                        Projects / month
                      </h2>

                      <h2 className="info-text-doller  ">
                        ${allowedProjects ? allowedProjects * 5 : 0}
                      </h2>
                    </div>
                  </div>
                </div>
                {appliedToCancel ? (
                  <div className="text-center text-sm text-black">
                    Your subscription will be cancelled after paid duration
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center gap-3 mb-3">
                    <SubscriptionModal onSubmit={onSubmit} />
                    {isSubscribed && (
                      <Button1
                        type="button"
                        title="Cancel Subscription"
                        onClick={() => setCancelModal(true)}
                      />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {cancelModal && (
        <ConfirmationModel
          isModal={cancelModal}
          onClick={handleCancel}
          setIsModal={() => setCancelModal(false)}
        />
      )}
    </div>
  );
};

export default Payment;
