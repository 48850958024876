import { message, Popover } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Can } from "../../../@permissions/Can";
import {
  Button,
  Button1,
  FormInput,
  Input,
  Input2,
  Options,
  SideModel,
} from "../../../component/macros";
import DeleteModal from "../../../component/modals/DeleteModal";
import AddEditTagModal from "../../../component/modals/NewTagModal";
import StatusModal from "../../../component/modals/StatusModal";
import { completeAsset } from "../../../store/asset/assetActions";
import { getUsersByProject } from "../../../store/customers/customerActions";
import { rgbToHex } from "../../../Utils/functions";
import ConfirmationModal from "../../../component/modals/ConfitmationModal";
import { showMessage } from "../../../Utils/pushNotification";
import style from "./assetTagging.module.scss";
import ProjectDetails from "./ProjectDetails";
import TagActionModel from "../../../component/modals/TagActionModel";
import TagDetailsModal from "../../../component/modals/TagDetailsModal";

const AssetTagging = ({
  assets,
  submitAddTag,
  handleDeleteTag,
  handleNavigateAsset,
  setChosen,
}) => {
  const { loading } = useSelector((state) => state.asset);
  const { selectedProject } = useSelector((state) => state.project);

  const [addTagModal, setAddTagModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState("");
  const [tagDetailModal, setTagDetailModal] = useState(false);
  const [deleteTagModal, setDeleteTagModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [editTagModal, setEditTagModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [statusText, setStatusText] = useState("");
  const [chosenTag, setChosenTag] = useState({});
  const [openActionModel, setActionModel] = useState(false);

  const dispatch = useDispatch();
  const { id: projectId } = useParams();

  useEffect(() => {
    dispatch(getUsersByProject(projectId));
  }, []);

  const handleActionModel = (item) => {
    setActionModel(true);
  };

  useEffect(() => {
    setStatusText(
      chosenTag?.status === "Completed" ? "InComplete" : "Complete"
    );
  }, [chosenTag, statusText]);

  const handleStatus = () => {
    dispatch(
      completeAsset({
        id: chosenTag._id,
        data: {
          status: statusText === "InComplete" ? "InCompleted" : "Completed",
        },
      })
    )
      .unwrap()
      .then(
        (res) => (showMessage("success", res?.message), setStatusModal(false))
      )
      .catch(
        (err) => (
          showMessage("error", "Could not update status"), setStatusModal(false)
        )
      );
  };

  const content = (
    <div className={style.actionMain}>
      <div className={style.action}>
        <Can I="edit" a="Asset">
          <div className={style.text} onClick={() => setEditTagModal(true)}>
            Edit
          </div>
        </Can>
        <div className={style.text} onClick={() => setStatusModal(true)}>
          {statusText}
        </div>
        <Can I="delete" a="Asset">
          <div className={style.text} onClick={() => setDeleteTagModal(true)}>
            Delete
          </div>
        </Can>
        <div
          className={style.text}
          onClick={() => handleNavigateAsset(chosenTag)}
        >
          Go to Tag
        </div>
      </div>
    </div>
  );

  return (
    // <div className="my-5 pb-2 flex flex-col gap-5 h-full self-start overflow-y-auto">
    //   <h3 className="text-[22px] text-primary font-medium self-center">
    //     Asset Tagging
    //   </h3>
    //   <Input
    //     type="search"
    //     placeholder="Search a Tag..."
    //     onChange={(e) => setSearchQuery(e.target.value)}
    //   />
    //   {assets.length <= 0 && (
    //     <p className="italic text-secondary text-lg ">
    //       No file found please upload to see the search results...
    //     </p>
    //   )}
    //   <Can I="create" a="Asset">
    //     <Button
    //       title="Add a Tag"
    //       width={"w-full"}
    //       onClick={() => {
    //         setAddTagModal(!addTagModal);
    //       }}
    //       titleColor="text-primary"
    //       noIcon
    //     />
    //   </Can>
    //   <div className="w-full min-h-[1px] h-[1px] bg-secondary my-5" />
    //   <div>
    //     {assets.length > 0 && (
    //       <h3 className="text-[22px] text-primary font-medium">Recent Tags</h3>
    //     )}
    //     {assets
    //       ?.filter((item) =>
    //         item?.title.toLowerCase().includes(searchQuery.toLowerCase())
    //       )
    //       .map((item, index) => (
    //         <div key={item?._id}>
    //           <Popover
    //             content={
    //               <div>
    //                 <Can I="edit" a="Asset">
    //                   <div
    //                     className="cursor-pointer"
    //                     onClick={() => setEditTagModal(true)}
    //                   >
    //                     Edit
    //                   </div>
    //                 </Can>
    //                 <div
    //                   className="cursor-pointer"
    //                   onClick={() => setStatusModal(true)}
    //                 >
    //                   Status
    //                 </div>
    //                 <Can I="delete" a="Asset">
    //                   <div
    //                     className="cursor-pointer"
    //                     onClick={() => setDeleteTagModal(true)}
    //                   >
    //                     Delete
    //                   </div>
    //                 </Can>
    //                 <div
    //                   className="cursor-pointer"
    //                   onClick={() => handleNavigateAsset(chosenTag)}
    //                 >
    //                   Go to Tag
    //                 </div>
    //               </div>
    //             }
    //             trigger="click"
    //           >
    //             <div
    //               onClick={() => setChosenTag(item)}
    //               className={`hover:bg-[#D9D9D9] hover:shadow-md relative flex mt-1 py-2 px-3 rounded-full items-center justify-between`}
    //             >
    //               <span className="text-lg text-primary truncate">
    //                 {item?.title}
    //               </span>
    //               <div className="w-[21px] h-[21px] bg-[#868cb1] rounded-lg flex justify-center items-center">
    //                 <div
    //                   className={`h-[14px] w-[14px] rounded-full bg-[${rgbToHex(
    //                     item?.color
    //                   )}]`}
    //                 />
    //               </div>
    //             </div>
    //           </Popover>
    //         </div>
    //       ))}
    //   </div>

    //   {addTagModal && (
    //     <AddEditTagModal
    //       open={addTagModal}
    //       setOpen={setAddTagModal}
    //       type="Add"
    //       submitAddTag={submitAddTag}
    //     />
    //   )}
    //   {editTagModal && (
    //     <AddEditTagModal
    //       chosenData={chosenTag}
    //       open={editTagModal}
    //       type="Edit"
    //       setOpen={setEditTagModal}
    //       submitAddTag={submitAddTag}
    //     />
    //   )}
    //   {statusModal && (
    //     <StatusModal
    //       status={statusText}
    //       open={statusModal}
    //       setOpen={setStatusModal}
    //       handleStatus={handleStatus}
    //     />
    //   )}
    //   {deleteTagModal && (
    //     <ConfirmationModal
    //       isModal={deleteTagModal}
    //       setIsModal={setDeleteTagModal}
    //       onClick={() => (handleDeleteTag(chosenTag), setDeleteTagModal(false))}
    //     />
    //   )}
    // </div>

    <>
      <SideModel
        isOpen={true}
        handleReset={() => setChosen("")}
        theme={{ sideModel: style.sideModel }}
      >
        <div className={style.tagsListContainer}>
          <h1 className={style.tagsHeading}>{`Tag List ${assets.length} `}</h1>

          <Input2
            placeholder="Search"
            type="search"
            icon="/assets/images/searchIcon.svg"
            name="search"
            onChange={(e) => setSearchQuery(e.target.value)}
            theme={{ input: style.input }}
          />

          <div className={style.tagList}>
            {assets.length <= 0 && <p>No Tag Available</p>}

            {assets
              ?.filter((item) =>
                item?.title.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((item, index) => {
                return (
                  <div className={style.itemWrapper}>
                    <div
                      class={style.tagItem}
                      onClick={() => {
                        // handleActionModel(item);
                        setSelectedAsset(item);
                        setTagDetailModal(true);
                      }}
                    >
                      <div
                        className={style.tagColor}
                        style={{ backgroundColor: `${rgbToHex(item?.color)}` }}
                      ></div>
                      <div className={style.tagText}>{item?.title}</div>
                    </div>

                    <Popover
                      content={content}
                      placement="bottom"
                      trigger={"click"}
                    >
                      <div
                        className={style.tagArrow}
                        onClick={() => setChosenTag(item)}
                      >
                        {/* {" "}
                        <img src="/assets/images/RightArrowIcon.svg" /> */}
                        <div className={style.moreData}>
                          <span className={style.dotte}></span>
                          <span className={style.dotte}></span>
                          <span className={style.dotte}></span>
                        </div>
                      </div>
                    </Popover>
                  </div>
                );
              })}
          </div>
          <Button1
            type="button"
            title="Cancel"
            onClick={() => setChosen("")}
            theme={{ btn: style.cancelUpload }}
          />
        </div>
      </SideModel>
      {editTagModal && (
        <AddEditTagModal
          chosenData={chosenTag}
          open={editTagModal}
          type="Edit"
          setOpen={setEditTagModal}
          submitAddTag={submitAddTag}
        />
      )}
      {statusModal && (
        <ConfirmationModal
          isModal={statusModal}
          setIsModal={setStatusModal}
          text={`Do you want to mark as ${statusText}`}
          onClick={handleStatus}
        />
      )}{" "}
      {deleteTagModal && (
        <ConfirmationModal
          isModal={deleteTagModal}
          setIsModal={setDeleteTagModal}
          text="Do you want to delete this tag?"
          onClick={() => (handleDeleteTag(chosenTag), setDeleteTagModal(false))}
        />
      )}
      {tagDetailModal && (
        <TagDetailsModal
          selectedTag={selectedAsset}
          open={tagDetailModal}
          setOpen={setTagDetailModal}
          handleTagDetails={submitAddTag}
        />
      )}
    </>
  );
};

export default AssetTagging;
