import { createSlice } from "@reduxjs/toolkit";
import {
  completeAsset,
  createAsset,
  deleteAsset,
  fetchAssets,
  getFiles,
  deleteFile,
  updateAsset,
  uploadFile,
  getAsset,
  createAssetComment,
  getCustomerTags,
} from "./assetActions";

const initialState = {
  assets: [],
  overDueAssets: [],
  selectedAssetComments: [],
  selectedAsset: {},
  selectedAssetFiles: [],
  totalAssets: 0,
  totalOverDueAssets: 0,
  customerTags: [],
  pageIndex: 1,
  numberOfRows: 5,
  loading: false,
};
const assetSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createAsset.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAsset.fulfilled, (state, action) => {
        state.assets = [action.payload.result, ...state.assets];
        state.totalAssets = state.totalAssets + 1;
        state.loading = false;
      })
      .addCase(createAsset.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAssets.fulfilled, (state, action) => {
        state.assets = action.payload.result.docs;
        state.totalAssets = action.payload.result.totalDocs;
        state.numberOfRows = action.payload.result.limit;
        state.pageIndex = action.payload.result.page;
        state.loading = false;
      })
      .addCase(fetchAssets.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getFiles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFiles.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedAssetFiles = action.payload.result;
      })
      .addCase(getFiles.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteAsset.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAsset.fulfilled, (state, action) => {
        const deletedId = action.payload.result;
        const assets = state.assets;

        const filteredAssets = assets.filter(function (obj) {
          return obj._id !== deletedId;
        });
        state.assets = [...filteredAssets];
        state.totalAssets = state.totalAssets - 1;
        state.loading = false;
      })
      .addCase(deleteAsset.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateAsset.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAsset.fulfilled, (state, action) => {
        const asset = action.payload.result.asset;
        const assets = state.assets;
        const filteredAssets = assets.filter(function (obj) {
          return obj._id !== asset._id;
        });
        state.assets = [asset, ...filteredAssets];
        if (action.payload?.result?.uploaded.length > 0) {
          const selectedFile = action.payload?.result?.uploaded;
          const selectedAssetFiles = state.selectedAssetFiles;
          const filteredAssetFiles = selectedAssetFiles.filter(function (obj) {
            return obj._id !== selectedFile._id;
          });

          state.selectedAssetFiles = [selectedFile, ...filteredAssetFiles];
        }
        state.loading = false;
      })
      .addCase(updateAsset.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(completeAsset.pending, (state) => {
        state.loading = true;
      })
      .addCase(completeAsset.fulfilled, (state, action) => {
        const asset = action.payload.result.asset;
        const assets = state.assets;
        const filteredAssets = assets.filter(function (obj) {
          return obj._id !== asset._id;
        });
        state.assets = [asset, ...filteredAssets];
        state.loading = false;
      })
      .addCase(completeAsset.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(uploadFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        const file = action.payload.result;
        const files = state.selectedAssetFiles;
        state.selectedAssetFiles = [file, ...files];
        state.loading = false;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        const filteredFiles = state.selectedAssetFiles.filter(function (obj) {
          return obj._id !== action.payload.result;
        });
        state.selectedAssetFiles = [...filteredFiles];
        state.loading = false;
      })
      .addCase(deleteFile.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAsset.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAsset.fulfilled, (state, action) => {
        state.selectedAssetComments = action.payload.result.assetComments.docs;
        state.selectedAsset = action.payload.result.asset;
        state.loading = false;
      })
      .addCase(getAsset.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createAssetComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAssetComment.fulfilled, (state, action) => {
        state.selectedAssetComments = [
          action.payload.result,
          ...state.selectedAssetComments,
        ];
        state.loading = false;
      })
      .addCase(createAssetComment.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getCustomerTags.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerTags.fulfilled, (state, action) => {
        state.customerTags = action.payload.result;
        state.loading = false;
      })
      .addCase(getCustomerTags.rejected, (state, action) => {
        state.loading = false;
      });
  },
});
export const { setNewTagTemp } = assetSlice.actions;
export const assetReducer = assetSlice.reducer;
