import React from "react";
import styles from "./../../Styles/button.module.scss";
import { themr } from "react-css-themr";

const Button1 = ({ type, title, icon, disabled, theme, onClick }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`${theme.btn}`}
    >
      {title}
      {icon && <img src={icon} className={`${theme.icon}`} alt="" />}
    </button>
  );
};

export default themr("Button1", styles)(Button1);
