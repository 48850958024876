import React from "react";
import styles from "./authLayoutForms.module.scss";
import { Button1, CountDown } from "../../component/macros";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import OtpInput from "../../component/macros/OtpInput";

const OtpForm = (props) => {
  const {
    otp,
    handleConfirm,
    setOtp,
    otpError,
    setOtpError,
    data,
    handleResend,
  } = props;

  const handleOTPResend = () => {
    handleResend(data);
    setResetTimer(true);
    setActivateResend(false);
    setOtpError(false);
  };

  const [resetTimer, setResetTimer] = useState(false);
  const [activateResend, setActivateResend] = useState(true);
  return (
    <>
      <h2>Email Verification</h2>
      <p>
        There is nothing to worry about, we'll send you a message to help you
        reset your password.
      </p>

      <form onSubmit={handleConfirm}>
        <div className={styles.inputgroup}>
          <OtpInput
            otpError={otpError}
            setOtp={setOtp}
            type="text"
            placeholder="Verify six digit otp"
            value={otp}
            minLength="6"
            maxLength="6"
          />
        </div>

        <div className={styles.loginbtn}>
          <Button1 title="Verify Email" type="submit" />
        </div>

        <div className={styles.otpResend}>
          Didn’t Receive The Email?
          {activateResend && <a onClick={handleOTPResend}>Check to resend</a>}
          {resetTimer && (
            <CountDown
              duration={60}
              onTimeout={() => (setActivateResend(true), setResetTimer(false))}
            />
          )}
        </div>

        <div className={styles.backToLogin}>
          <NavLink className={styles.link} to="/">
            <img src="assets/images/ArrowLeft.svg" alt="" />
            Back To Log in
          </NavLink>
        </div>
      </form>
    </>
  );
};

export default OtpForm;
