import style from "./../../pages/dashboard.module.scss";
import { useNavigate } from "react-router-dom";
import { getTimeAgo } from "../../Utils/functions";
import { useSelector } from "react-redux";
import Button1 from "./Button1";

const FeatureCard = ({ featureList }) => {
  const { role } = useSelector((state) => state.user);

  const navigate = useNavigate();

  return (
    <div className={style.featureRequest}>
      <h2>Feature Request / Reported Issues</h2>

      <div className={style.tableData}>
        <div className={style.table}>
          <div className={style.header}>
            <div className={style.cell}>Title</div>
            <div className={style.cell}>Date</div>
            <div className={style.cell}>By</div>
          </div>

          {featureList?.map((feature) => (
            <div className={style.row}>
              <div className={style.cell}>{feature.title}</div>
              <div className={style.cell}>{getTimeAgo(feature.createdAt)}</div>
              <div className={style.cell}>{feature.userId?.name}</div>
            </div>
          ))}
        </div>

        <Button1
          type="button"
          title="Add New Feature Request"
          icon="/assets/images/ArrowRightWhite.png"
          theme={{ btn: style.btnAddNew }}
          onClick={() => navigate(`/${role.toLowerCase()}/features`)}
        />
      </div>
    </div>
  );
};

export default FeatureCard;
