import { Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableComponent } from "../component/macros";
import { getNotifications } from "../store/notifications/notificationActions";
import { timestamptoDate } from "../Utils/functions";
import style from "./notification.module.scss";

const Notification = () => {
  const { notifications, loading } = useSelector((state) => state.notification);
  const { user } = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNotifications({ user }));
  }, []);
  const FEATURE_COLUMNS = [
    {
      title: "Sr No.",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Date",
      dataIndex: "updatedAt",
      render: (text) => {
        return <p className="mx-auto text-center">{timestamptoDate(text)}</p>;
      },
    },
  ];
  return (
    <div className={style.main}>
      {!loading && (
        <TableComponent
          checkbox={false}
          columns={FEATURE_COLUMNS}
          data={notifications}
        />
      )}
    </div>
  );
};

export default Notification;
