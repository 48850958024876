export const MATTERPORT_MENU = [
  {
    id: 1,
    name: "Storage",
    icon: "/assets/images/storageIcon.svg",
  },

  {
    id: 2,
    name: "Share",
    icon: "/assets/images/shareIcon.svg",
  },
  {
    id: 3,
    name: "Tags List",
    icon: "/assets/images/tagListIcon.svg",
  },
  {
    id: 4,
    name: "BIM Model",
    icon: "/assets/images/homeIcon.svg",
  },

  {
    id: 5,
    name: "Add Tag",
    icon: "/assets/images/addTagIcon.svg",
  },
];
