import React from "react";
import ReactPlayer from "react-player";
import { ModalContent } from "../macros";

const VideoModal = ({ open, setOpen, src }) => {
  return (
    <ModalContent isOpen={open} setClose={() => setOpen(false)} width={631}>
      <div className=" w-full mt-3 rounded-2xl overflow-hidden shadow-md">
        <ReactPlayer url={src} width="100%" height="500px" controls={true} />
      </div>
    </ModalContent>
  );
};

export default VideoModal;
