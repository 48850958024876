import React, { useMemo } from "react";
import { useState } from "react";
import CustomerEditModal from "../component/modals/CustomerEditModal";
import {
  Button1,
  Input2,
  TableComponent,
  UserActions,
} from "../component/macros";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCustomer, getPeople } from "../store/customers/customerActions";
import { Popover, Spin } from "antd";
import useDebounce from "../hooks/useDebounce";
import { CSVLink } from "react-csv";
import {
  accessAccount,
  sendPlatformUpdatesToAll,
} from "../store/users/userActions";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  setAssignProjectModel,
  setCustomerForAssignProject,
} from "../store/projects/projectSlice";
import {
  setEditCustomerModal,
  setCustomerDataForEdit,
  setAddCustomerModal,
} from "../store/customers/customerSlice";

import style from "./customer.module.scss";
import EditCustomerModel from "../component/modals/EditCustomerModal";
import SortBy from "../component/macros/SortBy/SortBy";
import ProjectAssignModal from "../component/modals/ProjectAssignModal";
import AddNewCustomerModal from "../component/modals/AddNewCustomerModal";
import FilterBy from "../component/macros/filterBy/filterBy";
import ConfirmationModal from "../component/modals/ConfitmationModal";
import { showMessage } from "../Utils/pushNotification";

const filterOptions = [
  { label: "Client Name", value: "name" },
  { label: "Description", value: "description" },
  { label: "Address", value: "address" },
];

const Customer = () => {
  const items = [
    { label: "item 1", key: "item-1" }, // remember to pass the key prop
    { label: "item 2", key: "item-2" },
  ];
  const [searchParams] = useSearchParams();
  const { customers, loading, isEditCustomerModal, isAddCustomerModal } =
    useSelector((state) => state.customer);
  const { CustomerForAssignProject, isAssignProjectModal } = useSelector(
    (state) => state.project
  );

  const { role } = useSelector((state) => state.user);
  const { allowedUsers } = useSelector((state) => state.subscription);
  const [modifiedDataSource, setModifiedDataSource] = useState(() => customers);
  const [sortType, setSortType] = useState("asc");
  const [filter, setFilter] = useState({
    name: filterOptions[0].value,
    value: "",
  });

  const [customerData, setCustomerData] = useState();
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [value, setValue] = useState(() => searchParams.get("search") || "");
  const [searchValue, oldValue] = useDebounce(value, 500, true);

  useEffect(() => {
    if (filter?.value > "") {
      const path = filter.name;
      const data = customers?.filter((update) =>
        update[path]?.toLowerCase()?.includes(filter?.value?.toLowerCase())
      );
      setModifiedDataSource(handleSort(data));
    } else {
      setModifiedDataSource(handleSort(customers));
    }
  }, [filter?.name, filter?.value, sortType, customers]);

  const handleRadioChange = (e) => {
    setSortType(e.target.value);
  };

  const handleFilterChange = (option) => {
    setFilter({ name: option, value: "" });
  };

  const handleSort = (data) => {
    return [...data].sort((a, b) => {
      if (sortType === "asc") {
        return a?.name?.localeCompare(b.name);
      } else if (sortType === "desc") {
        return b?.name?.localeCompare(a.name);
      }
      return 0;
    });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAccessAccount = (data) => {
    dispatch(accessAccount({ id: data._id, loggedInRole: role }));
    navigate(`/${role === "ADMIN" ? "customer" : "user"}/dashboard/`);
  };

  const handleEditCustomer = (data) => {
    dispatch(setEditCustomerModal(true));
    dispatch(setCustomerDataForEdit(data));
  };

  const handleAssignProject = (data) => {
    dispatch(setAssignProjectModel(true));
    dispatch(setCustomerForAssignProject(data));
  };
  const handleAddUser = () => {
    if (role === "CUSTOMER") {
      if (allowedUsers > customers.length) {
        setAddCustomerModal(true);
      } else {
        showMessage("info", "Your need to add or upgrade your subscription");
        navigate("/customer/payment");
      }
    } else {
      setAddCustomerModal(true);
    }
  };
  const handleDeleteCustomer = () => {
    dispatch(deleteCustomer(customerData._id))
      .unwrap()
      .then((res) => showMessage("success", res?.message))
      .catch((err) => showMessage("error", "Could not delete customer"))
      .finally(setDeleteModal(false));
  };
  const handleDelete = (data) => {
    setCustomerData(data);
    setDeleteModal(true);
  };
  const actions = [
    { name: "Edit", onClick: handleEditCustomer, iconName: "editIcon" },
    {
      name: "Assign Project",
      onClick: handleAssignProject,
      iconName: "assignIcon",
    },
    { name: "Delete", onClick: handleDelete, iconName: "deleteIcon" },
  ];

  const CUSTOMER_COLUMNS = [
    {
      title: <div>Name</div>,
      dataIndex: "name",
      render: (text, record) => (
        <div className={style.cell}>
          <img
            src={
              record.photo
                ? record.photo
                : "/assets/images/default-avatar-icon-of-social-media-user-vector.jpg"
            }
            alt=""
          />
          {text}
        </div>
      ),
    },
    {
      title: <div>Email</div>,
      dataIndex: "email",
      width: "150px",
      render: (text) => <div className={style.cell}>{text}</div>,
    },
    {
      title: <div>Phone</div>,
      dataIndex: "phone",
      render: (text) => <div className={style.cell}>{text}</div>,
    },

    {
      title: <div>Address</div>,
      dataIndex: "address",
      width: "300px",
      render: (text) => (
        <div className={style.scrollableDiv}>
          <div className={`${style.cell}`}>{text}</div>
        </div>
      ),
    },
    {
      title: <div>Description</div>,
      dataIndex: "description",
      width: "300px",
      render: (text) => (
        <div className={style.scrollableDiv}>
          <div className={style.cell}>{text}</div>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "",
      render: (record) => (
        <Button1
          theme={{ btn: style.accessBtnMain }}
          title="Access Account"
          onClick={() => handleAccessAccount(record)}
        />
      ),
    },
    {
      title: "",
      dataIndex: "",
      render: (record) => (
        <Popover
          content={<UserActions data={record} actions={actions} />}
          trigger="hover"
          placement="bottom"
        >
          <div className={style.cell}>
            <div className={style.moreData}>
              <span className={style.dotte}></span>
              <span className={style.dotte}></span>
              <span className={style.dotte}></span>
            </div>
          </div>
        </Popover>
      ),
    },
  ];

  useEffect(() => {
    setExportData(
      customers?.map((customer) => {
        const item = {
          name: customer?.name,
          email: customer?.email,
          phone: customer?.phone,
          address: customer?.address,
        };
        return item;
      })
    );
  }, [customers]);

  useEffect(() => {
    if (searchValue !== "" || (searchValue === "" && oldValue !== "")) {
      dispatch(getPeople({ searchValue, role }));
    } else {
      dispatch(getPeople({ searchValue, role }));
    }
  }, [searchValue, oldValue]);

  const sortByOptions = useMemo(
    () => [
      {
        label: "Ascending",
        value: "asc",
        isSelected: sortType === "asc",
      },
      {
        label: "Descending",
        value: "desc",
        isSelected: sortType === "desc",
      },
    ],
    [sortType]
  );

  return (
    <div className={style.customerMain}>
      <div className={style.headerTop}>
        <div className={style.leftside}>
          <Button1
            title="Add New"
            theme={{ btn: style.addNew }}
            onClick={() => {
              dispatch(setAddCustomerModal(true));
            }}
          />

          <CSVLink data={exportData} filename={"Features"}>
            <Button1 title="Export " theme={{ btn: style.exportBtn }} />
          </CSVLink>
          {role === "ADMIN" && (
            <Button1
              title="Send Updates"
              theme={{ btn: style.platformUpdateBtn }}
              onClick={() => {
                dispatch(sendPlatformUpdatesToAll());
                showMessage(
                  "success",
                  "Successfully invited all users to our new platform."
                );
              }}
            />
          )}
        </div>

        <div className={style.rightside}>
          <div className="search">
            <Input2
              placeholder="Search"
              type="search"
              icon="/assets/images/searchIconBlack.svg"
              name="search"
              onChange={(e) => setValue(e?.target?.value)}
              theme={{ input: style.labelInput }}
            />
          </div>

          <FilterBy
            defaultValue={filterOptions[0]?.value}
            onChange={handleFilterChange}
            options={filterOptions}
            filter={filter}
            setFilter={setFilter}
          />

          <SortBy
            items={sortByOptions}
            onChange={(e) => setSortType(e.target.value)}
          />
        </div>
      </div>

      <div>
        {loading && !customers && <Spin />}

        <div style={{ width: "100%" }}>
          <TableComponent
            columns={CUSTOMER_COLUMNS}
            data={modifiedDataSource}
            className={style.tableWrapper}
          />
        </div>
        {editModal && (
          <CustomerEditModal
            open={editModal}
            setOpen={setEditModal}
            data={customerData}
          />
        )}

        <EditCustomerModel isOpen={isEditCustomerModal} />

        <AddNewCustomerModal isOpen={isAddCustomerModal} />

        <ProjectAssignModal
          isOpen={isAssignProjectModal}
          type={role === "ADMIN" ? "CUSTOMER" : "USER"}
          id={CustomerForAssignProject?._id}
        />

        {deleteModal && (
          <ConfirmationModal
            text="Do you want to delete this customer?"
            isModal={deleteModal}
            setIsModal={setDeleteModal}
            onClick={handleDeleteCustomer}
          />
        )}
      </div>
    </div>
  );
};

export default Customer;
