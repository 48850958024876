import React from "react";
import style from "./formInput.module.scss";
import { themr } from "react-css-themr";

const FormInput = ({
  inputType,
  placeholder,
  Icon,
  label,
  defaultValue,
  value,
  register,
  width,
  onChange,
  disabled,
  minValue,
  maxValue,
  name,
  errors,
  theme,
  onKeyDown,
}) => {
  return (
    <div className={theme.main}>
      {label && <p className={theme.labelText}>{label}</p>}

      <div>
        {inputType == "textArea" ? (
          <textarea
            name={label}
            cols=""
            rows="5"
            placeholder={placeholder}
            className={theme.textarea}
            {...register}
            onChange={onChange}
            disabled={disabled}
          />
        ) : (
          <input
            className={theme.input}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            disabled={disabled}
            type={inputType}
            onKeyDown={onKeyDown}
            min={minValue}
            max={maxValue}
            placeholder={placeholder}
            {...register}
          />
        )}
      </div>
      {errors && <div className={theme.invalid}>{errors[name]?.message}</div>}
    </div>
  );
};

export default themr("FormInput", style)(FormInput);
