import React from "react";
import { useState } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useRef } from "react";
import FormInput from "./FormInput";
import Button1 from "./Button1";
import style from "./inputComments.module.scss";
import Input2 from "./Input2";

const InputComments = ({ handleSend, input, setInput, handleFile }) => {
  const [showEmojis, setShowEmojis] = useState(false);
  const emojiRef = useRef();
  const inputRef = useRef();

  const insertEmoji = (emoji) => {
    const cursorPosition = inputRef.current.selectionStart;
    const updatedValue =
      input.slice(0, cursorPosition) +
      emoji.native +
      input.slice(cursorPosition);
    setInput(updatedValue);
    inputRef.current.setSelectionRange(
      cursorPosition + emoji.native.length,
      cursorPosition + emoji.native.length
    );
    setShowEmojis(false);
  };

  return (
    <div className={style.inputText}>
      <div className={style.commentsInput}>
        <input
          name="message-input"
          inputType="text"
          ref={inputRef}
          placeholder="Write your message..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              handleSend();
            }
          }}
          onClick={() => setShowEmojis(false)}
        />
        <div className={style.microphone} onClick={() => setShowEmojis(true)}>
          <img src="/assets/images/microphone.svg" alt="Emoji Icon" />
        </div>
        <div className={style.uploadCommentsFile}>
          <img src="/assets/images/paperClipeIcon.svg" alt="Attachment" />
          <Input2
            type="file"
            name="file"
            theme={{ input: style.inputfile }}
            id="image-upload"
            onChange={handleFile}
          />
        </div>
      </div>
      <Button1
        type="button"
        title="Send"
        onClick={() => handleSend()}
        theme={{ btn: style.messageSend }}
      />
      {showEmojis ? (
        <div ref={emojiRef} className="absolute bottom-12 left-0 ">
          <Picker
            data={data}
            onEmojiSelect={(emoji) => {
              insertEmoji(emoji);
            }}
            previewPosition="none"
          />
        </div>
      ) : null}
    </div>
  );
};

export default InputComments;
